//import React, {useState} from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import "../../css/theoryPage.css";
import "../../css/responsiveText.css";
import { Link } from "react-router-dom";
const ChapterColumnComponent = (chapter) => {
  //const [isShown, setIsShown] = useState(false);
  //onMouseEnter={() => setIsShown(true)}  onMouseLeave={() => setIsShown(false)}

  return (
    <Col className="theoryPage-col">
      <Card className="theoryPage-card">
        <Link to={chapter.redirect}>
          <Card.Img
            className="theoryPage-card-img"
            src={chapter.link}
            alt={chapter.alt}
            fluid
          ></Card.Img>
          <Card.ImgOverlay>
            <Card.Title className="theoryPage-card-title">
              <p>
                <span className="red-text-span">{chapter.partID}</span> :{" "}
                {chapter.title}
              </p>
            </Card.Title>
          </Card.ImgOverlay>
        </Link>
      </Card>
    </Col>
  );
};

export default ChapterColumnComponent;
