import "./App.css";
import Footer from "./components/Footer";
import MainStreamer from "./components/homePage/MainStreamer";
import NavComponent from "./components/NavComponent";

function App() {
  return (
    <div className="App">
      <MainStreamer></MainStreamer>
      <NavComponent></NavComponent>
      <Footer></Footer>
    </div>
  );
}

export default App;
