const FigureInnerLinkComponent = (props) => {

  return (
    <a href={"#"+props.anchor} style={{"textDecoration":"none"}}>
      <span
        className="red-text-span"
      >
        {props.text}
      </span>
      </a>
  );
};

export default FigureInnerLinkComponent;
