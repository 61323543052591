import { Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useSearchParams } from "react-router-dom";

const CopyrightImage = (props) => {
  const [params,] = useSearchParams();
  console.log(params.get("author"));
  return (
    <Container>
      <Image
        alt={props.alt}
        src={params.get("link")}
        className={props.className}
        fluid
      />
      <p className="text-normal">
        © {params.get("author") ? params.get("author") : ""} - 2022
      </p>
    </Container>
  );
};

export default CopyrightImage;

// Should look after it to make img fluid
