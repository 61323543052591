import { Container } from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGithub} from "@fortawesome/free-brands-svg-icons"
const ScriptComponent = () => {
  return (
    <Container className="left">
    <hr className="thick-hr"></hr>
    <p className="text-title bold centered">Species chromatogram: a new way to display the ecological niche of a species</p>
    <div className="top-span"></div>
    <p className="text-normal">The full method has been implemented in a R package (specieschrom) available on Github  <a className="hidden-hyperlink" href="https://github.com/loick-klpr/specieschrom.git"><FontAwesomeIcon icon={faGithub} beat></FontAwesomeIcon></a>
    {"   "}and as Matlab functions <a className=" hidden-hyperlink" href="https://github.com/loick-klpr/Species-chromatogram-with-Matlab.git"><FontAwesomeIcon icon={faGithub} beat></FontAwesomeIcon></a>
    </p>
    <p className="text-normal">The method has been used and tested in Kléparski & Beaugrand (2022).</p>
    <p className="text-normal">Kléparski L, Beaugrand G (2022) The species chromatogram, a new graphical method to represent, characterise and compare the ecological niches of different species. <em>Ecology and Evolution 12:e8830.</em></p>
    <div className="centered" style={{margin:"auto"}}>
    <iframe  src="./ressources/Kleparski-specieschrom.pdf" title="Specieschrrom" width={700} height={800}>
     Presss me: <a href="./resources/Kleparski-specieschrom.pdf">Download PDF</a>
    </iframe>
    </div>
    <div className="top-span"></div>
    <hr className="thick-hr"></hr>
    <p className="text-normal">Other scripts developed as part of the METAL theory will be available soon on this website.</p>
    </Container>
  );
};

export default ScriptComponent;

/*
On remote finalement le texte suivant. Utilise celui en dessous car j'ai fait des modifications.

The full method has been implemented in a R package (specieschrom) available on Github  and as Matlab functions.

The method has been used and tested in Kléparski & Beaugrand (2022).

Kléparski L, Beaugrand G (2022) The species chromatogram, a new graphical method to represent, characterise and compare the ecological niches of different species. Ecology and Evolution 12:e8830.


PUIS EN DESSOUS DE LA BARRE HORIZONTALE, tu mets le titre en rouge.
*/