import "../../../css/theoryPage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FigureInnerLinkComponent from "../../utilComponents/FigureInnerLinkComponent";
import PartTwoReferences from "./PartTwoReferences";
import TheoryFigure from "../../utilComponents/TheoryFigure";
import ClickableCopyrightImage from '../../utilComponents/ClickableCopyrightImage'
import Tex2SVG from "react-hook-mathjax";
const PartFourPage = () => {
  return (
<div className="theoryPage">
      <p className="text-big-title centered bold">
        {" "}
        II.Patterns and biological events
      </p>
      <hr className="thick-hr"></hr>
      <Row>
        <Col>
        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/leftTop.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
            <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/leftBottom.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
                        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/petrelFulmar.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Christophe Luczak"
            ></ClickableCopyrightImage>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={10} xs={10} xxs={10}>
          <div className="top-span"></div>
          <p className="text-normal">
          The METAL theory postulates that the arrangement of life results from the interaction between the ecological niche of species and the fluctuations in the environmental regime. If this postulate is correct, we can predict a number of spatial and temporal patterns at different organisational levels ranging from the individual to the biospheric organisational level and at different spatial and temporal scales. In this section, we show a few examples at the species and community levels.
          </p>
          <p className="text-subtitle bold">The species level</p>
          <p className="text-normal">
          At the species level, the METAL theory enables the understanding of space and time responses of species to environmental changes at many scales of variability. Niche shape can be modelled by using many different functions (<FigureInnerLinkComponent text="Figure 1" anchor="Figure-1"></FigureInnerLinkComponent>). Some researchers have used a trapezoidal (Kaschner et al. 2008) or a rectangular niche (Beaugrand et al. 2013), the latter being used when only presence/absence are considered (<FigureInnerLinkComponent text="Figure 1b-c" anchor="Figure-1"></FigureInnerLinkComponent>). The niche can be symmetric (<FigureInnerLinkComponent text="Figure 1a-d" anchor="Figure-1"></FigureInnerLinkComponent>) or asymmetric (<FigureInnerLinkComponent text="Figure 1e-f" anchor="Figure-1"></FigureInnerLinkComponent>). The consideration of asymmetry is important for some species as many ectotherms seem more sensitive to warming than cooling (Araujo et al. 2013).
          </p>
          <TheoryFigure
            anchor="Figure-1"
            text="Figure 1"
            definition=". Examples of niche shape that can be used as part of the METAL theory. a. Gaussian niche. b. Trapezoidal niche. c. Rectangular niche. d. Parabolic niche. e. Negative asymmetric niche. f. Positive asymmetric niche. From Beaugrand (Book in preparation)."
            link="../images/partII/FIGURE 1.png"
          >
          </TheoryFigure>
          <p className="text-normal">Below we model the niche of a species using a Gaussian function (Ter Braak 1996) and using a single environmental parameter: mean Sea Surface Temperature (SST). The response curve of the abundance E of a pseudospecies s in a given site i and time j to change in SSTs was modelled as follows: </p>
          <div className="centered text-title" id="Equation-1">
          <Tex2SVG
              display="inline"
              latex="E_{i,j,s} = c_{s}e - \left(\frac{(x_{i,j}-u_s)^2}{2t^2_s}\right)"
            ></Tex2SVG>
             <p className="text-normal">
            With <Tex2SVG display="inline" latex="E_{i,j,s}"></Tex2SVG>
            the expected abundance of a pseudo-species{" "}
            <Tex2SVG display="inline" latex="{s}"></Tex2SVG> at location{" "}
            <Tex2SVG display="inline" latex="{i}"></Tex2SVG> and time{" "}
            <Tex2SVG display="inline" latex="{j}"></Tex2SVG>;{" "}
            <Tex2SVG display="inline" latex="{c_s}"></Tex2SVG> the maximum value
            of abundance for species{" "}
            <Tex2SVG display="inline" latex="{s}"></Tex2SVG> (here this
            parameter is fixed to one);{" "}
            <Tex2SVG display="inline" latex="{x_{i,j}}"></Tex2SVG> the value of
            SST at location <Tex2SVG display="inline" latex="{i}"></Tex2SVG> and
            time <Tex2SVG display="inline" latex="{j}"></Tex2SVG>;{" "}
            <Tex2SVG display="inline" latex="{u_s}"></Tex2SVG> the thermal
            optimum and <Tex2SVG display="inline" latex="{t_s}"></Tex2SVG> the
            thermal amplitude for species{" "}
            <Tex2SVG display="inline" latex="{s}"></Tex2SVG>. The thermal
            tolerance is an estimation of the breadth (or thermal amplitude) of
            the species thermal niche (or bioclimatic envelope) (Ter Braak,
            1996).
          </p>
          </div>
          <p className="text-subtitle bold">Species spatial distribution</p>
          <p className="text-normal">We first start by estimating species spatial distribution, a currently active area of research based on the application of Ecological Niche (ENM) or Spatial Distribution (SDM) Models (Albouy et al. 2012; Araujo and Guisan 2006; Cheung et al. 2008; Lenoir et al. 2011; Raybaud et al. 2013). As temperature is a fundamental parameter, we use a thermal niche for simplification. However, the METAL theory should consider the full multidimensionality of the niche; for example, light limitations probably take place in high-latitude regions (Mitchell et al. 1991; Sverdrup 1953), influencing the spatial distribution and the phenology of some species (Edwards and Richardson 2004), and oxygen limitation may be important in some oceanic regions such as the East Pacific or the Indian Oceans (Hofmann et al. 2011). </p>
          <TheoryFigure
            anchor="Figure-2"
            text="Figure 2"
            definition=". Probability of occurrence of some pseudo-species having a Gaussian niche with different thermal optima and amplitude. Within a map, different areas characterized by a high probability of occurrence can be occupied by different pseudo-species according to Buffon’s Law (see main text). From Beaugrand (Book in preparation)."
            link="../images/partII/FIGURE 2.png"
          >
          </TheoryFigure>
          <p className="text-normal">We created hypothetical species characterized by a Gaussian thermal niche with 4 different values of us for 2 values of ts (<FigureInnerLinkComponent text="Figures 2" anchor="Figure-2"/> and <FigureInnerLinkComponent text="3" anchor="Figure-3"/>). <FigureInnerLinkComponent text="Figure 2" anchor="Figure-2"/> shows the probability of species occurrence based on a given thermal niche. Even if similar environmental (here, thermal) conditions occur in different oceanic and neritic regions, different species may be present according to Buffon’s Law, also known as the first principle of biogeography (Lomolino et al. 2006). We therefore designed an algorithm that enables species characterised by the same thermal niche and that are spatially separated to be differentiated. In practice, when an area with high contiguous probabilities (i.e. above 0.4) was separated by at least one geographical cell (the spatial grid was 2°x2°) from another contiguous area with high probabilities, the two areas were considered to be occupied by two different species having the same thermal niche. <FigureInnerLinkComponent text="Figure 3" anchor="Figure-3"/> shows the results of the application of this algorithm on data displayed in <FigureInnerLinkComponent text="Figure 2" anchor="Figure-2"/>, each colour representing a different species in each map. The consideration of a different threshold did not lead to a different conclusion. </p>
          <TheoryFigure
            anchor="Figure-3"
            text="Figure 3"
            definition=". Spatial distribution of some pseudo-species having a Gaussian niche with different thermal optima and amplitudes. Within a map, when an area with high contiguous probabilities of occurrence above 0.4 (see Figure 2) was separated by at least one geographical cell (the spatial grid was 2°x2°) from another contiguous area with probabilities higher than 0.4, the two areas were considered to be occupied by two different species having the same thermal niche. Within a map, each pseudo-species (with the same niche) is represented by a different colour. From Beaugrand (Book in preparation)."
            link="../images/partII/FIGURE 3.png"
          >
          </TheoryFigure>
          <p className="text-normal">A universal eurythermic species is the only species than may have a pandemic distribution (not shown). Other species with a smaller degree of eurythermy will exhibit a more restricted distributional range. Many species are likely to exhibit a circum-oceanic (e.g. circum-polar and circum-temperate) distributional range (<FigureInnerLinkComponent text="Figure 2-3a-e" anchor="Figure-2"/> for both hemispheres and <FigureInnerLinkComponent text="Figure 2-3c-d" anchor="Figure-2"/> for the Southern Hemisphere exclusively). By either considering more ecological variables (e.g. bathymetry, oxygen concentration, macro-nutrients and micro-nutrients) or in the case of stronger stenoecy (see <FigureInnerLinkComponent text="Figure 2-3a" anchor="Figure-2"/> versus <FigureInnerLinkComponent text="Figure 2-3b" anchor="Figure-2"></FigureInnerLinkComponent> and <FigureInnerLinkComponent anchor="Figure-2" text="Figure 2-3e"/> versus <FigureInnerLinkComponent text="Figure 2-3f" anchor="Figure-2"/>), the circum-oceanic distributions are likely to fade and to become more fragmented (i.e. disjointed distribution).</p>
          <p className="text-normal">Tropical/warm-temperate species (Figure 2e-h) are less likely to have a circum-oceanic distribution because of the presence of continents that act nowadays as geographical barriers. However, this figure also depends on the degree of euryoecy (<FigureInnerLinkComponent anchor="Figure 2-3e" text="Figure-2"/> versus <FigureInnerLinkComponent text="Figure 2-3f" anchor="Figure-2"/>). Stenothermic tropical/temperate species occurring in both hemispheres (<FigureInnerLinkComponent text="Figure 2f" anchor="Figure-2"/>) may also become isolated enough at the equator to become different species (allopatric speciation). Such an equatorial diminution can be permanent or not. For example, the eurythermic temperate species Atlantic bluefin tuna (Thunnus thynnus) is in general disjointed at the equator and individuals are therefore mainly observed in the North Atlantic Ocean (Fromentin et al. 2014). The species sometimes exhibits spectacular changes in spatial distribution, however (<FigureInnerLinkComponent text="Figure 4" anchor="Figure-4"/>). In the 1960s, the species was observed in large concentration in the equatorial Atlantic and the warm-temperate regions of the Southern Hemisphere (the so-called Brazilian Episode) because environmental conditions became more suitable at the equator (<FigureInnerLinkComponent text="Figure 4" anchor="Figure-4"/>) (Fromentin et al. 2014). It may be possible to find circum-tropical or circum-temperate species (1) because of the gradual formation of the Isthmus of Panama that took place only between 13 and 1.9 million years ago (Haug and Tiedemann 1998) and (2) because there exists a possibility for some eurythermic tropical/temperate species to migrate from the Indian to the Atlantic Ocean (<FigureInnerLinkComponent text="Figure 3e" anchor="Figure-3"/>).</p>
          <TheoryFigure
            anchor="Figure-4"
            text="Figure 4"
            definition=". Decadal maps of the probabilities of the Atlantic bluefin tuna occurrence during: (a) the “Brazilian episode”, (b) the 1970s, (c) the 1980s, (d) the 1990s and (f) the 2000s. From Fromentin and colleagues (Fromentin et al. 2014)."
            link="../images/partII/FIGURE 4.png"
            className="theoryPage-I-1Tier"
          >
          </TheoryFigure>
          <p className="text-normal">The METAL theory may help to understand better the relationship between the niche of a species and its spatial distribution. Lomolino and colleagues (Lomolino et al. 2006) stated that the geographical range of a species reflects its ecological niche. This also explains the relative success of ecological niche modelling, which enables from the modelling of species’ ecological niche to not only infer their past and current spatial distribution but also to project their future spatial range from the knowledge of forthcoming environmental conditions (Lenoir et al. 2011; Raybaud et al. 2013). Such a modelling approach has lacked however a theoretical framework to be better understood and accepted by all scientists. Brown’s theory (Brown 1984) links species’ local density and range with species’ ecological niche. Although this seems to be the case in general (Beaugrand et al. 2014b), this relationship can sometimes be not so straightforward. Some species can have a more restricted range (and sometimes be endemic) because of the presence of geographical barriers (e.g. landmass; see <FigureInnerLinkComponent text="Figure 3c" anchor="Figure-3"/> and <FigureInnerLinkComponent text="Figure 3f" anchor="Figure-3"/>). For example, despite that all species have the same niche, we can see in Fig. 5f that one has a very limited spatial distribution in the northern part of the Indian Ocean because of the presence of the Eurasian continent. Such a restricted spatial distribution, modulated by the realised environment (Jackson and Overpeck 2000), may substantially affect the modelling of the niche by ENMs. A high level of endemism seems therefore possible in the Mediterranean Sea (<FigureInnerLinkComponent anchor="Figure-3" text="Figure 3c, e, g"/>), a pattern frequently reported in the literature (Lasram et al. 2010). </p>
          <p className="text-normal">Studies have shown that the METAL theory explains of large proportion of the variance of the spatial distribution of some species (Beaugrand et al. 2014b). For example, by considering annual SST, bathymetry, chlorophyll a and photosynthetically active radiation, the modelled spatial distribution of the calanoid copepod Calanus finmarchicus was close to the spatial distribution observed from the Continuous Plankton Recorder (CPR) survey (Figure 5). Approaches based on ENMs often successfully reconstruct marine species spatial distribution (Beaugrand et al. 2011; Lenoir et al. 2011; Raybaud et al. 2013; Rombouts et al. 2012). ENMs have been more criticised in the terrestrial realm but this might be related to the higher heterogeneity of this realm and the strong direct influence of human activities.</p>
          <TheoryFigure
            anchor="Figure-5"
            text="Figure 5"
            definition=". Expected (a) and observed (b) mean spatial distribution of the calanoid copepod Calanus finmarchicus. Observed data were from the Continuous Plankton Recorder (CPR) survey (Reid et al. 2003). Expected abundance was calculated using the Non-Parametric Probabilistic Ecological Niche (NPPEN) model based on four ecological dimensions (see text). The scatterplot shows the relationships between expected and observed abundance of C. finmarchicus. From Beaugrand and colleagues (Beaugrand et al. 2014b)."
            link="../images/partII/FIGURE 5.png"
            className="theoryPage-I-2Tier"
          >
          </TheoryFigure>
          <p className="text-normal">Current controversies exist between process-based models and phenomenological (also improperly called correlative) models such as ENMs (Morin and Thuiller 2009). We think that this distinction is not appropriate because ENMs are based on a key property of species: the ecological niche, which integrates all biological and physiological processes taking place at smaller organisational levels and enable the consideration of emergent properties. They can also be considered as process-based models but at a higher level of emergence. We caution that only ENMs can be used in the context of the METAL theory. SDMs are not appropriate because they focus primarily on the modelling of the spatial distribution of species (e.g. MAXENT (Philips et al. 2006)). Therefore, SDMs work primarily in a spatial domain whereas ENMs do work in the space of the (Euclidean) ecological niche. The model NPPEN (Non-Parametric Probabilistic Ecological Niche model) that we have recently developed can therefore be used as part of the METAL theory (Beaugrand et al. 2011). This technique determines the species’ niche and uses it to determine the spatial distribution of a species. The METAL theory provides a background against which the rationale of ENMs can be better understood. ENMs are therefore not only statistical models used without any ecological background.</p>
          <p className="text-subtitle bold">Effects of the degree of eurythermy on species phenology and spatial distribution</p>
          <p className="text-normal">Beaugrand and colleagues (Beaugrand et al. 2014b) compared a eurythermic with a stenothermic psychrophilic species (<FigureInnerLinkComponent text="Figure 6a,d" anchor="Figure-6"/>). For an identical thermal optimum (us=15°C), the local density of the eurytherm was much higher than the stenotherm (<FigureInnerLinkComponent text="Figure 6b,e" anchor="Figure-6"/>). This result agreed with Brown’s theory (Brown 1984), although we saw earlier that this result also depends on the realized environment and the presence of geographical barriers (<FigureInnerLinkComponent text="Figure 3" anchor="Figure-3"/>). In the case of a theoretical stenotherm (thermal optimum us=15°C and thermal tolerance ts=1°C), the thermal sensitivity increases and the overall species’ abundance declines (<FigureInnerLinkComponent text="Figure 6d-f" anchor="Figure-6"/> versus <FigureInnerLinkComponent anchor="Figure-6" text="Figure 6a-c"/>). Although the same phenological pattern is expected at its low and high-latitude boundary, this species does not occur throughout the year at its range center (<FigureInnerLinkComponent anchor="Figure-6" text="Figure 6f"/>). Zone 2 (<FigureInnerLinkComponent anchor="Figure-6" text="Figure 6f"/>) is therefore narrower and the species can only persist for a few months. It follows that a stenotherm will react quickly to an increase in temperature even at the center of its range. The stenotherm is therefore expected to be more sensitive to a climate-mediated shift in temperature. Although Brown’s theory, which holds true for many marine organisms (Sunday et al. 2012), was exclusively proposed to explain the relationship between niche breadth and distributional range, our framework suggests that species’ ecological niche can also explain how species respond to changes in temperature in time and space.</p>
          <TheoryFigure
            anchor="Figure-6"
            text="Figure 6"
            definition=". Theoretical relationships between species distribution, latitudinal range and phenology of two cold-water species: one eurytherm and one stenotherm. Theoretical thermal niche of an (a) eurytherm and (d) a sternotherm. Theoretical mean annual spatial distribution of (b) eurytherm and (e) stenotherm species. Theoretical changes in abundance as a function of latitudes and months for (c) eurytherm and (f) stenotherm species. Zone 1: part of the distribution where seasonal maximum occurs in spring/winter. Zone 2: part of the distribution where seasonal extent is highest. Zone 3: part of the distribution where seasonal maximum is located at the end of summer. From Beaugrand and co-workers (Beaugrand et al. 2014b)."
            link="../images/partII/FIGURE 6.png"
          >
          </TheoryFigure>
          <p className="text-normal">Our theoretical model’s predictions can be established for other types of species (e.g. thermophile species, <FigureInnerLinkComponent text="Figure 7" anchor="Figure-7"/>). The figure shows a species having an optimum of 28°C (and thermal tolerance of 5°C; <FigureInnerLinkComponent text="Figure 7a" anchor="Figure-7"/>). This species occurs in the tropics in all seasons but is predicted to occur in late summer (August-September) in some extratropical regions (<FigureInnerLinkComponent anchor="Figure-7" text="Figure 7b-c"/>). A more stenotherm species (us=28°C and ts=1°C; <FigureInnerLinkComponent text="Figure 7d" anchor="Figure-7"/>) is expected to have a more restricted spatial distribution (<FigureInnerLinkComponent text="Figure 7e" anchor="Figure-7"/>) and to exhibit a seasonal maximum polewards between September and October (<FigureInnerLinkComponent text="Figure 7f" anchor="Figure 7"/>). </p>
          <TheoryFigure
            anchor="Figure-7"
            text="Figure 7"
            definition=". Theoretical relationships between species distribution, latitudinal range and phenology of two warm-water species: one eurytherm and one stenotherm. Theoretical thermal niche of an (a) eurytherm and (d) a sternotherm. Theoretical mean annual spatial distribution of (b) eurytherm and (e) stenotherm species. Theoretical changes in abundance as a function of latitudes and months for (c) eurytherm and (f) stenotherm species. Zone 1: part of the distribution where seasonal maximum occurs in spring/winter. Zone 2: part of the distribution where seasonal extent is highest. Zone 3: part of the distribution where seasonal maximum is located at the end of summer. From Beaugrand (Book in preparation)."
            link="../images/partII/FIGURE 7.png"
          >
          </TheoryFigure>
          <p className="text-normal">Beaugrand and colleagues (Beaugrand et al. 2014b) compared predictions from the METAL theory with observed abundance of Calanus finmarchicus inferred from the Continuous Plankton Recorder (CPR) survey (Reid et al. 2003). They modelled the spatial distribution of C. finmarchicus as a function of SST, PAR, chlorophyll-a concentration and bathymetry using the NPPEN model. They compared the expected and observed abundance of this key copepod in the Northeast Atlantic (<FigureInnerLinkComponent text="Figure 8a-b" anchor="Figure-8"/>).  Both predicted and observed abundance of C. finmarchicus as a function of latitudes and months were strongly correlated (r=0.84, n=259, p{"<0.001"}). The spatio-temporal pattern in expected abundance was however more concentrated than observed abundance (<FigureInnerLinkComponent anchor="Figure-8" text="Figure 8a-b"/>). At the end of the seasonal occurrence of the species (in summer towards higher latitudes), the level of abundance remained elevated whereas expected abundance drops. This lag was explained by the fact that when the environment becomes less suitable, the species may remain a certain amount of time before decreasing in abundance (diapause initiation and source/sink dynamics) (Helaouët et al. 2011; Pulliam 1988). At the beginning of the seasonal occurrence, the lag between expected and observed abundance was much less pronounced and was explained by the time needed for the species to increase its level of abundance (reproduction and individual growth)(Helaouët et al. 2011). </p>
          <TheoryFigure
            anchor="Figure-8"
            text="Figure 8"
            definition=". Latitudinal and seasonal changes in both expected and observed abundance of C. finmarchicus based on the periods 1960-1979 (a and b, respectively) and 1990-2009 (c and d, respectively). Both expected and observed C. finmarchicus abundance were calculated for a meridional band between 30°W and 10°W (Northeast Atlantic). Scaled between 0 and 1, scatterplots in b and d exhibit expected abundance versus observed abundance for a-b and c-d, respectively. Both vertical and horizontal dashed lines (a-d) are superimposed to better reveal phenological and biogeographical shifts. Modified, from Beaugrand and co-workers (Beaugrand et al. 2014b)."
            link="../images/partII/FIGURE 8.png"
          >
          </TheoryFigure>
          <p className="text-normal">They subsequently made the same comparison for a warmer decade (Beaugrand et al. 2014b).  Expectations from METAL were (1) a reduction in the level of abundance in spring resulting in an erosion of the spatial distribution of the species at its southern margin and (2) a reduction in the abundance of the species in late summer to the north. They found good support for both predictions and explained 70.56% of the total variance of the combined phenological and biogeographical changes (<FigureInnerLinkComponent text="Figure 8c-d" anchor="Figure-8"/>). As expected, and because the species overwinters in deep water and PAR is positively correlated to phytoplankton production in winter in these areas (Behrenfeld 2010), the copepod could not compensate for the increase in temperature observed between March and September in the southern part of its current distribution during this season. In the central part of its range (~60°N), rising temperature had a negative effect on the abundance of the species in summer. An increase in temperature is expected to generate a poleward shift in the species spatial distribution.</p>
          <p className="text-normal">Investigating long-term phenological shift of the North Sea plankton, Edwards & Richardson (Edwards and Richardson 2004) found no phenological shift for spring diatoms. They proposed that the phenology of these diatoms did not shift because the reproduction of the taxonomic group was mainly controlled by the photoperiod. METAL allows us to complete this hypothesis. Spring North Sea diatoms cannot shift their phenology in winter because most species are located to their southern boundary and cannot compensate for an increase in sea temperature in winter because reduced winter PAR limits their proliferation. Spring North Sea diatoms should therefore decline in the future if the warming continues. In contrast, Edwards & Richardson (Edwards and Richardson 2004) found that summer diatoms and dinoflagellates exhibited an earlier seasonal occurrence (their figure 1), a result predicted by our theory.</p>
          <p className="text-subtitle bold">Community level</p>
          <p className="text-normal">Because we have demonstrated at the organizational species level that species phenology and spatial range, as well as phenologic and biogeographic shifts and that therefore long-term changes in abundance in space and time, are mainly the result of the niche/environment (here thermal niche/thermal regime interaction) interaction, we can create pseudo-species, each having a different ecological niche. Those species are subsequently allowed to colonize a given oceanic region so long as they can survive changes in the environmental regime at different temporal scales. Niche overlapping is allowed but no species can have the same ecological niche in a given region, after the principle of competitive exclusion (Gause 1934). </p>
          <p className="text-normal">By reconstructing pseudo-communities, we can then investigate the origin of various ecogeographic patterns (e.g. latitudinal gradients in biodiversity, Rappoport’s effect), community processes such as seasonal succession, community’s properties and their consequences for ecosystem functioning, regulating and provisioning services (Beaugrand 2014; Beaugrand et al. 2015; Beaugrand et al. 2013). We detail here only the application of the METAL theory to abrupt community shifts (Beaugrand 2015b; Beaugrand et al. 2019; Beaugrand et al. 2014b). More information will be available in the book in preparation.</p>
          <p className="text-normal">An ecosystem regime shift is often defined as a substantial and relatively rapid shift between two contrasting stable states (de Young et al. 2008). A common explanation to the origin of some shifts refers to the theory of alternative stable states (Holling 1973; Scheffer 2009; Scheffer and Carpenter 2003). The theory stipulates that, for a given system, some alternative stable states or attractors are possible and that the shift from one system to another depends on the size of the attraction basin and the strength of both positive and negative feedbacks. The transition from one state to another is difficult to predict, and the return to initial environmental conditions is not sufficient for the system to switch back in the case of hysteresis (Scheffer and van Nes 2004). Regime shifts are a form of Abrupt Community Shift (ACS), although the last term does not necessitate the existence of stable states. ACSs are just defined by the increase in the variance associated to a shift (Beaugrand et al. 2008).</p>
          <p className="text-normal">METAL may also help to understand the origin and mechanisms involved in observed ACSs (Beaugrand et al. 2014a; Reid et al. 2016). In addition, METAL connects year-to-year changes in local abundance, phenological, biogeographical shifts at the species organizational level and long-term changes in community including ACSs at the species organizational level (Beaugrand et al. 2014b).</p>
          <p className="text-normal">To demonstrate that ACSs may result from the niche/environment interaction, we can first generate pseudo-species each having a unique niche (<FigureInnerLinkComponent text="Figure 9a" anchor="Figure-9"/>). We then estimated the abundance of each pseudo-species from the knowledge of their niche and using a constant thermal regime, here 17.5°C. Substantial shifts in the abundance of these pseudo-species take place when temperature is artificially increased from 17.5°C to 18.5°C (middle of time series; <FigureInnerLinkComponent text="Figure 9b" anchor="Figure-9" />). A single degree of temperature rise is sufficient to trigger abrupt changes in the abundance of some eurytherms and stenotherms, especially those with an initial thermal regime close to THV (<FigureInnerLinkComponent text="Figure 9a,b" anchor="Figure-9"/>); an augmentation of the abundance of thermophiles (orange) was associated to a reduction in the abundance of psychrophiles (blue). No shift was found for pseudo-species with an initial thermal regime close to Topt (green). The magnitude of the change was greater for stenotherms because they are more sensitive to temperature (<FigureInnerLinkComponent text="Figure 9a-b" anchor="Figure-9"/>). However, stenotherms with an initial thermal regime close to the edge of their thermal niche (outside TD) exhibited only minor changes in abundance (<FigureInnerLinkComponent text="Figure 9a-b" anchor="Figure-9"/>; black and red). This point was further investigated in Beaugrand  (Beaugrand 2015a) and it was clear that the sensitivity of a community should also depend upon the degree of stenothermy/eurythermy of species (his figure 3).</p>
          <TheoryFigure
            anchor="Figure-9"
            text="Figure 9"
            definition=". An example showing how climate-induced shift in temperature, by its interaction with the thermal niche of each species, may trigger an ACS. (a) Eight pseudo-species’ niches with thermal optima of us=12°C (black) and us=14°C (blue) for more psychrophiles, us=18°C (green), us=22°C (orange) and us=24°C (red) for more thermophiles. The thermal amplitude for more stenotherms was ts=2°C (dashed line) and ts=4°C for more eurytherms (filled line). (b) Idealized shifts triggered by a rise in the thermal regime of 1°C at the middle of the time series. The rectangles in (b) highlight that the stenotherm species are more sensitive than more eurytherm species. Modified, from Beaugrand (Beaugrand 2015b)."
            link="../images/partII/FIGURE 9.png"
            className="theoryPage-I-1Tier"
          >
          </TheoryFigure>
          <p className="text-normal">When the abundance of pseudo-species was assessed from a time series of temperature having the same variability than long-term changes in temperatures observed in the North Sea for the period 1958–2010 but a different average (mean average of 17.5°C versus 10.2°C in the North Sea), the same conclusion was found (<FigureInnerLinkComponent text="Figure 10a-b" anchor="Figure-10"/>). When the eight pseudo-species were combined and their long-term changes analysed by standardized principal component analysis (PCA on the table years x pseudo-species), the first principal component (PC, 91.4% of the total variance) revealed major changes in this pseudo-community, including a stepwise and rapid shift at the end of the 1980s (<FigureInnerLinkComponent text="Figure 10c" anchor="Figure-10"/>). A change-point analysis (Taylor 2000) detected a shift in 1988 (<FigureInnerLinkComponent text="Figure 10c" anchor="Figure-10"/>; in red), corresponding to the timing of the regime shift originally proposed in the North Sea (Reid et al. 2001). In such ecosystems, the framework predicts that the response of the community is directly a function of the magnitude of the thermal shift and the degree of stenothermy in the community.</p>
          <TheoryFigure
            anchor="Figure-10"
            text="Figure 10"
            definition=". An example showing how climate-induced shift in temperature, by its interaction with the thermal niche of each species, may trigger an ACS. (a) Eight pseudo-species’ niches with thermal optima of us=12°C (black) and us=14°C (blue) for more psychrophiles, us=18°C (green), us=22°C (orange) and us=24°C (red) for more thermophiles. The thermal amplitude for more stenotherms was ts=2°C (dashed line) and ts=4°C for more eurytherms (filled line).  (b) Predicted long-term changes in the abundance of each pseudo-species from the knowledge of the thermal niche and sea surface temperature. (c) Long-term expected changes in the pseudo-community inferred from the first principal component of a Principal Component Analysis (PCA) performed on the table 53 years (1958–2010) pseudo-species."
            link="../images/partII/FIGURE 10.png"
            className="theoryPage-I-1Tier"
          >
          </TheoryFigure>
          <p className="text-normal">METAL was tested against a real planktonic (copepod) community in the North Sea (<FigureInnerLinkComponent text="Figure 11" anchor="Figure-11"/>). A total of 10,000 expected long-term community states were generated (<FigureInnerLinkComponent text="Figure 11" anchor="Figure-11"/>; curves in black). Positive values reflected a warmer dynamic regime and negative values a colder dynamic regime. Most long-term expected patterns exhibited a warm dynamic regime between 1958 and 1962 and then a colder dynamic regime until the end of the 1980s, periods well identified in previous studies (Beaugrand and Ibanez 2004). The cold-ocean climate anomaly of 1978–1982 was also correctly reproduced (Edwards et al. 2002). After an abrupt shift at the end of the 1980s, the system remained in a warm dynamic regime and a second abrupt shift was detected at the end of the 1990s. When long-term changes in copepod abundance were examined by a PCA (<FigureInnerLinkComponent text="Figure 11" anchor="Figure-11"/>; in red), the first (circa 1988) and second (circa 1998) shifts were well synchronous to expected patterns. Patterns of changes predicted by the theoretical framework were closely related to observed long-term changes (<FigureInnerLinkComponent text="Figure 11" anchor="Figure-11"/>). The correlation coefficients between the 10,000 long-term expected patterns and observed long-term community shifts in copepod abundance were rmean=0.72. All correlations were significant after accounting for temporal autocorrelation at the threshold {"p<0.05"} (Beaugrand et al. 2003).</p>
          <TheoryFigure
            anchor="Figure-11"
            text="Figure 11"
            definition=". Test of the hypothesis that climate-caused abrupt community shift may be triggered by the interaction between changes in temperature and the thermal niche of each species composing a community. A total of 10,000 long-term expected changes was simulated (curves in black) and compared to the first component of long-term copepod abundance changes (1958-2009; curve in red) and to annual sea surface temperature (1958-1999; curve in green). From Beaugrand and colleagues (Beaugrand et al. 2014b)."
            link="../images/partII/FIGURE 11.png"
            className="theoryPage-I-2Tier"
          >
          </TheoryFigure>
          <p className="text-normal">Theories suggest that shifts from one ecosystem state to another may originate from the presence of attractors (Holling 1973; Scheffer et al. 2001). Although attractors are difficult to recognize in the field, there are evidence that they play a key role in critical transitions (Scheffer 2009). For example, attractors have been identified in some lakes where the system alternates between clear and algae bloom phases and in some marine systems, e.g. shifts from a cod- to clupeid-dominated system state in the Baltic Sea (Casini et al. 2009). However, METAL does show that attractors are not the only process by which ACSs may take place and that ACSs may be the consequence of a community to environmental change. Here, we show that interaction between the thermal niche of each species and fluctuations in the thermal regime at the species organisational level propagate at the community scale to trigger ACSs. The same process may also explain long-term changes in community from a year-to-year to a millennial scale. </p>
          <p className="text-subtitle">Litterature cited</p>
          <PartTwoReferences></PartTwoReferences>
          {/* ------------------------------------------------------------------------------------------------------ */}
        </Col>
        <Col sm={0} xs={0} xxs={0}>
        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/rightTop.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
            <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/rightBottom.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
        </Col>
      </Row>
    </div>
  );
};

export default PartFourPage;
