import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import "../../css/publicationPage.css";
const SinglePublicationComponent = (publicationData) => {


  const checkState = (state,meta) => {
    console.log(state,meta);
      switch (state) {
      case "published":
        if(meta.trim() !== "")
          return `Volume ${meta}`;
        return "";
      case "inPress":
        return "In Press";
      case "submitted":
        return "In submission";
      case "revised":
        return "Communications Biology Revised";
      default:
        return "";
      }
    }


  return (
    <div className="publication">
      <Card className="publication-card">
      <a
        href={publicationData.link}
        target="_blank"
        rel="noreferrer"
        className="publication-hyperlink"
      >
        
          <Card.Body>
              <div className="column c-20">
                {publicationData.preview ? (
                  <Image src={`./images/preview/${publicationData.preview}`} width={100} height={132}/>
                ) : (
                  <Image src="./images/preview/2013-Beaugrand-GEB.jpg" width={100} height={132} />
                )}
              </div>
              <div className="column c-80">
                <Card.Title>
                  {publicationData.title} ({publicationData.year})
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {publicationData.author}
                </Card.Subtitle>
                <Card.Subtitle className="publication-text">
                  <b>{publicationData.review}</b> {" "}
                  <em>
                  {checkState(publicationData.state,publicationData.meta)}
                  </em>
                </Card.Subtitle>
              </div>
          </Card.Body>
        
      </a>
      </Card>
    </div>
  );
};

export default SinglePublicationComponent;
