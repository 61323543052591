import { Container } from "react-bootstrap";
import "../../css/responsiveText.css";
const Chapter2 = () => {
  return (
    <div className="left">
      <Container>

        <p className="text-title centered bold">Chapter 2. Foundations of the METAL Theory</p>
        <div className="top-span"></div>
      <p className="text-normal red bold">Animation 2.1.</p>
      <p className="text-normal">
      This animation shows an example of variation in the thermal optimum of a Gaussian niche of a species with a thermal tolerance t=5. Thermal niches move from psychrophiles to more thermophiles. Then the thermal optimum is fixed to 20°C and the tolerance is moved from high stenothermy to high eurythermy. The maximum abundance is fixed to 1. 
      </p>
      <div className="centered">
      <video width="80%" height="auto" controls>
          <source src="/videos/Animation 2.1.mp4" type="video/mp4" />
      </video>
      </div>
      <div className="top-span"></div>
      <p className="text-normal red bold">Animation 2.2.</p>
      <p className="text-normal">
      Asymmetrical Gaussian niches. An asymmetric Gaussian niche can be created by using a different thermal tolerance on the left and right side of the thermal optimum. When the tolerance on the right is smaller, the niche is said left-asymmetrical. When the tolerance on the left is smaller, the niche is said right-asymmetrical. The maximum abundance is fixed to 1. 
      </p>

      <div className="centered">
      <video width="80%" height="auto" controls>
          <source src="/videos/Animation 2.2.mp4" type="video/mp4" />
      </video>
      </div>
      <div className="top-span"></div>
      <p className="text-normal red bold">Animation 2.3.</p>
      <p className="text-normal">
      Examples of two-dimensional niches that can be generated with no dimension interaction (i.e. no correlation). The optima and tolerance of the two dimensions fluctuate in the animation. The maximum abundance is fixed to 1. 
      </p>
      <div className="centered">
      <video width="80%" height="auto" controls>
          <source src="/videos/Animation 2.3.mp4" type="video/mp4" />
      </video>
      </div>
      <div className="top-span"></div>
      </Container>
    </div>
  );
};

export default Chapter2;
