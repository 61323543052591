import { Container } from "react-bootstrap";
import "../../css/responsiveText.css";
const Chapter1 = () => {
  return (
    <div className="left">
    <Container>

      <p className="text-title centered bold">Chapter 1. Introduction</p>
      <div className="top-span"></div>
    <p className="text-normal red bold">Animation 1.1.</p>
    <p className="text-normal">
    Random distribution of individuals of an hypothetical species (pseudo-species) in a square of 100 m x 100 m 
    </p>
    <div className="centered">
    <video width="80%" height="auto" controls>
        <source src="/videos/Animation 1.1.mp4" type="video/mp4" />
    </video>
    </div>
    <div className="top-span"></div>
    </Container>
  </div>
  );
};

export default Chapter1;
