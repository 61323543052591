import { Container } from "react-bootstrap";
import newsData from "../../data/news.json";
import NewBubbleComponent from "./NewBubbleComponent";
const NewsComponent = () => {
  return (
    <Container>
      <p className="text-title bold centered"> Latest research </p>
      <div className="top-span"></div>
      {newsData.data.map((aNews) => {
          return (
            <>
              <NewBubbleComponent
              abstract={aNews.abstract}
              references={aNews.references}
              title={aNews.title}
              preview={aNews.preview}
              />
              <div className="top-span"></div>
              </>
          )}
        )}
    </Container>
  );
};

export default NewsComponent;
