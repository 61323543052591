import "../../css/publicationPage.css";
import SinglePublicationComponent from "../publicationPage/SinglePublicationComponent";

const OutReachComponent = () => {
  return (
    <div className="publication-page">
      <div className="top-span"></div>
      <p className="text-subtitle white">
        In this section, we provide two articles of introduction of the METAL
        theory for predicting climatic surprises in the biological ocean. One is
        in French and one is in english.{" "}
      </p>

      <SinglePublicationComponent
        author="Jacquet S, Beaugrand G"
        year="2019"
        title="Augmentation des surprises climatiques dans l'ocean"
        review="Subaqua"
        meta="74-75"
        link="./ressources/Beaugrand-2019-Subaqua.pdf"
        preview="SubaquaPreview.PNG"
      />

      <SinglePublicationComponent
        author="Beaugrand G"
        year="2019"
        title="Predictions of unprecedented biological shifts in the global ocean"
        review="The Marine Biologist"
        meta="06-07"
        link="./ressources/Beaugrand-2019-The-Marine-Biologist.pdf"
        preview="MarineBiologistPreview.PNG"
      />
    </div>
  );
};

export default OutReachComponent;

