import references from "../../../data/partI/references.json";

const PartOneReferences = (props) => {
  return (
    <ul>
      {references.data.map((string) => {
        return (
          <li>
            <p className="text-small">{string}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default PartOneReferences;
