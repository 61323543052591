import "../../css/publicationPage.css";
import SinglePublicationComponent from "./SinglePublicationComponent";
import Row from "react-bootstrap/Row";
import { Container } from "react-bootstrap";
import data from "../../data/METAL-publication.json";
const PublicationComponent = () => {
  return (
    <div className="publication-page">
      <Container>
              <Row>
      <p className="text-subtitle white">Publications about METAL</p>
        {data.publications.map((onePublication) => {
          return (
            <SinglePublicationComponent
                    author={onePublication.author}
                    year={onePublication.year}
                    title={onePublication.title}
                    review={onePublication.review}
                    preview={onePublication.preview}
                    meta={onePublication.meta}
                    link={onePublication.link}
                    state={onePublication.state}
                  ></SinglePublicationComponent>
                );
              })}
              {/**
          <SinglePublicationComponent
            author="Beaugrand G, Rombouts I, Kirby RR "
            year="2013"
            title="Towards an understanding of the pattern of biodiversity in the oceans"
            review="Global Ecology and Biogeography"
            meta="22: 400-449"
            link="https://onlinelibrary.wiley.com/doi/abs/10.1111/geb.12009"
          />
          <SinglePublicationComponent
            author="Kléparski L, Beaugrand G, Kirby RR"
            year="2022"
            title="How do plankton species coexist in an apparently unstructured environment?"
            review="Biology Letters"
            meta=""
          />
           */}
        </Row>
      </Container>
    </div>
  );
};

export default PublicationComponent;
