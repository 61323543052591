import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

/**
 * The footer contains the most important mention, copyright that ensure the protection of the website content and ressources
 * It also contains logos of organizations supporting and working with METAL searchers.
 * @returns The "footer" of the page, (the bottom of the page) always shown 
 */
const Footer = () => {
  return (
    <footer className="footer">
      <hr className="footer-hr"></hr>
      <h5>Related Organizations</h5>
      <Container>
        <Row xxl={5} xl={5} lg={5} md={5} sm={5} xs={5} xxs={5}>
          <Col>
            <a href="https://log.cnrs.fr" target="_blank" rel="noreferrer">
              <img
                src="./images/organizationLogos/log2.png"
                alt="LOG"
                className="footer-icon-img"
              ></img>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.cnrs.fr/fr/page-daccueil"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./images/organizationLogos/cnrs.png"
                alt="CNRS"
                className="footer-icon-img"
              ></img>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.insu.cnrs.fr/fr/institut-national-des-sciences-de-lunivers"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./images/organizationLogos/insu.png"
                alt="INSU"
                className="footer-icon-img"
              ></img>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.univ-littoral.fr/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./images/organizationLogos/ulco.jpg"
                alt="ULCO"
                className="footer-icon-img"
              ></img>
            </a>
          </Col>
          <Col>
            <a
              href="https://www.univ-lille.fr/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="./images/organizationLogos/ulille.png"
                alt="Université de Lille"
                className="footer-icon-img"
              ></img>
            </a>
          </Col>
        </Row>
      </Container>
      <hr className="footer-hr"></hr>
      <p>© All Rights Reserved - 2022</p>
    </footer>
  );
};
export default Footer;
