import { Button, Container, Col, Row,Accordion } from "react-bootstrap";

const BookComponent = () => {
  return (
    <div>
      <Container>
        <p className="text-title bold">Book 1 : Marine Biodiversity, Climatic Variability and Global Change</p>
        <hr className="thick-hr"></hr>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} xxs={6}>
            <img
              src="/images/bookFrontPage.jpg"
              alt="Missing book front page"
              className="column-img"
              style={{ maxWidth: "100%" }}
            />
          </Col>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} xxs={6}>
            <img
              src="/images/bookHardFrontPage.jpg"
              alt="Missing book front page"
              className="column-img"
              style={{ maxWidth: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          
        <Col
            style={{ margin: "auto"}}
          >
            <div className="top-span"></div>
            <hr className="thick-hr"></hr>
            <p className="text-subtitle bold centered">Summary of the book</p>
            <div className="top-span"></div>
            <div className="text-normal left">
            <p>
            The book reviews parameters and processes that control marine biodiversity in time and space and at all organisational levels. Theories and hypotheses proposed to explain the arrangement of biodiversity in time and space are reviewed in depth. The relationships between marine biodiversity and ecosystem functioning and services and the many direct influences of human activities on marine biodiversity are investigated and potential consequences for human being are reviewed. 
            </p>
            <p>
            In detail, the book has 3 main parts and includes 12 chapters. After introducing marine biodiversity (Chapter 1), the first part of this book (Chapters 2-6) investigates the influence of natural hydro-climatic and environmental changes on biodiversity at all spatial and temporal scales and all organisational levels. 
            </p>
            <p>      
            <span className="red-text-span bold"> Chapter 2 </span>makes an overview of the atmosphere and the Earth’s radiation budget, describes the main climatic regions on Earth, summarises global atmospheric and oceanic circulations, and proposes a short description of the oceanic hydrosphere. Hydro-climatic processes and mechanisms that can potentially affect marine biodiversity patterns are also reviewed. This chapter contains the information needed to better understand how hydro-climatic and environmental changes as well as meteorological events may affect marine biodiversity. 
            </p>
            
            <p>
            <span className="red-text-span bold">Chapter 3</span> investigates how the biosphere has been partitioned in both the terrestrial and the marine realms and examines which abiotic factors control the locations of the primary ecological compartments on Earth. Ecogeographic patterns observed in the marine environment and mechanisms causing these patterns are subsequently examined. 
            </p>
            <p>
            <span className="red-text-span bold">Chapter 4</span> investigates in great detail latitudinal biodiversity patterns and reviews the main factors, hypotheses or theories that have been proposed to explain them. Neutral and null models or theories, the area hypothesis, the effect of history, hypotheses based on fundamental processes, climate and environmental hypotheses, the effects of evolutionary rates and biotic interactions are all examined.
            </p>
            <p>
            <span className="red-text-span bold">Chapter 5</span> investigates the relationships between marine biodiversity, climate and the environment at temporal scales ranging from million years to intraseasonal and synoptic scales. Paleoclimatic changes are examined at tectonic, orbital, millennial and sub-millennial frequency bands. Global extinctions that punctuated the geological time period and their likely causes are reviewed. Natural contemporaneous changes are explored from the effects of meteorological events (e.g. cyclones, heat waves) to seasonal, year-to-year and decadal hydro-climatic changes, including long-term and abrupt ecosystem shifts. The chapter concludes by showing why climate is a strong driver of temporal changes in marine biodiversity. 
            </p>
            <p>
            <span className="red-text-span bold">Chapter 6</span> shows how temperature affects marine biodiversity at the physiological, behavioural, biological and ecological levels. First, I explain the role of this parameter prior to the establishment of the planetary ecosphere. Second, I review some fundamental laws involving temperature. Third, I evaluate the effects of temperature at the physiological level. Finally, I review the influence of temperature at the species and community levels. 
            </p>
            <div className="top-span"></div>
            <p>
            The second part of the book (Chapters 7-10) explores the potential effects of global change (e.g. pollution, eutrophication, exploitation, aquaculture), including anthropogenic climate change and ocean acidification, on marine biodiversity. The impacts of marine biodiversity loss for ecosystem functioning and both provisioning and regulating services are also examined.
            </p>
            <p>
            <span className="red-text-span bold">Chapter 7</span> reviews the potential influences of anthropogenic climate change on marine biodiversity. Human alteration of the greenhouse effect and its influence on the Earth’s radiative budget and the oceans are reviewed. The implications of current anthropogenic climate change are explored. I detail four types of species responses to climate change: (1) physiological adjustment; (2) adaptive evolution; (3) species niche tracking (phenological, biogeographical shifts) and (4) species extinction. Ecosystem responses to anthropogenic climate change are also explored. The chapter ends by comparing anthropogenic climate change with natural sources of climatic variability. 
            </p>
            <p> 
            <span className="red-text-span bold">Chapter 8</span> is about the effects of ocean acidification on marine biodiversity. The chapter explains why increasing CO2 concentrations lead to ocean acidification. Then, the effects of ocean acidification on different taxonomic groups and ecosystems are explored and I show the complexity of the responses of species and ecosystems to ocean acidification, highlighting the potential drawbacks of past studies and especially the lack of consideration of the synergistic effects of acidification and temperature. 
            </p>
            <p>
            <span className="red-text-span bold">Chapter 9</span> reviews the direct effects of human activities on marine biodiversity. The consequences of species exploitation for marine biodiversity are reviewed. The influences of all types of pollution are then investigated. Consequences of hypoxia due to eutrophication or climate change are also explored. Biodiversity erosion due to species invasion is studied and the alteration of the biodiversity by ultra-violets and tourism are outlined. I also review marine species vulnerability to human activities and conclude the chapter by showing some examples of interactive effects among anthropogenic stressors. 
            </p>
            <p>
            <span className="red-text-span bold">Chapter 10</span> reviews the relationships between biodiversity, ecosystem functioning, ecosystem goods and services. In the first section, the chapter investigates the relationships between biodiversity and ecosystem functioning (stability, ecosystem productivity, functional diversity, trophic food webs) at all spatial and temporal scales, including the geological time scale and examines the debate on the role of biodiversity for ecosystem functioning. In the second section, the relationship between biodiversity and ecosystem goods and services is reviewed. This section covers the economic quantification of biodiversity, the role of some important marine ecosystems, meta-analyses on the effects of biodiversity for goods and services, potentially harmful species and the great potential of marine biodiversity in medicine. The third section talks about the potential effects of changes in marine biodiversity for global biogeochemistry and potential feedbacks of changes in marine biodiversity for the Earth’s system. 
            </p>
            <div className="top-span"></div>
            <p>
            The third and final part of the book (<span className="red-text-span bold">Chapter 11</span>) is devoted to theorising and scenarising the effects of hydro-climatic and environmental changes on marine biodiversity. The MacroEcological Theory on the Arrangement of Life (METAL) is outlined. This unifying ecological theory explains how marine biodiversity is organised in space and time and how it reacts to climate and environmental changes. As the elementary macroscopic unit of this theory is based on the ecological niche, the chapter first reviews this concept in detail. In the next section, the fundamentals of the theory are presented and I show that the theory explains the arrangement of marine life and all biological responses documented so far in the context of climate change: local changes in abundance including local extirpation, phenological and biogeographical shifts and both long-term gradual and abrupt community/ecosystem shifts. Strengths and weakness of the present version of the theory is reviewed and current or absolute limits to predictions in the context of global change explored. This chapter concludes by showing how we currently establish scenarios of changes in marine biodiversity and the limits to such projections.
            </p>
            <p>
            I think it is high time to develop a unifying ecological theory to understand and predict how biodiversity is organised and how it responds to climate change. A unifying theory is outlined in this book and based on the concept of the ecological niche of Hutchinson, which integrates genetic, molecular and physiological processes that are difficult to parametrise in classical approaches because of the large number of parameters that need to be estimated and issues associated with oversimplification and assumptions. The theory should make predictions from the species to the biome level and from small to large spatial/temporal scales. Theories will enable predictions to be carried out, guide future research by defining research priorities and identify both weaknesses and strengths in our current scientific knowledge. This book shows that a large part of the arrangement of biodiversity and its spatial and temporal changes related to environmental and climate changes is quasi-deterministic and can therefore be predicted.
            </p>
            <p>
            Dr Grégory Beaugrand
            </p>
            </div>
            <div className="top-span"></div>
            <hr className="thick-hr"></hr>
            <p className="text-subtitle bold centered">Table of contents</p>
            <div className="top-span"></div>
            <p className="text-subtitle"></p>
          <Accordion defaultActiveKey="0" style={{ color: "black"}} className="left">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Dropdown the table of contents</Accordion.Header>
            <Accordion.Body bsPrefix="custom-accordion">
              <ul>
                <li>1.	Introduction	9</li>
                  <ul>
                    <li> 1.1	Biodiversity from the land to the ocean	10 </li>
                    <li> 1.2	Classification and census of marine biodiversity	12</li>
                      <ul>
                        <li>1.2.1	Classification	12</li>
                        <li>1.2.2	Census of biodiversity	12</li>
                        <li>1.2.3	Marine viruses	15</li>
                        <li>1.2.4	Marine prokaryotes	15</li>
                        <li>1.2.5	Marine eukaryotes	16</li>              
                      </ul>
                    <li>1.3	Organisation of the book	20</li>
                  </ul>
                <li>2.	Large-scale hydro-climatic variability	24</li>
                  <ul>
                    <li>2.1	The composition and structure of the atmosphere	24</li>
                    <li>2.2	The Earth radiation budget	25</li>
                    <li>2.3	The thermal engine	27</li>
                      <ul>
                        <li>2.3.1	Average global atmospheric circulation	28</li>
                        <li>2.3.2	Seasonal variability in atmospheric circulation	31</li>
                      </ul>
                    <li>2.4	Main climatic regions	32</li>
                      <ul>
                        <li>2.4.1	Classification of climates	32</li>
                        <li>2.4.2	Empirical classifications of climates	33</li>
                        <li>2.4.3	Genetic classification of climates	34</li>
                      </ul>
                    <li>2.5	The oceanic hydrosphere	34</li>
                      <ul>
                        <li>2.5.1	the global oceanic circulation assists the atmosphere to attenuate the deficit of energy towards the poles	36</li>
                        <li>2.5.2	The global oceanic circulation: mechanisms, role and consequences	38</li>
                      </ul>
                    <li>2.6	Hydro-climatic variability	43</li>
                      <ul>
                        <li>2.6.1	Sources of hydro-climatic variability	43</li>
                      </ul>
                  </ul>
                <li>3.	Large-scale biogeographic patterns	63</li>
                  <ul>
                    <li>3.1	Biogeography: from the terrestrial to the marine realm	63</li>
                      <ul>
                        <li>3.1.1	First biogeographic partitions and proposed terminology	63</li>
                        <li>3.1.2	Partition of the terrestrial ecosphere	65</li>
                        <li>3.1.3	Terrestrial versus marine biogeography	66</li>
                      </ul>
                    <li>3.2	Primary compartments of the marine ecosphere	67</li>
                      <ul>
                        <li>3.2.1	Potential factors affecting species spatial distribution	67</li>
                        <li>3.2.2	Partitions of the marine ecosphere	69</li>
                        <li>3.2.3	The pelagic realm	71</li>
                        <li>3.2.4	The benthic realm	74</li>
                      </ul>
                    <li>3.3	ecogeographic patterns	78</li>
                      <ul>
                        <li>3.3.1	Bergmann’s pattern	78</li>
                        <li>3.3.2	Allen’s pattern	81</li>
                        <li>3.3.3	Rapoport’s pattern	81</li>
                        <li>3.3.4	Thorson’s pattern	82</li>
                        <li>3.3.5	Other biogeographic patterns	82</li>
                      </ul>
                  </ul>
                <li>4.	Large-scale biodiversity patterns	84</li>
                  <ul>
                    <li>4.1	The search for a primary cause	84</li>
                    <li>4.2	Neutral and null models or theories	85</li>
                      <ul>
                        <li>4.2.1	The neutral theory of diversity and biogeography	85</li>
                        <li>4.2.2	The mid-domain effect	86</li>
                      </ul>
                    <li>4.3	The area hypothesis	87</li>
                    <li>4.4	History	88</li>
                    <li>4.5	Hypotheses based on fundamental processes	89</li>
                      <ul>
                        <li>4.5.1	The equilibrium/nonequilibrium hypothesis	89</li>
                        <li>4.5.2	Metabolic theory of ecology	89</li>
                        <li>4.5.3	Energy partitioning in living systems	94</li>
                        <li>4.5.4	The niche-assembly or the structural theory	95</li>
                      </ul>
                    <li>4.6	The climatic influence	96</li>
                      <ul>
                        <li>4.6.1	The species-energy theory	97</li>
                        <li>4.6.2	Selection of climatic parameters	102</li>
                      </ul>
                    <li>4.7	Temperature	107</li>
                      <ul>
                        <li>4.7.1	Biodiversity and temperature at a basin scale	107</li>
                        <li>4.7.2	Copepod biodiversity and temperature on a global scale	108</li>
                        <li>4.7.3	Relationships between marine biodiversity and temperature from plankton to marine mammals	109</li>
                      </ul>
                    <li>4.8	Environmental hypotheses	109</li>
                      <ul>
                        <li>4.8.1	Environmental stability	109</li>
                        <li>4.8.2	Environmental harshness	110</li>
                        <li>4.8.3	Disturbance	110</li>
                        <li>4.8.4	Habitat heterogeneity	112</li>
                        <li>4.8.5	Hydro-dynamics	112s</li>
                      </ul>
                    <li>4.9	Evolutionary rate	113</li>
                    <li>4.10	Biotic interactions	113</li>
                      <ul>
                        <li>4.10.1	Competition	114</li>
                        <li>4.10.2	Predation	114</li>
                        <li>4.10.3	Herbivory and grazing	116</li>
                        <li>4.10.4	symbiosis	116</li>
                        <li>4.10.5	Are biotic relationships important to explain global biodiversity patterns?	118</li>
                      </ul>
                  </ul>
                <li>5.	Marine biodiversity through time	120</li>
                  <ul>
                    <li>5.1	Paleoclimatic changes	121</li>
                      <ul>
                        <li>5.1.1	biodiversity and climate Coevolution	121</li>
                        <li>5.1.2	The tectonic frequency band	123</li>
                        <li>5.1.3	The Orbital frequency band (1 million to 10,000 years)	127</li>
                        <li>5.1.4	The millennial frequency band (10000 to 1000 years)	131</li>
                        <li>5.1.5	Sub-millennial frequency bands ({"< 1000 years"})	136</li>
                      </ul>
                    <li>5.2	Natural causes of extinction	137</li>
                      <ul>
                        <li>5.2.1	The Late Ordovician mass extinction	139</li>
                        <li>5.2.2	Late Devonian mass extinction	139</li>
                        <li>5.2.3	The Late Triassic mass extinction	140</li>
                        <li>5.2.4	The Late Permian mass extinction	140</li>
                        <li>5.2.5	The end Cretaceous mass extinction	144</li>
                      </ul>
                    <li>5.3	Natural contemporaneous changes	144</li>
                      <ul>
                        <li>5.3.1	Synoptic variability	145</li>
                        <li>5.3.2	Seasonal variability	148</li>
                        <li>5.3.3	Year-to-year variability	154</li>
                        <li>5.3.4	Ocean-climate anomalies	158</li>
                        <li>5.3.5	Pseudo-cyclical or cyclical variability	159</li>
                        <li>5.3.6	Abrupt ecosystem shifts	163</li>
                      </ul>
                    <li>5.4	Is climate the primary factor?	164</li>
                  </ul>
                <li>6.	Temperature and marine biodiversity	165</li>
                  <ul>
                    <li>6.1	temperature from the origin of the universe to early life	165</li>
                    <li>6.2	Basics and First principles	166</li>
                      <ul>
                        <li>6.2.1	Comparison of the effects of temperature on marine and terrestrial species	167.</li>
                        <li>6.2.2	Fundamental laws	168</li>
                      </ul>
                    <li>6.3	Effects of temperature at the physiological level	171</li>
                      <ul>
                        <li>6.3.1	speed of enzymatic reactions	171</li>
                        <li>6.3.2	individual performance	172</li>
                        <li>6.3.3	swimming performance	172</li>
                        <li>6.3.4	growth	173</li>
                        <li>6.3.5	photosynthesis	175</li>
                        <li>6.3.6	biological macromolecules	175</li>
                        <li>6.3.7	biological membranes	175</li>
                        <li>6.3.8	oxygen transport	176</li>
                        <li>6.3.9	reproduction timing	177</li>
                        <li>6.3.10	sex determination	178</li>
                        <li>6.3.11	seasonal acclimatisation	179</li>
                        <li>6.3.12	Stage-dependent sensivity of species to temperature	179</li>
                        <li>6.3.13	Physiological responses of ectotherms to a thermal stress	180</li>
                        <li>6.3.14	Resistance of endotherms to changes in temperatures	182</li>
                      </ul>
                    <li>6.4	Influence of temperature at the species level	183</li>
                      <ul>
                        <li>6.4.1	Temperature and the species’ bioclimatic envelope	183</li>
                        <li>6.4.2	Temperature and thermotaxis	184</li>
                        <li>6.4.3	torpor	187</li>
                      </ul>
                    <li>6.5	Thermal influence at the community level	188</li>
                      <ul>
                        <li>6.5.1	Community state	188</li>
                        <li>6.5.2	Community body size	188</li>
                        <li>6.5.3	Biodiversity	189</li>
                      </ul>
                  </ul>
                <li>7.	Biodiversity and anthropogenic climate change	191</li>
                  <ul>
                    <li>7.1	Human alteration of the greenhouse effect and the radiative budget of the planet	192</li>
                    <li>7.2	Increase in global air and sea surface temperature	195</li>
                    <li>7.3	Species responses to anthropogenic climate change	198</li>
                      <ul>
                        <li>7.3.1	First type of response to climate change: physiological adjustment	198</li>
                        <li>7.3.2	Second type of response to climate change: Adaptation	205</li>
                        <li>7.3.3	Third type of response to climate change: species niche tracking	213</li>
                        <li>7.3.4	Fourth type of response: extinction	218</li>
                        <li>7.3.5	Species vulnerability to anthropogenic climate change	218</li>
                      </ul>
                    <li>7.4	Community/ecosystem response to climate change	222</li>
                      <ul>
                        <li>7.4.1	Biogeographical community shifts	222</li>
                        <li>7.4.2	changes in the ecosystem trophodynamics	225</li>
                        <li>7.4.3	Trophic mismatch and changes in species interaction	226</li>
                        <li>7.4.4	Coupling/decoupling between systems	228</li>
                        <li>7.4.5	Abrupt Ecosystem Shifts	229</li>
                        <li>7.4.6	Anthropogenic climate change and tipping points	231</li>
                      </ul>
                    <li>7.5	anthropogenic climate change and natural hydro-climatic variability	231</li>
                      <ul>
                        <li>7.5.1	Comparison of anthropogenic global change with natural source of climatic variability	231</li>
                        <li>7.5.2	Anthropogenic global change versus natural climatic variability in the North-east Atlantic 232</li>
                      </ul>
                  </ul>
                <li>8.	Marine biodiversity and ocean acidification	234</li>
                  <ul>
                    <li>8.1	Introduction	234</li>
                    <li>8.2	Anthropogenic acidification	236</li>
                    <li>8.3	Effects of acidification on biodiversity	238</li>
                      <ul>
                        <li>8.3.1	Early results	238</li>
                        <li>8.3.2	Complexity	240</li>
                        <li>8.3.3	Acidification in some marine ecosystems	241</li>
                        <li>8.3.4	Acidification and life history stages	242</li>
                      </ul>
                    <li>8.4	Limitations of past studies on ocean acidification	243</li>
                      <ul>
                        <li>8.4.1	Few species have been investigated	243</li>
                        <li>8.4.2	Effects of acidification are species-dependent	244</li>
                        <li>8.4.3	Diversity of mechanisms involved in species’ responses to ocean acidification	245</li>
                        <li>8.4.4	Information gained from in situ studies and long-TERM experiments	247</li>
                        <li>8.4.5	Absence of consideration of the effects of temperature	248</li>
                        <li>8.4.6	CO2 concentration	250</li>
                      </ul>
                    <li>8.5	Conclusions	252</li>
                  </ul>
                <li>9.	Biodiversity and Direct Anthropogenic EFFECTS	253</li>
                  <ul>
                    <li>9.1	Exploitation of marine biodiversity	254</li>
                      <ul>
                        <li>9.1.1	Direct effects of exploitation	254</li>
                        <li>9.1.2	Indirect consequences of fishing	260</li>
                        <li>9.1.3	Aquaculture	262</li>
                      </ul>
                    <li>9.2	Pollution	267</li>
                      <ul>
                        <li>9.2.1	Plastic debris	268</li>
                        <li>9.2.2	Chemical pollution	268</li>
                        <li>9.2.3	Radioactive substances	276</li>
                        <li>9.2.4	Noise pollution	278</li>
                        <li>9.2.5	Thermal pollution	279</li>
                      </ul>
                    <li>9.3	Nutrient enrichment and eutrophication	279</li>
                      <ul>
                        <li>9.3.1	Causes of eutrophication	280</li>
                        <li>9.3.2	The main effects of eutrophication	281</li>
                      </ul>
                    <li>9.4	Oxygen depletion	283</li>
                      <ul>
                        <li>9.4.1	hypoxia caused by eutrophication	284</li>
                        <li>9.4.2	Natural causes of hypoxia	286</li>
                        <li>9.4.3	Hypoxia caused by anthropogenic climate change	288</li>
                      </ul>
                    <li>9.5	Introduction and invasion of exotic species	289</li>
                      <ul>
                        <li>9.5.1	Causes of species introduction	289</li>
                        <li>9.5.2	Accidental species introductions	289</li>
                        <li>9.5.3	Deliberate species introductions	292</li>
                      </ul>
                    <li>9.6	UV-B radiation	293</li>
                      <ul>
                        <li>9.6.1	Discovery of the ozon hole	293</li>
                        <li>9.6.2	Mechanisms of ozone destruction	294</li>
                        <li>9.6.3	Effects of UV on biodiversity	296</li>
                      </ul>
                    <li>9.7	Tourism	297</li>
                    <li>9.8	Extinction	297</li>
                      <ul>
                        <li>9.8.1	Pronounced increase in global extinction rate	298</li>
                        <li>9.8.2	Extinction in the oceans and seas	298</li>
                        <li>9.8.3	Species vulnerability to extinction	299</li>
                      </ul>
                    <li>9.9	Interactive effects	301</li>
                      <ul>
                        <li>9.9.1	Climate and fishing interaction	301</li>
                        <li>9.9.2	Climate and pollution	302</li>
                        <li>9.9.3	Global warming and UV-B	303</li>
                        <li>9.9.4	increase in atmospheric CO2 and temperature	303</li>
                      </ul>
                  </ul>
                <li>10.	Marine biodiversity, ecosystem functioning, services and human well-being	306</li>
                  <ul>
                    <li>10.1	Biodiversity and ecosystem functioning	306</li>
                      <ul>
                        <li>10.1.1	Biodiversity and stability	309</li>
                        <li>10.1.2	Biodiversity and ecosystem productivity	313</li>
                        <li>10.1.3	Functional diversity	315</li>
                        <li>10.1.4	Relationships between marine biodiversity and food webs	316</li>
                        <li>10.1.5	Natural relationships between diversity and ecosystem functioning at the geological scale	318</li>
                        <li>10.1.6	The debate on the role of biodiversity for ecosystem functioning	319</li>
                      </ul>
                    <li>10.2	Biodiversity changes and ecosystem goods and services	321</li>
                      <ul>
                        <li>10.2.1	An economic quantification	321</li>
                        <li>10.2.2	An overview of the influence of some ecosystems for goods and services	324</li>
                        <li>10.2.3	effects of biodiversity on ecosystem goods and services	326</li>
                        <li>10.2.4	Biodiversity changes and potentially harmful species	328</li>
                        <li>10.2.5	Biodiversity changes and chemicals	331</li>
                      </ul>
                    <li>10.3	Potential effects of changes in marine biodiversity for global biogeochemistry	338</li>
                      <ul>
                        <li>10.3.1	marine biodiversity and the global carbon cycle	338</li>
                        <li>10.3.2	Marine biodiversity and The nitrogen cycle	341</li>
                        <li>10.3.3	Marine biodiversity and the global silicon cycle	342</li>
                      </ul>
                    <li>10.4	Potential feedbacks	343</li>
                      <ul>
                        <li>10.4.1	Marine biodiversity and tropical cyclones	343</li>
                        <li>10.4.2	Marine biodiversity and cloud formation	344</li>
                      </ul>
                  </ul>
                <li>11.	Theorising and scenarising biodiversity	346</li>
                  <ul>
                    <li>11.1	Introduction	346</li>
                    <li>11.2	the concept of the ecological niche	347</li>
                      <ul>
                        <li>11.2.1	Towards the notion of ecological niche	347</li>
                        <li>11.2.2	The ecological niche	348</li>
                        <li>11.2.3	Improvements and enlargements of the niche concept	349</li>
                      </ul>
                    <li>11.3	Rationale of the METAL theory	354</li>
                    <li>11.4	The metal theory	355</li>
                      <ul>
                        <li>11.4.1	Understanding of large-scale biodiversity gradients	355</li>
                        <li>11.4.2	Understanding the responses of ecosystems and their biodiversity to climate change	358</li>
                      </ul>
                    <li>11.5	Strength and assumptions of the metal theory	368</li>
                      <ul>
                        <li>11.5.1	Strength of the theory	368</li>
                        <li>11.5.2	Current assumptions of the theory	369</li>
                      </ul>
                    <li>11.6	Limits to predictions in the context of global change	370</li>
                      <ul>
                        <li>11.6.1	Chaos	371</li>
                        <li>11.6.2	Complexity	372</li>
                        <li>11.6.3	Sustainability	373</li>
                        <li>11.6.4	Other Assumptions	374</li>
                      </ul>
                    <li>11.7	Scenarising biodiversity	376</li>
                      <ul>
                        <li>11.7.1	The Non-Parametric Probabilistic Ecological Niche Model	377</li>
                        <li>11.7.2	Examples of application of the niche approach	378</li>
                        <li>11.7.3	Limitations of ecological niche models	380</li>
                      </ul>
                  </ul>
                <li>12.	Conclusions	383</li>
                  <ul>
                    <li>12.1	A macroscopic approach	383</li>
                    <li>12.2	Global monitoring	383</li>
                    <li>12.3	Towards a unifying ecological theory	384</li>
                  </ul>
                <li>13.	Index	386</li>
                <li>14.	References	395</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
          <div className="top-span"></div>
          <Row>
          <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} xxs={6}>
            <Button href="./ressources/bookPreview.pdf" target="_blank">
              See Preview
            </Button>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} xxs={6}>   
            <Button target="_blank" href="https://www.routledge.com/Marine-Biodiversity-Climatic-Variability-and-Global-Change/Beaugrand/p/book/9780415517034">Buy it now</Button>
          </Col>
          </Row>
          <div className="top-span"></div>
        </Col>
      </Row>
        <hr className="thick-hr"></hr>
        <p className="text-title bold">Book 2 : Principles Of Biodiversity Organization And Climate Change Biology</p>
        <hr className="thick-hr"></hr>
        <p className="text-title">Available soon</p>
        {/*<Row>
        <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} xxs={4} style={{margin:"auto",border:"solid","border-radius":"12px"}}>
        <p className="text-normal">Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque qui, ab atque officiis beatae labore dolorum voluptas eveniet facilis aut sequi sit. Fuga laborum odio neque voluptas. Nulla, praesentium doloribus.</p>
        <Button href="./ressources/bookPreview.pdf" target="_blank">See Preview</Button>
        <hr></hr>
        <Button>Buy it now</Button>
        </Col>
        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={8} xxs={8}>
        <img src="/images/bookFrontPage.png" alt="Les OISEAUX" className="column-img" style={{"maxWidth":"100%"}}/>
        </Col>
    </Row>*/}
      </Container>
    </div>
  );
};

export default BookComponent;
