import { Image } from "react-bootstrap";
import "../../css/teamPage.css";

const MemberComponent = (props) => {
    
  const imageWidth = props.imageWidth;
  const imageHeight = props.imageHeight;
  const imageSource = props.imageSource;
  const memberName = props.name;
  const memberDefinition = props.memberDef;
  const divWidth = props.width;
  console.log(props.picture);
  return (
    <div style={{"width": divWidth}}>
    <Image width={imageWidth} height={imageHeight} alt="Can't display the ressource" src={imageSource}></Image>
        <p className="text-small white">
          <span className="bold">{memberName}</span> {memberDefinition}
        </p>
    </div>
  );
};

export default MemberComponent;
