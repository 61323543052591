import { Container } from "react-bootstrap";
import "../../css/responsiveText.css";
const Chapter3 = () => {
  return (
    <div>
      <Container>
        <div className="top-span"></div>
        <p className="text-title center bold">
          Chapter 3. Application of METAL at an intra-specific level.
        </p>
        <div className="top-span"></div>
        <p className="left">
          <span className="text-subtitle red-text-span left">
            Animation 3.1
          </span>
        </p>
        <p className="text-normal left">
          Thermotaxis modelled by METAL. In this model, we created 500
          individuals having the same thermal (Gaussian) niche (xopt=30°C and
          t=5). The index of thermal suitability is shown at the background from
          blue (highly unsuitable) to red (highly suitable). Past trajectories
          remain visible (white circle). The last position of an individual is
          in red circle. A total of 50 iterations was performed here.
          Individuals converged to highly suitable thermal environments.
        </p>
        <video width="80%" height="auto" controls>
          <source src="/videos/Anim31.mp4" type="video/mp4" />
        </video>
        <div className="top-span"></div>
        <p className="left">
          <span className="text-subtitle red-text-span">Animation 3.2</span>
        </p>
        <p className="text-normal left">
          Thermotaxis modelled by METAL. In this model, we created 150
          individuals having the same thermal (Gaussian) niche (xopt=15 and
          t=3). The index of thermal suitability is shown at the background from
          blue (highly unsuitable) to red (highly suitable). Past trajectories
          remain visible (white circle). The last position of an individual is
          in red circle. A total of 25 iterations was performed here.
          Individuals converged to highly suitable thermal environments.
        </p>
        <video width="80%" height="auto" controls>
          <source src="/videos/Anim32.mp4" type="video/mp4" />
        </video>
      </Container>
    </div>
  );
};

export default Chapter3;
