import articleData from "../../data/radio.json";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ClickableRessource from "../utilComponents/ClickableRessource";
const RadioReleaseComponent = () => {
  //Une barre horizontale
  //Puis l'année à gauche en gras en rouge, puis les articles. Attention, il y en a beaucoup d'autres et je te les donnerai progressivement.
  return (
    <div>
      <div className="top-span"></div>
      <p className="text-subtitle">
        In this section, we present a few audio coverage about the METAL theory
        and its use in the field of biogeography and climate change biology.
      </p>
      <Container>
        <div className="top-span"></div>
        {articleData.data.map((oneYearData) => {
          return (
            <Row>
              <hr className="thick-hr"></hr>
              <p className="text-subtitle red bold left">{oneYearData.year}</p>
              {oneYearData.videos.map((video) => {
                return (
                  <ClickableRessource
                    link={video.link}
                    review={video.review}
                    title={video.title}
                    preview={video.preview}
                  ></ClickableRessource>
                );
              })}
            </Row>
          );
        })}
      </Container>
    </div>
  );
};

export default RadioReleaseComponent;
