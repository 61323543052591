import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "../../css/teamPage.css";
import { Col} from "react-bootstrap";
import Image from "react-bootstrap/Image"
import MemberComponent from "./MemberComponent";
import data from "../../data/students.json";
const TeamComponent = () => {
  return (
    <Container className="teamPage">
      <div className="top-span"></div>
        <Row>
          <Col>
            <Image width={200} height={300} alt="Can't display the ressource" src="./images/team/GREGORY_BEAUGRAND.png"></Image>
            <p className="text-small white">Dr Gregory Beaugrand</p>
          </Col>
          <Col xxl={9} xl={9} lg={9} md={8} sm={6} xs={6} xxs={6}>
          <p className="text-normal"> I am a research director at the National Centre for Scientific Research (CNRS)
in France, currently based in the Laboratory of Oceanography and Geosciences (LOG). My fields of
expertise are modelling, theoretical biology, bioclimatology and biogeography. Since my PhD, I have
worked on the influence of hydro-meteorological variability and climate change on marine, and more
recently, on terrestrial biodiversity, and the implications for ecosystem functioning, regulating and
provisioning services. I have written more than 200 scientific papers or reports and the book ‘Marine
biodiversity, climatic variability and global change’. I have worked as part of the IPCC and IPBES
reports. I am currently writing the book entitled ‘Principles of biodiversity organisation and climate
change biology’ where I describe in details the METAL theory outlined in my first book. I have
developed the METAL theory more than 10 years ago and I have worked with my friend and
colleague Dr Richard Kirby and have collaborated with others, some of whom are below. I have
supervised more than 30 students and more than 10 PhDs. My list of publications can be found <a href="./beaugrand-publication">here</a>.
          </p>
          </Col>
        </Row>
          <hr className="thick-hr"></hr>
          <p className="text-title centered">Colleagues</p>
          <div className="top-span"></div>
        <Row xxl={2} xl={2} lg={2} md={2} sm={2} xs={1} className="member-spacing">
        <div style={{"width": 500}}>
          <Image width={500} height={250} alt="Can't display the ressource" src="./images/team/RICHARD_R_KIRBY.png"></Image>
            <p className="text-small white">
                <span className="bold">Dr Richard R Kirby</span> is an independent scientist, filmmaker, author, public speaker &amp; marine expedition guide. Richard has been involved in many METAL papers. His website is <a href="http://www.planktonpundit.org/">here</a>.
              </p>
        </div>
        </Row>
        <div className="top-span"></div>
        <Row className="member-spacing">
        <div style={{"width": 500}}>
          <Image width={500} height={250} alt="Can't display the ressource" src="./images/team/CHRISTOPHE_LUCZAK.png"></Image>
              <p className="text-small white">
                <span className="bold">Dr Christophe Luczak</span> is an assistant professor at the University of Lille. He has studied birds and the
                benthos for decades. Christophe has been involved in some of the METAL papers published recently.
                His website is <a href="http://www.christopheluczak.fr/category/news/">here</a>.
              </p>
              </div>
        <div style={{"width": 300}}>
          <Image width={300} height={400} alt="Can't display the ressource" src="./images/team/ERIC_GOBERVILLE.png"></Image>

              <p className="text-small white">
                <span className="bold">Dr Eric Goberville</span> is an assistant
                professor at Sorbonne University (Paris). Eric has helped with the tests of the METAL theory at
                different time and spatial scales in the marine environment. His website is <a href="https://borea.mnhn.fr/fr/users/eric-goberville">here</a>.
              </p>
        </div>


        </Row>
        <hr className="thick-hr"></hr>
          <p className="text-title centered">Students</p>
          <div className="top-span"></div>
          
        <Row xxl={3} xl={3} lg={2} md={2} sm={2} xs={1} className="member-spacing">
        <div style={{"width": 300}}>
          <Image width={300} height={400} alt="Can't display the ressource" src="./images/team/LOICK_KLEPARSKI.png"></Image>
              <p className="text-small white">
                <span className="bold">Loick Kléparski</span> is a PhD student. Loick has helped with the tests of the METAL theory on
                oceanic islands and in the context of Annual Plankton Succession. His website is <a href="https://www.researchgate.net/profile/Loick-Kleparski">here</a>.
              </p>
              </div> 
              <MemberComponent 
              imageSource="./images/team/LEA_SPECQ.png"
              imageHeight={400}
              imageWidth={300}
              width={300}
              name="Léa Specq"
              memberDef=" was a master student (first year) that worked on the development of METAL at the
              community organisational level using many ecological dimensions. Her report will soon be available."
              ></MemberComponent>

              <MemberComponent 
              imageSource="./images/team/LAURIE_MICHAUD.png"
              imageHeight={400}
              imageWidth={300}
              width={300}
              name="Laurie Michaud"
              memberDef=" was a Master student (first year) and has helped with the improvement of the
              METAL theory on oceanic islands. Her report will soon be available."
              ></MemberComponent>

              <MemberComponent 
              imageSource="./images/team/DANIEL_ELIAHOU.png"
              imageHeight={400}
              imageWidth={300}
              width={300}
              name="Daniel Eliahou"
              memberDef=" was a Master student (second year) that has applied a model from the METAL theory
              to reconstruct changes in marine biodiversity during the Ordocivian period. He has provided
              evidence that the Great Ordovician Biodiversity Event (GOBE) has probably been triggered by the
              cooling of the Ordovician. His report will soon be available."
              ></MemberComponent>

              <MemberComponent 
              imageSource="./images/team/JEREMY_DELOBEL.png"
              imageHeight={400}
              imageWidth={300}
              width={300}
              name="Jérémy Delobel"
              memberDef="is the IT student that has designed and developed the website."
              ></MemberComponent>
        </Row>
        {/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/}
        <hr className="thick-hr"></hr>
        <p className="text-title centered bold">Students</p>
        {data.students.map((subdata) => {
          return (
            <>
          <ol>
          <hr className="small-hr"></hr>
          <p className="text-subtitle bold red">{subdata.category}</p>
            {subdata.students.map((student) => {
              return (<li className="text-normal"  style={{marginTop:"5px",marginBottom:"5px"}}><span className="bold">{student.name}</span> {student.work}</li>)
            })}
          </ol>
          </>
          )
        })}

        {/*--------------------------------------------------------------------------*/}

        <hr className="thick-hr"></hr>
        <p className="text-title centered bold">Post-docs</p>
        {data.postdocs.map((subdata) => {
          return (
            <>
          <ol>
          {/* <p className="text-subtitle">{subdata.category}</p> */}
          {subdata.students.map((postdoc) => {
            return (<li className="text-normal"  style={{marginTop:"5px",marginBottom:"5px"}}><span className="bold">{postdoc.name}</span>{postdoc.work}</li>)
          })}
          </ol>
          </>
          )
        })}

      </Container>
  );
};

export default TeamComponent;
