import "../../App.css";
import HomeCarouselComponent from "./HomeCarouselComponent";
import "../../css/homePage.css";
import "../../css/responsiveText.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ClickableCopyrightImage from "../utilComponents/ClickableCopyrightImage";
import { Image } from "react-bootstrap";
function Home() {
  return (
    <div className="homePage">
      <div className="homePage-span"></div>
      <Container>
        <Row>
          <Col>
            <Image
              src="./images/homeRessources/drBeaugrand.jpg"
              className="homePage-vertical-streamer-img"
              fluid
            ></Image>
            <p className="left text-small">Dr Grégory Beaugrand</p>
            <p className="left text-small">Centre National de la Recherche Scientifique (CNRS).</p>
          </Col>
          <Col xxl={9} xl={9} lg={9} md={9} sm={9} xs={9} xxs={9}>
            <p className="text-normal">
              Since the end of the 1990s I have been working on the
              relationships between marine biodiversity, hydro-meteorological
              variability and anthropogenic climate change. My field of research
              is modelling, marine biogeography and climate change biology. I
              first started to document the responses of species and communities
              to global warming by analysing plankton and fish data in the North
              Atlantic Ocean and its adjacent seas where I found changes in
              local abundance, phenological and biogeographical shifts, and
              alterations in biodiversity. At the end of the 2000s I started to
              develop an approach, based on niche theory, to simulate the
              spatial distributions of plankton and fish. My ultimate goal was
              to better understand how the biosphere may reorganise in the
              context of climate change and I thought that, as a starting point,
              we first needed a firm understanding of how biodiversity is
              currently organised in space and time at different organisational
              levels. This knowledge was absent.
            </p>
          </Col>
        </Row>
        <div className="homePage-span"></div>
        <Row>
          <p className="text-normal">
            I am based in Wimereux (Laboratory of Oceanography and Geosciences)
            north of France where I work for the French National Center for
            Scientific Research (CNRS) and from where I travelled regularly to
            the UK to collaborate with the Sir Alister Hardy Foundation for
            Ocean Science (SAHFOS), the latter is now the Continuous Plankton
            Recorder (CPR) survey at the Marine Biological Association of the
            UK. During this time, I came to the idea that the niche may unify
            global biodiversity patterns and I developed a theory I called the
            Macro-Ecological Theory on the Arrangement of Life (METAL). In
            science, we never work alone and I have developed this theory,
            beneficiating from discussions with many colleagues and friends such
            as Dr Richard R Kirby, my students, and the past and current work of
            all our scientific community. I am also indebted to my former phD
            supervisors (Dr Frédéric Ibãnez and Pr Philip Chris Reid).
          </p>
          <p className="text-normal">
            The main goal of this website is to explain what the METAL theory is
            and why it may improve our knowledge of biodiversity organisation
            and climate change biology. Although the METAL theory was originally
            developed for the marine realm I am now testing it in the
            terrestrial realm as well. All of the contents published on this
            website will soon be available in a book entitled “Principles of
            biodiversity organisation and climate change biology”. Although
            METAL is a theory I have developed for more than 10 years now, it is
            still incomplete and so I would welcome comments and questions about
            its current and future development.
          </p>
        </Row>
        <hr className="delim-hr medium-hr"></hr>
      </Container>
      <div className="homePage-span">
        <Container>
          <Row>
            <Col>
              <ClickableCopyrightImage
                link="./images/homeRessources/planktonpundit2.png"
                className="homePage-vertical-streamer-img"
                author="Dr Richard R Kirby"
              ></ClickableCopyrightImage>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={4} xxs={4}>
              <p className="text-subtitle centered bold">
                What is the METAL theory ?
              </p>
              <div className="homePage-span"></div>
            </Col>
            <Col>
              <ClickableCopyrightImage
                link="./images/homeRessources/planktonpundit1.png"
                className="homePage-vertical-streamer-img"
                author="Dr Richard R Kirby"
              ></ClickableCopyrightImage>
            </Col>
          </Row>
          <Row>
            <Col>
              <ClickableCopyrightImage
                link="./images/homeRessources/Ecureuil.png"
                author="Sarah Beaugrand"
                className="homePage-vertical-streamer-img"
              ></ClickableCopyrightImage>
              <ClickableCopyrightImage
                link="./images/homeRessources/Anémone pulsatile.png"
                author="Sarah Beaugrand"
                className="homePage-vertical-streamer-img"
              ></ClickableCopyrightImage>
              <ClickableCopyrightImage
                link="./images/homeRessources/Libellule.png"
                className="homePage-vertical-streamer-img"
                author="Sarah Beaugrand"
              ></ClickableCopyrightImage>
              <ClickableCopyrightImage
                link="./images/homeRessources/PhotoCoursEau.jpg"
                className="homePage-vertical-streamer-img"
                author="Sarah Beaugrand"
              ></ClickableCopyrightImage>
            </Col>
            <Col xs={7}>
              <p className="text-normal">
                <b>
                  The MacroEcological Theory on the Arrangement of Life (METAL)
                </b>{" "}
                proposes that biodiversity is strongly influenced by climate and
                environment in a deterministic manner. This influence mainly
                occurs through the interactions between the ecological niche of
                species sensu Hutchinson (i.e. the range of a species tolerance
                when several factors are taken simultaneously), as an elementary
                emergent macroscopic process. The theory enables us to predict
                and unify (i) at the species level local changes in abundance,
                species phenology, and biogeographic range shifts, and (ii) at
                the community level, the arrangement of biodiversity in space
                and time as well as long-term community/ecosystem shifts,
                including regime shifts. This theory offers a way to make
                testable ecological and biogeographical predictions to
                understand how life is organized, and how it responds to global
                environmental changes. The theory might ultimately lead to the
                enunciation of key principles of biodiversity organization and
                climate change biology.
              </p>
              <div className="homePage-span"></div>
              <HomeCarouselComponent></HomeCarouselComponent>
            </Col>
            <Col>
              <ClickableCopyrightImage
                link="./images/homeRessources/Lézard vert.png"
                author="Sarah Beaugrand"
                className="homePage-vertical-streamer-img"
              ></ClickableCopyrightImage>
              <ClickableCopyrightImage
                link="./images/homeRessources/Gentiane printanière.png"
                author="Sarah Beaugrand"
                className="homePage-vertical-streamer-img"
              ></ClickableCopyrightImage>
              <ClickableCopyrightImage
                link="./images/homeRessources/Jeune chamois.png"
                author="Sarah Beaugrand"
                className="homePage-vertical-streamer-img"
              ></ClickableCopyrightImage>
              <ClickableCopyrightImage
                link="./images/homeRessources/PhotoMarin.jpg"
                author="Sarah Beaugrand"
                className="homePage-vertical-streamer-img"
              ></ClickableCopyrightImage>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Home;
