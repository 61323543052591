import "../../../css/theoryPage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FigureInnerLinkComponent from "../../utilComponents/FigureInnerLinkComponent";
import PartThreeReferences from "./PartThreeReferences";
import TheoryFigure from "../../utilComponents/TheoryFigure";
import ClickableCopyrightImage from '../../utilComponents/ClickableCopyrightImage'
const PartThreePage = () => {
  return (
    <div className="theoryPage">
      <p className="text-big-title centered bold">
        {" "}
        III. A global theory of biodiversity
      </p>
      <hr className="thick-hr"></hr>
      <Row>
        <Col>
        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/leftTop.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
            <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/leftBottom.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
                        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/petrelFulmar.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Christophe Luczak"
            ></ClickableCopyrightImage>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={10} xs={10} xxs={10}>
          <div className="top-span"></div>
          <p className="text-subtitle bold">Summary</p>
          <p className="text-normal">
            Among questions such as the origin of the universe or the biological
            bases of consciousness, understanding the origin and organization of
            planetary biodiversity is one of the twenty-five most important
            scientific enigmas according to the journal American Science (2005).
            In this part, we present the METAL theory and its application to
            understand the arrangement of biodiversity. We think we provide an
            explanation why there are more species at low latitudes than at the
            poles, why the peak of biodiversity is located at mid-latitudes in
            the oceanic domain and at the equator in the terrestrial domain and
            finally why there are more terrestrial than marine species despite
            the fact that biodiversity has emerged in the oceans. We postulate
            that the organization of planetary biodiversity is mathematically
            constrained, a constraint called the chessboard of life, which
            determines the maximum number of species that may colonise a given
            region or domain. This theory also makes it possible to reconstruct
            past biodiversity and understand how biodiversity could be
            reorganized in the context of the disruption of the Earth
            system&#39;s radiative budget and its consequences in terms of
            regional climates.
          </p>
          <p className="text-subtitle bold">Introduction</p>
          <p className="text-normal">
            Biology studies all living systems, all biodiversity, from the
            simplest organic molecules (molecular biology) to large biomes
            (biogeography) by crossing a multitude of organizational levels such
            as cells, tissues, organs, species, biocoenoses and ecosystems. It
            is essentially a science of complexity (
            <FigureInnerLinkComponent
              text="Text Box 1"
              anchor="Text-Box-1"
            ></FigureInnerLinkComponent>
            ). Since the origin of life, whether on Earth or elsewhere,
            biological systems have constantly evolved to adapt to their
            environment (Kump et al. 2004) . Species have been created and
            others have died out at gradual or sometimes more sudden rates,
            apparent balances punctuated by periods when changes occur
            relatively quickly (Gould 2007) . The extraordinary variety of
            species is not only perceptible from a morphological or anatomical
            point of view, but it is also reflected in a multitude of life
            history traits, i.e. traits (size, growth, lifespan) that influence
            reproduction and individual survival. The inventiveness demonstrated
            by the living is almost inexhaustible and its evolving tinkering
            obscures a form of intelligibility that researchers have the mission
            to discover (Gould 1977) . It is a subtle mix between chance and
            necessity (Monod 1971) , chance because inventiveness finds its
            origin in the genetic variability maintained by mutations, intra and
            inter-chromosomal mixing, and necessity because there are
            fundamental limits, whether physical, genetic, physiological or
            ecological.
          </p>
          <p className="text-normal">
            The origin and evolution of biodiversity are now better known.
            Charles Darwin, and neo- Darwinism, laid the solid theoretical
            foundations (Darwin 1859, Mayr 1942, Dobzhansky 1973, Kimura 1983) .
            However, there remains a fundamental question to be resolved. How is
            biodiversity and the species that compose it organized on our planet
            and how is the abundance or number of species modified in space and
            time (Pennisi 2005) ? These questions are fundamental because
            biodiversity strongly influences the functioning of ecosystems and
            thus regulating services such as atmospheric carbon dioxide
            sequestration, but also provisioning services, i.e. the exploitation
            of ecosystems (Naeem et al. 2002, Beaugrand 2015) . On a global
            scale, nearly a billion people are dependent on marine fisheries as
            their main source of animal protein and the marine resource is
            controlled by a set of processes involving environmental parameters
            (including climate) and food web (FAO 2020) . The food security of
            many countries is therefore at stake, but also their economic
            equilibrium. Moreover, to understand how the disruption of the Earth
            system&#39;s radiative budget and its regional climatic consequences
            will affect individuals, species and biocoenoses, the essential
            prerequisite is to understand how these biological systems are
            naturally organized and to identify cardinal factors and mechanisms
            responsible for the alterations, to then anticipate the
            modifications caused by environmental changes.
          </p>
{/** ----------------------------------------------------------------*/}
          <div
            style={{
              border: "solid white 0.6vw",
              borderLeft: "solid white 0.8vw",
              borderRight: "solid white 0.8vw",
              background: "gray",
              "padding-left": "1vw",
              "padding-right": "1vw",
            }}
          >
                      <p className="text-subtitle bold" id="Text-Box-1">
            <span className="red-text-span">Box Text 1</span>: Complexity in ecology and scientific approach adopted within the framework of
the METAL theory to consider it.</p>
            <p className="text-normal centered bold">Complexity in biology</p>
            <ol className="text-small">
              <li>Innumerable actors and factors</li>
              <li>All elements are interconnected and interdependent</li>
              <li>
                Multiple actions and feedbacks at different organizational
                levels and spatio-temporal scales
              </li>
              <li>Nonlinearity (threshold effect, hysteresis).</li>
              <li>
                Emergence of new properties that are difficult to predict from
                properties of the parts.
              </li>
            </ol>
          <div className="top-span"></div>
            <p className="text-normal centered bold">
              How to deal with this complexity within the framework of METAL
              Theory ?
            </p>
            <ol className="text-small">
              <li>
                The system is complex but it can be simplified at certain
                organizational levels (consideration of emergent properties) and
                to certain spatio-temporal scales (at the largest scales).
              </li>
              <li>
                At certain organizational levels and spatio-temporal scales, the
                laws influencing the organization of biodiversity are simple.
              </li>
              <li>
                Non-linearity can be overcome (e.g. the concept of niche
                considers elegantly the non-linear responses of species to
                environmental fluctuations).
              </li>
              <li>
                The use of ecological properties at the organizational level and
                at the relevant spatio-temporal scales enables one to unify
                phenomena, patterns of variability and biological events that
                govern the organization of biodiversity.
              </li>
              <li>
                Their unification give a high level of coherence to the
                phenomena and observed events and improve their understanding
                and predictability.
              </li>
            </ol>
          </div>
          {/** ----------------------------------------------------------------*/}
          <div className="top-span"></div>
          <p className="text-subtitle bold">
            Patterns of variability in nature
          </p>
          <p className="text-normal">
            For millennia, humans have detected recurring patterns of
            variability in nature, patterns or cycles. The first patterns of
            variability detected may have been the constellations, which were
            quickly identified by many civilizations before our era. Claudius
            Ptolemy recorded them from the middle of the second century in the
            Almagest. In biology, the multitude of environments that our planet
            conceals forces species to diversify and biogeography teaches 3 us
            that certain species are present only in tropical environments while
            others are exclusively found in temperate or polar regions (Lomolino
            et al. 2006) . For example,{" "}
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>{" "}
            shows that the spatial distribution of marine zooplankton (animal
            plankton, here copepod crustaceans) exhibits distinct patterns of
            variability (Beaugrand et al. 2003) ; some species are present at
            the level of the cold Labrador current (Calanus glacialis), others
            essentially along the European continental slope (Candacia armata)
            or in the waters of the north (Paraeuchaeta norvegica) or of the
            south (ex: Clausocalanus spp.) of the North Atlantic, or finally at
            the transition between these waters along the North Atlantic Drift
            (Metridia lucens). In addition, bioclimatologists and ecologists
            have noted the existence of cycles where periods of high abundance
            alternate with periods of low abundance or even absence (Russell
            1973, Faillettaz et al. 2019) . In our latitudes, certain species
            flourish in the spring; we speak of spring phenology. Others bloom
            in the summer; we then speak of summer phenology (Caracciolo et al.
            2021) . The presence of these recurrent patterns of variability in
            space or time suggests the existence of control mechanisms, whether
            autogenic or allogenic.
          </p>
          <TheoryFigure
            anchor="Figure-1"
            text="Figure 1"
            definition=". Mean spatial distribution of some species of planktonic marine crustaceans called
copepods. Maximum abundance values ​​are in red and zero abundances are in dark blue. The
absence of colour corresponds to an absence of sampling. Some copepods are present in the
icy or cold waters of the North Atlantic Ocean (Pareuchaeta norvegica or Calanus glacialis).
Others are present in practically subtropical waters (Clausocalanus spp., Neocalanus gracilis
and Euchaeta marina). The Para-Pseudocalanus group is rather present in temperate waters,
Metridia lucens at the limit between temperate and cold waters and Candacia armata
mainly south of the European continental slope. These examples show that the distribution
of species is not random at a large scale and that there are therefore control mechanisms."
            link="../images/partIII/FIGURE 1.png"
          ></TheoryFigure>
          <p className="text-subtitle bold">
            The difficult identification of patterns in ecology
          </p>
          <p className="text-normal">
            It is more difficult than it seems to identify these patterns of
            variability in nature and sometimes and especially at small scales,
            it may seem that there are no rules governing the organization of
            biodiversity. Take the example of the simulated distribution of
            individuals from a fictitious species in a hypothetical region (
            <FigureInnerLinkComponent
              text="Figure 2"
              anchor="Figure-2"
            ></FigureInnerLinkComponent>
            ). If we identify the number of individuals in an imaginary
            geographical square of 100 x 100 m, the distribution of individuals
            in this square appears random because no pattern of variability is
            identifiable (
            <FigureInnerLinkComponent
              text="Figure 2A"
              anchor="Figure-2"
            ></FigureInnerLinkComponent>
            ; the blue squares 1 x 1 m represent an individual). If we now cover
            an area of ​​19 x 19 km (there are 19,000/100 squares of 100 x 100 m
            in the figure, i.e. 190 x 190 = 36,100 squares), the distribution of
            the total number of individuals in each square of 100 x 100 m
            remains unintelligible (
            <FigureInnerLinkComponent
              text="Figure 2B"
              anchor="Figure-2"
            ></FigureInnerLinkComponent>
            ). Now imagine that we can examine the distribution of the number of
            individuals of this same species in a large region of 1000 x 1000 km
            (there are in the figure 1000000/100 squares of 100 x 100 m, i.e.
            10000 x 10000 = 100 millions), we now see a pattern of variability
            emerging. The species is present in the centre of the region and the
            density of individuals also increases towards the centre (
            <FigureInnerLinkComponent
              text="Figure 2C"
              anchor="Figure-2"
            ></FigureInnerLinkComponent>
            ). Taking height, that is to say the passage from local to global
            (small to large spatial scale), allows us to precisely identify the
            contours of the spatial distribution of this fictitious species ({" "}
            <FigureInnerLinkComponent
              text="Text Box 1"
              anchor="Text-Box-1"
            ></FigureInnerLinkComponent>
            ). The ecologist, who often studies biological systems at a small
            scale, may conclude that there are no detectable patterns of
            variability and that therefore the distribution of individuals is
            random and does not obey any rules ({" "}
            <FigureInnerLinkComponent
              text="Text Box 1"
              anchor="Text-Box-1"
            ></FigureInnerLinkComponent>
            ). On the other hand, the biogeographer may conclude that there is a
            structure, which implies the existence of underlying regulatory
            mechanisms. The problem arises if researchers from these different
            disciplines extrapolate their results from the small to the large
            scale or vice versa. The ecologist will conclude that there are no
            principles governing the spatial distribution of a species and the
            biogeographer will be able to establish certain predictions which
            will be debatable at smaller scales. We touch here on the burning
            problem of the transfer of scale at the origin of so much
            controversy. This phenomenon is also observed along the time
            dimension. It is therefore essential in the construction of all
            theories to specify its limits according to the spatio- temporal
            scales considered.
          </p>
          <TheoryFigure
            anchor="Figure-2"
            text="Figure 2"
            definition=". Hypothetical distribution of a species from the scale of a region of 100 x 100 m to a scale of 1000 x 1000 km. A. At a local scale (100 x 100 m), the presence of individuals of the same species (blue squares, 1 x 1 m square) seems random. B. At a more regional scale (19 x 19 km), the number of individuals is counted in each 100 x 100 m square. The density of individuals in the target region still seems random although this density is between 2.4 and 3.5 (in decimal logarithmic scale). C. At a large scale (1000 x 1000 km), a pattern of variability is clearly observed and the abundance of the species is greater towards the centre of the geographical domain. The transition from small to large scale is called scale transfer."
            link="../images/partIII/FIGURE 2.png"
          ></TheoryFigure>
          <p className="text-subtitle bold">
            Need for a unifying theory in ecology and biogeography
          </p>
          <p className="text-normal">
            The METAL theory (&quot;MacroEcological Theory on the Arrangement of
            Life&quot;) has recently been proposed to connect together a large
            number of phenomena observed in biogeography (spatial distribution
            of species, communities and biodiversity), ecology (phenology,
            gradual or abrupt changes in communities or biodiversity),
            paleoecology (past distribution of species, communities and
            biodiversity) and bioclimatology (biogeographic and phenological
            changes, temporal changes in abundance and biodiversity at local or
            regional scales) (Beaugrand 2015) . The unification of these
            phenomena is obtained by the use of the concept of ecological niche
            in the sense of Hutchinson (Hutchinson 1957) , which constitutes the
            elementary macroscopic brick of the theory, giving meaning and
            coherence to all the phenomena, patterns of variability or events
            cited above (
            <FigureInnerLinkComponent
              text="Figure 3"
              anchor="Figure-3"
            ></FigureInnerLinkComponent>
            ). The genome controls many processes at infraspecific
            organizational levels (e.g. molecular, physiological and behavioral
            processes) that affect the ecological niche of species, i.e. the
            environmental regime that allows individuals of a species to live
            and reproduce. The use of the niche makes it possible (1) to
            implicitly consider these infraspecific processes without having to
            model them (i.e. without having to put them into an equation) but
            also (2) to integrate the emergence of properties new biology,
            impossible to anticipate by examining the individual properties of
            each part, when crossing an organizational level (here the specific
            level)(
            <FigureInnerLinkComponent
              text="Text Box 1"
              anchor="Text-Box-1"
            ></FigureInnerLinkComponent>
            ).
          </p>
          <TheoryFigure
            anchor="Figure-3"
            text="Figure 3"
            definition=". Concept of the ecological niche, elementary macroscopic brick of the METAL
            theory. The ecological niche of a species is quantified by simultaneously considering all the
            ecological factors that influence its abundance. The concept is therefore multidimensional.
            The ecological optimum represents the values ​​of the ecological parameters for which the
            maximum abundance is observed. Ecological amplitude is the degree of ecological valence
            that a species tolerates. Put simply, it is the width of the ecological niche. The use of the
            ecological niche within the METAL theory makes it possible to integrate molecular,
            physiological, biological and behavioral processes controlled in part by the genome and the
            environment. Such processes are impossible to model for all living species on our planet
            using a reductionist approach. Moreover, the concept of niche makes it possible to consider
            the emergence of new properties at a specific organisational level. The niche-environment
            (including climatic) interaction makes it possible to explain, unify and predict a large number
            of variability patterns observed in ecology, paleoecology, biogeography and climate change
            biology. The niche-environment interaction affects the species genome through processes
            involved in natural selection."
            link="../images/partIII/FIGURE 3.png"
          ></TheoryFigure>
          <p className="text-normal">
            The niche-environment interaction is crucial for explaining,
            unifying and predicting a very large number of phenomena, patterns
            of variability or biological events observed in nature (
            <FigureInnerLinkComponent
              text="Figure 4A"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). At the organizational level of the individual, the
            niche-environment interaction controls a large number of
            physiological and behavioral responses (e.g. phenomenon of
            thermotaxis) of individuals faced with environmental changes such as
            alterations in the thermal regime (
            <FigureInnerLinkComponent
              text="Figure 4B"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). At the organizational level of the population, the niche-
            environment interaction controls its phenology, its long-term
            changes, including the arrival or extirpation of individuals of a
            species (
            <FigureInnerLinkComponent
              text="Figure 4C"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). At the specific organizational level, the niche-environment
            interaction controls the distributional rank of the species and even
            its extinction (
            <FigureInnerLinkComponent
              text="Figure 4D"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). At the organizational level of communities, the niche-environment
            interaction helps to understand how communities are formed and
            modified, thus providing a theoretical basis for synecology and
            phytosociology (
            <FigureInnerLinkComponent
              text="Figure 4E"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). The theory explains the seasonal succession observed in the
            marine planktonic environment, the gradual or abrupt modifications
            of communities, the biogeographical changes of biocoenoses (or
            assemblages), their contractions or expansions, and their eventual
            extinctions (
            <FigureInnerLinkComponent
              text="Figure 4E"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). We can thus explain and anticipate major biological changes but
            also understand how specific biodiversity is organized and can be
            altered in the context of climate change. The niche-environment
            interaction is therefore fundamental. However, human activities now
            influence a large number of processes that can interfere with the
            niche-environment interaction. For example, the extinction of a
            species or its long-term changes can be explained by changes in
            anthropogenic pressures (fishing, agriculture, hunting, pollution),
            which are not mentioned here.
          </p>
          <p className="text-normal">
            In this part, we use the METAL theory to show how the
            niche-environment interaction generates a mathematical constraint on
            the organization of large-scale biodiversity (the latitudinal
            gradients of biodiversity) and we explain why there are more species
            both land and sea. To make progress on these questions, the
            scientific community continues to collect living beings (archaea,
            bacteria, plants, fungi and animals) to inventory them and study
            their biology. Scientists estimate that between 9 (marine species)
            and 14% (terrestrial species) of species have been named and
            described to date. Thus, 1,233,500 species have been inventoried in
            the terrestrial environment and 193,756 in the marine environment
            (bottom and surface). Recent estimates suggest that the number of
            terrestrial and marine species could be 8,740,000 and 2,210,000,
            respectively (Mora et al. 2011) . Ecologists investigate the
            multiple interactions of these species with the environment,
            including the climate, but also with all the species living around
            them, an essential prerequisite for understanding their spatial
            distributions (biogeography), their reproduction rates (phenology)
            and their temporal fluctuations from seasonal fluctuations to
            centenary and millennial changes, and even changes occurring on
            geological time scales (ecology, paleoecology and bioclimatology).
          </p>
          <TheoryFigure
            anchor="Figure-4"
            text="Figure 4"
            definition=". The niche-environment interaction and its influences on the organization of
            biological systems from the individual to the community organisational level and from the
            micro-scale to the mega-scale. A. Niche-environment interaction. Organizational levels of
            individual (B), population (C), species (D) and community (E). Since the organization of
            communities affects the environment of its habitat, the influence of the niche-environment
            interaction on the community is also exerted on ecosystems and ecotones, provinces and
            biomes. In black (bold): phenomena, patterns of variability and biological events. Only the
            main ones are represented here. In blue (bold): Organizational level examined. In red (bold):
            spatial scales concerned."
            link="../images/partIII/FIGURE 4.png"
          ></TheoryFigure>
          <p className="text-subtitle bold">
            Latitudinal biodiversity gradients
          </p>
          <p className="text-normal">
            We start with the question: why do some regions of the globe have
            more species than others? Among other scientific questions about the
            origin of life, the biological basis of consciousness or the
            composition of the universe, this question was cited as one of the
            25 most important questions by the American journal Science in 2005
            (Kennedy and Norman 2005, Pennisi 2005) . It is observed that warm
            regions have a higher number of species than polar regions for most
            taxonomic groups. Biogeographers speak of latitudinal gradients of
            biodiversity to describe the spatial patterns of variability in
            specific biodiversity. The plural is important because the gradient
            may be different from one taxonomic group to another and from one
            domain to another (e.g. terrestrial and marine domain at the bottom
            or at the surface on the continental shelf or in the ocean
            environment). For example, a maximum is obtained at the equator for
            a large number of terrestrial taxonomic groups while it is rather
            subtropical for most oceanic taxonomic groups. Although the
            existence of this pattern of biogeographic variability has been
            known since at least the return of the second expedition of HMS
            Beagle in 1836 thanks to the writings of Charles Darwin, dozens of
            hypotheses have been formulated but no consensus has been reached so
            far.
          </p>
          <TheoryFigure
            anchor="Figure-5"
            text="Figure 5"
            definition=". Idealized relationship between the ecological niche of a marine species and its
            spatial distribution. In this example, the ecological niche is a thermal niche with a Gaussian
            distribution characterized by two parameters: the optimum temperature and the thermal
            amplitude (parameter close to the standard deviation). The optimum temperature (T opt ) is
            15°C for the two fictitious niches (A and C). The thermal amplitude (t s ) is higher for A than C.
            The spatial distribution is wider and the abundance of the species higher when the species
            has a thermal niche with a large thermal amplitude (B versus D). In reality, the niche of a
            species is multidimensional."
            link="../images/partIII/FIGURE 5.png"
          ></TheoryFigure>
          <p className="text-normal">
            Understanding the spatio-temporal organization of biodiversity on a
            large scale requires the development of numerical models where
            biological, environmental and climatic knowledge are put into
            equation. In the context of the application of the METAL theory, the
            fundamental bases of the biodiversity model are simple. A large
            number of fictitious species is generated. Each fictitious species
            (we speak of pseudo-species) has unique physiological preferences,
            which define their ecological niches, that is to say their responses
            to climatic and environmental constraints. We can, initially,
            consider a simple niche, considering only the bioclimatic (or
            variable) dimensions temperature and water availability (here
            precipitation). Temperature is an essential factor controlling the
            physiology of all species living on our planet and precipitation is
            an indication of water availability, a variable just as important as
            temperature for terrestrial species. These bioclimatic dimensions
            are 6 fundamental and many studies have underlined their importance
            (Whittaker 1975, Tittensor et al. 2010, Sunday et al. 2011).{" "}
            <FigureInnerLinkComponent
              text="Figure 5"
              anchor="Figure-5"
            ></FigureInnerLinkComponent>{" "}
            shows an example with two pseudospecies, one being more eurythermal
            (i.e. tolerating a greater range of thermal variation) and the other
            being more stenothermic (i.e. tolerating a smaller range of thermal
            variation). In this example, we see that the stenothermal
            pseudospecies is characterized by a more limited range and lower
            abundance than the more eurythermal pseudospecies.
          </p>
          <TheoryFigure
            anchor="Figure-6"
            text="Figure 6"
            definition=". Different types of spatial distribution of marine species generated from thermal
            niches by varying the thermal optimum and the thermal amplitude. The different colours on
            the map represent different species generated from the same thermal niche. The same
            niche can give rise to several species if and only if individuals from different species cannot
            meet (allopatric speciation). Niches with a low thermal amplitude generate more species
            (e.g. A versus B and E versus F)."
            link="../images/partIII/FIGURE 6.png"
          ></TheoryFigure>
          <p className="text-normal">
            We can thus create a multitude of pseudo-species by varying the
            optimum and the ecological amplitude of each dimension composing the
            niche.{" "}
            <FigureInnerLinkComponent
              text="Figure 6"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>{" "}
            shows for a simple case the creation of marine pseudo-species from a
            single ecological dimension (i.e. a single ecological variable);
            this is a Gaussian thermal niche. Different thermal optima and
            amplitudes are used. We see that thermal niches with lower
            amplitudes give more species but these are characterized by smaller
            distribution areas (
            <FigureInnerLinkComponent
              text="Figure 6"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            , left maps versus right maps). There is therefore a relationship
            between the average abundance of a species and its area of
            ​​distribution, a relationship already demonstrated empirically by
            the American ecologist James Brown (Brown 1984) . We extended this
            relationship by indicating that there is a positive link between the
            ecological amplitude of a species, its average abundance and its
            distribution area (Beaugrand et al. 2014) .
          </p>
          <p className="text-normal">
            To reproduce the arrangement of biodiversity on a large scale, we
            can build a model which first creates millions of niches which then
            allow pseudo-species to establish themselves in a given region so
            long as environmental fluctuations allow it. A niche can therefore
            give rise to several species if the individuals from these different
            species never come into contact, in other words if there is no gene
            flow between populations (
            <FigureInnerLinkComponent
              text="Figure 6F"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ). Pseudo-species are gradually colonizing the terrestrial and
            marine environment (on the surface and at the bottom of the oceans).
            During the simulations, the species gradually organize themselves
            into communities and the biodiversity, more precisely here the
            number of species in a given region, is reproduced (
            <FigureInnerLinkComponent
              text="Figure 7"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ). These numerical experiments (or simulations) correctly
            reconstruct the spatial distribution of biodiversity as it is
            currently observed for a large number of taxonomic groups in the
            terrestrial and marine environment (e.g. crustaceans, fish,
            cetaceans, plants, birds). The biodiversity maps for the ocean
            floors (
            <FigureInnerLinkComponent
              text="Figures 7C"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="Figures 7F"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ) remain provisional as few observations have been made to date to
            confirm these results, however.
          </p>
          <TheoryFigure
            anchor="Figure-7"
            text="Figure 7"
            definition=". Average distribution of biodiversity (i.e. number of species) in terrestrial (A and D)
            and marine (B and E for surface biodiversity and C and F for biodiversity on the ocean floor)
            and seas) reconstituted from a bioclimatic model derived from the METAL theory. D-F: the
            curves show the latitudinal gradient of biodiversity observed for each environment
            considered. E: the blue curve reflects the latitudinal biodiversity of the oceanic regions
            (bathymetry above 200 m) and the green curve reflects the latitudinal biodiversity of the
            continental shelf regions (bathymetry below 200 m). F: the curve in green reflects the
            latitudinal biodiversity of the continental shelf (bathymetry lower than 200 m), the curve in
            blue reflects that of the deep regions (bathymetry higher than 2000 m) and that in magenta
            reflects the latitudinal biodiversity of the continental slope (bathymetry between 200 and
            2000 m)."
            link="../images/partIII/FIGURE 7.png"
          ></TheoryFigure>
          <p className="text-subtitle bold">The great chessboard of life</p>
          <p className="text-normal">
            The reconstruction of the latitudinal and spatial distributions of
            biodiversity observed in nature occurs because the niche-climate
            interaction generates a mathematical constraint on the number of
            species that can establish themselves in a given region. We have
            named this constraint the great chessboard of life (
            <FigureInnerLinkComponent
              text="Figure 8"
              anchor="Figure-8"
            ></FigureInnerLinkComponent>
            ). Although there remains a great deal of chance on the type and
            number of species that can establish themselves in a region linked
            to the processes of origination and diversification of a clade, this
            number cannot exceed a threshold set by the niche-climate
            interaction. For example, few species can establish themselves at
            the minimum and maximum limits of temperature and precipitation. The
            choice of minimum and maximum values ​​in the model is therefore
            important because it can have a significant influence on the
            results. The poles corresponding to the minimum limits of 7 the
            values ​​of temperature (for the terrestrial and marine environment)
            and precipitation (for the terrestrial environment), their numbers
            of species that can establish themselves are fundamentally limited
            since two species having the same niche cannot coexist at the same
            place and at the same time according to Gause&#39;s principle of
            competitive exclusion (Gause 1934). Since the upper limits are not
            observed at the level of the equator in the current climate,
            biodiversity is maximum at the level of the equator in the
            terrestrial environment and at the level of the subtropical regions
            in the marine environment. The rate of diversification remains an
            important parameter because it determines the degree of occupation
            of niches in a given geographical cell and the life history traits
            of each group make the chessboard of living organisms specific to a
            given taxonomic group, which sometimes explains the lack of
            universality of biodiversity patterns at large spatial scales (
            <FigureInnerLinkComponent
              text="Figure 8"
              anchor="Figure-8"
            ></FigureInnerLinkComponent>
            ).
          </p>
          <TheoryFigure
            anchor="Figure-8"
            text="Figure 8"
            definition=". The great chessboard of life. This particular chessboard has a number of geographic
            squares that correspond to different sea and land regions. Each square of the chessboard is
            composed of sub-squares which represent the number of climatic niches, which fixes the
            number of species that can establish themselves regionally in a geographical square. Only
            one species can establish itself in a sub-square of the chessboard, and therefore occupy a
            climatic niche, according to the competitive exclusion principle of Gause. The different
            pieces of the chessboard (e.g. king, queen, pawn) symbolize the different biological
            properties of the species (i.e. their differences in terms of strategy, e.g. locomotion and
            reproduction). L represents the possible number of niches (or maximum number of species)
            and S the number of species that occupy the geographical square. Q represents niche
            saturation, with Q=(S/L) x 100. 100% saturation means that all niches are occupied.
            Biological (degree of clade origination) and climatic (repeated Pleistocene glaciations) causes
            influence the percentage of saturation of the niches in each geographical square so that
            there remains a degree of valence on the number of species present on the chessboard of
            life."
            link="../images/partIII/FIGURE 8.png"
          ></TheoryFigure>
          <p className="text-subtitle bold">
            Differences in latitudinal biodiversity gradients between the
            terrestrial and the marine domains
          </p>
          <p className="text-normal">
            A biodiversity peak is observed at the equator in the terrestrial
            environment and around the subtropical regions in the marine
            environment (
            <FigureInnerLinkComponent
              text="Figure 7A-D"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ). This distinction is related to the differential influence of
            atmospheric pressure fields in the marine and terrestrial
            environment. Indeed, the high pressure centres (the large pressure
            high linked to the descending branches of the Hadley and Ferrel
            cells) provide climatic stability and heat, which increases surface
            biodiversity in the ocean environment (
            <FigureInnerLinkComponent
              text="Figure 9"
              anchor="Figure-9"
            ></FigureInnerLinkComponent>
            ). However, above the continents, the high pressure centres strongly
            limit precipitation and biodiversity is therefore very low due to
            the lack of water availability. The maximum values ​​of biodiversity
            are reached in regions where precipitation is regular (towards the
            equator) and decreases when moving away from the influence of the
            intertropical convergence zone, i.e. when this it is only present a
            few months a year (monsoon areas). Finally, the cold ocean floors do
            not show a typical gradient but a very homogeneous biodiversity
            except in high latitude regions where biodiversity decreases
            slightly and along seamounts where it is much higher (
            <FigureInnerLinkComponent
              text="Figure 7E-F"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ).
          </p>
          <TheoryFigure
            anchor="Figure-9"
            text="Figure 9"
            definition=". Average positions of the planet&#39;s major pressure Highs and its influence on average
            precipitation in January (A and B) and July (C and D). Atmospheric pressure (in hPa) (A and
            C). Precipitation (in mm) (B and D)."
            link="../images/partIII/FIGURE 9.png"
          ></TheoryFigure>
          <p className="text-normal">
            The chessboard of life is reorganized according to the climate but
            also according to environmental changes, which makes it dynamic from
            small to large temporal scales (i.e. geological scales). Indeed,
            latitudinal gradients are not stable over time and the METAL theory
            suggests that they were sometimes very different from those
            currently observed. For example, during a cold period at the end of
            the Ordovician (510 million years ago), a very significant contrast
            probably existed between tropical biodiversity and the biodiversity
            of high latitude regions (
            <FigureInnerLinkComponent
              text="Figure 10A"
              anchor="Figure-10"
            ></FigureInnerLinkComponent>
            ). Conversely, during the warm period of Stage 4 of the Cambrian
            (510 million years), the latitudinal gradient of biodiversity was
            probably reversed (
            <FigureInnerLinkComponent
              text="Figure 10B"
              anchor="Figure-10"
            ></FigureInnerLinkComponent>
            ). The current latitudinal gradient of biodiversity, characterized
            by a more or less regular increase in biodiversity from the poles to
            the equator, has therefore probably not always been observed since
            the appearance of eukaryotes on our planet (Zacaï et al. 2021) . In
            the context of current climate change, the use of the METAL theory
            suggests that the contrast between regions of low and high
            biodiversity should be reduced (Beaugrand et al. 2015) .
          </p>
          <TheoryFigure
            anchor="Figure-10"
            text="Figure 10"
            definition=". Marine latitudinal biodiversity gradients using the METAL theory for (A) a period
            of glaciation located at the end of the Ordovician (445 million years) and (B) a warm phase
            corresponding to a period of the Cambrian (Level 4, 510 million years). The position of the
            continents is indicated in white. Species richness is in relative unit with high richness values
            ​​in red and low ​​in blue. The conquest of the continents by the first terrestrial plants probably
            began around 500 million years ago. The carbon dioxide concentration was 5 times the pre-
            industrial concentration for the late Ordovician and 32 times the pre-industrial
            concentration for the Cambrian Stage 4. Modified from Zacaï and colleagues (Zacaï et al.
            2021)."
            link="../images/partIII/FIGURE 10.png"
          ></TheoryFigure>
          <p className="text-subtitle bold">
            Why terrestrial species are higher than marine species ?
          </p>
          <p className="text-normal">
            Why is the number of terrestrial species more important than the
            number of marine species? This conundrum is all the more
            incomprehensible since marine biodiversity appeared long before
            terrestrial species. Since more time has passed since the emergence
            of life in the oceans, the highest number of terrestrial species
            over marine species seems to be a seemingly counterintuitive
            observation. The answer to this conundrum may be relatively simple
            and two mechanisms may play an important role (Beaugrand et al.
            2020) . First, the difference between the number of bioclimatic
            dimensions between terrestrial and marine environments is
            fundamental. Water is by definition present everywhere in the ocean,
            which is not the case for the terrestrial environment. The
            additional discriminating bioclimatic dimension in the terrestrial
            environment (water availability) arithmetically increases the number
            of bioclimatic niches and thus the number of species that can
            establish themselves in the terrestrial environment (Beaugrand et
            al. 2020) . Secondly, but to a lesser extent, the addition of an
            additional bioclimatic dimension, combined with more pronounced
            geographical variations in the terrestrial environment, further
            fragment the range of species, increasing the possibility of
            allopatric speciation, i.e. that is, the creation of species by
            prolonged or permanent geographic isolation of populations.
          </p>
          <p className="text-subtitle bold">Conclusion</p>
          <p className="text-normal">
            A central objective of Biology and its sub-disciplines
            (biogeography, ecology) is to reveal the laws or general principles
            which govern the organization of life, but the sources of variations
            and exceptions seem inexhaustible. Yet simple laws have been
            discovered in other areas of science such as physics. Galileo wrote
            &quot;Philosophy [nature] is written in that great book which ever
            is before our eyes -- I mean the universe -- but we cannot
            understand it if we do not first learn the language and grasp the
            symbols in which it is written. The book is written in mathematical
            language, and the symbols are triangles, circles and other
            geometrical figures, without whose help it is impossible to
            comprehend a single word of it; without which one wanders in vain
            through a dark labyrinth.” We show here that the niche-environment
            interaction is a fundamental interaction that controls a large
            number of phenomena, patterns of variability and biological events.
            And like the Italian scholar of the Renaissance, we propose that the
            great book of life is also written in mathematical language. In
            particular, the niche-environment interaction, controlled in part by
            the climatic regime, generates a mathematical constraint on the
            arrangement of large-scale biodiversity that we have named the grand
            chessboard of life (
            <FigureInnerLinkComponent
              text="Figure 8"
              anchor="Figure-8"
            ></FigureInnerLinkComponent>
            ). The mathematical effect is probably considerable such that an
            inverted latitudinal gradient is impossible under present climatic
            conditions for most taxonomic groups that presently exhibit
            increasing biodiversity from the poles to the equator. The
            establishment of a global theory of biodiversity, however, requires
            taking into account a large number of biological processes that also
            influence biodiversity (e.g. diversification rate and origination of
            a clade) and Theodosius Dobzhansky was greatly inspired when he
            wrote his article “Nothing in biology makes sense except in the
            light of evolution” (Dobzhansky 1973). But in the process of
            developing this global theory of biodiversity considering all the
            complexity of biological systems (
            <FigureInnerLinkComponent
              text="Text Box 1"
              anchor="Text-Box-1"
            ></FigureInnerLinkComponent>
            ), it is also important to recognize that the mathematical
            constraints caused (1) by the number of key 9 dimensions that the
            niches include in the terrestrial and marine realms and (2) through
            niche-environment interaction also control the organization of life.
          </p>
          <p className="text-subtitle bold">Litterature cited:</p>
          <PartThreeReferences></PartThreeReferences>
          {/* ------------------------------------------------------------------------------------------------------ */}
        </Col>
        <Col>
        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/rightTop.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
            <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/rightBottom.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
        </Col>
      </Row>
    </div>
  );
};

export default PartThreePage;
