import Image from "react-bootstrap/Image";
const ClickableImage = (props) => {
  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      <Image
        alt={props.alt}
        src={props.link}
        className={props.className}
        fluid
      />
    </a>
  );
};

export default ClickableImage;
