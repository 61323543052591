import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import "../../css/publicationPage.css";

/**
 * Define the template for a PublicOutreach.pdf used in the public outreach magazine section
 * @param {*} publicationData 
 * @returns 
 */
const ClickableRessource = (publicationData) => {
  return (
    <div className="publication">
        <Card className="publication-card">
        <a
        href={publicationData.link}
        target="_blank"
        rel="noreferrer"
        className="publication-hyperlink"
      >
          <Card.Body>
              <div className="column c-20">
                {publicationData.preview ? (
                  <Image src={publicationData.preview} width={100} height={"auto"} />
                ) : (
                  //<Image src="./images/publicationIcon.jpg" width={100} />
                  <p className="text-normal bold">{publicationData.review}</p>
                )}
              </div>
              <div className="column c-80">
                <Card.Title style={{ color: "black" }}>
                  {publicationData.title}
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {publicationData.review}
                </Card.Subtitle>
              </div>
          </Card.Body>
      </a>
        </Card>
    </div>
  );
};

export default ClickableRessource;
