/* React bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
/* React router components, to organize*/
import { Link, Route, BrowserRouter, Routes,Navigate } from "react-router-dom";
/* Useful and global components*/ 
import ScrollToTop from "./utilComponents/ScrollToTop";
import CopyrightImage from "./utilComponents/CopyrightImageComponent";
import "../App.css";
/*Routes, components of the main pages of each section*/
import Home from "./homePage/Home";
import ContactComponent from "./contactPage/ContactComponent";
import TeamComponent from "./teamPage/TeamComponent";
import NewsComponent from "./newsPage/NewsComponent";
import ScriptComponent from "./ScriptComponent";
import PublicationComponent from "./publicationPage/PublicationPage";
import AnimationComponent from "./animationPage/AnimationPage";
/* Outreach*/
import MagazineComponent from "./publicOutreachPages/MagazineComponent";
import PressReleaseComponent from "./publicOutreachPages/PressReleaseComponent";
import RadioReleaseComponent from "./publicOutreachPages/RadioReleaseComponent";
import VideoReleaseComponent from "./publicOutreachPages/VideoReleaseComponent";
import BookComponent from "./bookPage/BookComponent";
/* The theory main page and sub-pages/ 1,2,3,4*/
import TheoryPageComponent from "./theoryPage/TheoryPageComponent";
import PartOnePage from "./theoryPage/Part1Page/PartOnePage";
import PartTwoPage from "./theoryPage/Part2Page/PartTwoPage";
import PartThreePage from "./theoryPage/Part3Page/PartThreePage";
import PartFourPage from "./theoryPage/Part4Page/PartFourPage";
import PPBeaugrand from "./teamPage/PPBeaugrandComponent";

/**
 * All the component should stay in <BrowserRouter> bracket,
 * This component is special, react-router is divided in 2 sections
 * The first one is from the beginning to the "routes" brackets containing
 * the real "frontend" view, what the user can see
 * The second one is contained  between <Routes> brackets,
 * it describes all possible routes that can be reach from the main URI of the website
 */
const NavComponent = ({ children }) => {
  return (
    <BrowserRouter>
    <ScrollToTop/> {/** Make user scrollbar back to the top of the page when entering a new route */}
      <Container fluid>
        <Row>
          <Navbar bg="light" expand="lg" sticky="top" fixed="top">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="my-nav">
                <Nav.Link as={Link} to="/">
                  Home
                </Nav.Link>
                <Nav.Link as={Link} to="/news">
                  News
                </Nav.Link>
                <Nav.Link as={Link} to="/theory">
                  The METAL Theory
                </Nav.Link>

                <NavDropdown title="Publication" id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="/publication">
                    Publication
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/book">
                    Book
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/animation">
                  Animation
                </Nav.Link>
                <NavDropdown
                  title="Public Outreach"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/magazine">
                    Magazine
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/press">
                    Press
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/tv">
                    TV
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to="/radio">
                    Radio
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/script">
                  Script
                </Nav.Link>
                <Nav.Link as={Link} to="/team">
                  Team
                </Nav.Link>
               
                <Nav.Link as={Link} to="/contact">
                  Contact
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Container>
      <Routes>
        <Route path="theory/partI" element={<PartOnePage />} />
        <Route path="theory/partII" element={<PartTwoPage />} />
        <Route path="theory/partIII" element={<PartThreePage />} />
        <Route path="theory/partIV" element={<PartFourPage />} />
        <Route path="theory" element={<TheoryPageComponent />} />
        <Route
          path="publication"
          element={<PublicationComponent></PublicationComponent>}
        />
        <Route
          path="press"
          element={<PressReleaseComponent></PressReleaseComponent>}
        />
        <Route
          path="tv"
          element={<VideoReleaseComponent></VideoReleaseComponent>}
        />
        <Route
          path="radio"
          element={<RadioReleaseComponent></RadioReleaseComponent>}
        />
        <Route path="book" element={<BookComponent />} />
        <Route path="animation" element={<AnimationComponent />} />
        <Route
          path="magazine"
          element={<MagazineComponent></MagazineComponent>}
        />
        <Route path="script" element={<ScriptComponent />} />
        <Route path="Contact" element={<ContactComponent></ContactComponent>} />
        <Route path="team" element={<TeamComponent></TeamComponent>} />
        <Route path="news" element={<NewsComponent />} />
        <Route path="beaugrand-publication" element={<PPBeaugrand></PPBeaugrand>}/>
        <Route path="ressource" element={<CopyrightImage></CopyrightImage>} />
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to="/home" replace />}
    />
      </Routes>
    </BrowserRouter>
  );
};

export default NavComponent;
