import { Container } from "react-bootstrap";
import "../../css/responsiveText.css";
import "../../App.css";
import Chapter1 from "./Chapter1";
import Chapter2 from "./Chapter2";
import Chapter3 from "./Chapter3";

/**
 * 
 * @returns The frontend view of the Animation page, calling several sub-components -> "chapters" to organize the page in section
 *
 */
const AnimationPage = () => {
  return (
    <div>
      <Container>
        <div className="top-span"></div>
        <p className="text-subtitle left">
        In this section, we propose a few animations that complete the book entitled 'Principles of biodiversity organisation and climate change biology'.
        </p>
        <Chapter1></Chapter1>
        <hr className="thick-hr"></hr>
        <Chapter2></Chapter2>
        <hr className="thick-hr"></hr>
        <Chapter3></Chapter3>
      </Container>
    </div>
  );
};

export default AnimationPage;
