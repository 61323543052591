import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
/**
 * Short component used in App.js, that is always shown no matter which route is taken
 * @returns The streamer always shown on the top of the page, containing logos of the website
 */
const MainStreamer = () => {
  return (
    <Container className="nav-streamers" fluid>
      <Row className="nav-streamers-row">
        <Col className="nav-streamers-col"           
          xxl="3"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="3"
          xxs="3">
          <Image
            src="/images/METALAcronymeV2.png"
            alt="The METAL Theory"
            className="nav-streamers-img"
          />
        </Col>
        <Col
          className="nav-streamers-main-col"
          xxl="6"
          xl="6"
          lg="6"
          md="6"
          sm="6"
          xs="6"
          xxs="6"
        >
          <Image src="/images/title-logo.png"
                  className="nav-streamers-main-img"></Image>
        </Col>
        <Col className="nav-streamers-col"           
          xxl="3"
          xl="3"
          lg="3"
          md="3"
          sm="3"
          xs="3"
          xxs="3">
          <Image
            src="/images/METALAcronymeV2.png"
            alt="The METAL Theory"
            className="nav-streamers-img"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MainStreamer;
