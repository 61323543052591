import ClickableImage from "../../utilComponents/ClickableImage";
import "../../../css/theoryPage.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tex2SVG from "react-hook-mathjax";
import GlossaryDefinition from "../../utilComponents/GlossaryDefinition";
import PartFourReferences from "./PartFourReferences";
import FigureInnerLinkComponent from "../../utilComponents/FigureInnerLinkComponent";
import ClickableCopyrightImage from '../../utilComponents/ClickableCopyrightImage'
const PartFourPage = () => {
  return (
    <div className="theoryPage">
      <p className="text-big-title centered bold">
        {" "}
        IV. Climate change biology
      </p>
      <hr className="thick-hr"></hr>
      <Row>
        <Col>
        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/leftTop.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
            <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/leftBottom.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
                        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/petrelFulmar.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Christophe Luczak"
            ></ClickableCopyrightImage>
        </Col>
        <Col xxl={6} xl={6} lg={6} md={6} sm={10} xs={10} xxs={10}>
          <div className="top-span"></div>
          <p className="text-normal">
            Ecosystems and their biodiversity are strongly affected by climatic
            variability and climate change, be anthropogenic or natural. In
            order to appreciate how climatic variability and climate change
            affects biodiversity we need a clear idea on the processes that
            shape living systems, which means that climate change biology should
            be deeply rooted in biogeography and macroecology. It was for this
            purpose that we developed the MacroEcological Theory on the
            Arrangement of Life (METAL) to explain how marine species,
            communities, ecosystems and the emergent biodiversity are organized
            in space and time and how they are reconfigured by changing
            environmental conditions (Beaugrand 2015). The METAL theory
            therefore provides a unifying framework to understand how climate
            change can alter life from low to high organizational levels
            (Beaugrand et al. 2014; Beaugrand et al. 2015). In this section, we
            show that the theory helps to understand many of the biological
            changes that have already been ascribed to climate and show that
            they are connected through the interaction between the species
            ecological{" "}
            <GlossaryDefinition
              word="niche"
              definition="Every species has a unique niche, which reflects the sum of all environmental factors
              that enable it to complete its life cycle and reproduce successfully (Hutchinson, 1957;
              Beaugrand, 2015)."
            ></GlossaryDefinition>{" "}
            and the environmental regime.
          </p>
          <p className="text-subtitle bold">
            METAL unifies most responses of species and communities to climate
            variability and global climate change.
          </p>
          <p className="text-normal">
            METAL enables us to propose a hierarchical sequence of response of
            species to climate change that involves, in the following order,
            behaviour, physiology,{" "}
            <GlossaryDefinition
              word="phenology"
              definition="The phenology is the study of recurring biological events in plants and animals
              (Visser &amp; Both, 2005) . Phenology can be measured in several ways according to the system
              under investigation. For example, in the terrestrial realm, phenology may represent plant
              flowering, breeding or migration of bird while in the marine environment phenology is
              investigated through the peak of annual abundance of plankton, timing of reproduction in
              corals or fish migration (Edwards &amp; Richardson, 2004; Jaffré et al., 2013; Thackeray et al.,
              2016)."
            ></GlossaryDefinition>{" "}
            (seasonal dilatation or contraction), annual abundance, biogeography
            (biogeographical shifts, range expansion or contraction),{" "}
            <GlossaryDefinition
              word="extinction"
              definition="the full disappearance of a species."
            ></GlossaryDefinition>{" "}
            and adaptation (
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>
            ).
          </p>
          {/************************************************************************************* */}
          <div className="top-span"></div>

          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-1">
            <ClickableImage
              className="theoryPage-IV-img"
              link={
                process.env.PUBLIC_URL +
                "/images/theoryRessources/Part_IV_Figure1.jpg"
              }
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span bold">Figure 1</span>: Theoretical
            diagram summarizing the different types of responses explained by
            the METAL theory from the species to the community/ecosystem levels.
            The METAL theory connects and explains climate-induced behavioral,
            physiological, phenological shifts as well as changes in annual
            abundance, local{" "}
            <GlossaryDefinition
              word="extirpation"
              definition="the local disappearance of a species."
            ></GlossaryDefinition>{" "}
            and appearance, biogeographical shifts, range expansion/contraction
            and extinction. Note that norm of reaction is included in the
            ecological amplitude of the niche while adaptation modifies the
            shape of the niche. From Beaugrand & Kirby (2018).{" "}
          </p>

          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            Behavioral adjustment (also termed behavioral plasticity) enables a
            species to exploit the{" "}
            <GlossaryDefinition
              word="habitat"
              definition="the place where a species lives."
            ></GlossaryDefinition>{" "}
            micro-heterogeneity to remain in environmental conditions that are
            close to their ecological optimum, e.g. thermotaxis. Physiological
            adjustment enables an organism to acclimate to environmental
            conditions that depart from the ecological optimum of the species.
            When this type of adjustment becomes difficult, normal life is
            compromised and local abundance diminishes. When climate cannot be
            overcome by behavioral and physiological adjustment (acclimation) it
            affects the seasonal distribution of the species locally.
            Phenological adjustment (also called phenological plasticity) is
            therefore the response of a species to changing local environmental
            conditions to remain in an{" "}
            <GlossaryDefinition
              word="environment"
              definition="The sum of all conditions, including allogenic and autogenic factors, affecting
              or not a living organism."
            ></GlossaryDefinition>{" "}
            that enables optimal growth and reproduction, to maintain annual
            abundance at the highest possible level. When phenological
            adjustment becomes difficult, annual abundance diminishes leading
            eventually to the local extirpation of a species. Annual abundance
            changes due to the positive and negative effects of local climate
            can alter a species’ spatial distribution. The sum of all local
            effects on annual abundance that lead eventually to local
            extirpation or appearance can be perceived at a macro-ecological
            scale as a biogeographical shift. Extinction occurs when range
            contraction is complete. Finally, adaptation, which can enable a
            species to avoid global extinction, is a modification in the
            genotype that enables an alteration of the biology of a species that
            modifies its ecological niche. (Note that we consider (i) that the
            niche is controlled by the genotype and that any alteration of the
            niche of a species results from a modification in its genotype and
            that (ii) the{" "}
            <GlossaryDefinition
              word="norm of reaction"
              definition="the phenotypic plasticity of a given genotype across a large range of
              environmental conditions (Shama et al., 2011) ."
            ></GlossaryDefinition>{" "}
            is included within the ecological niche). Adaption to new
            environmental conditions, which might be impossible if the magnitude
            and speed of climate change is too great, is briefly discussed in
            our review because its effect is to alter the shape of the species’
            ecological niche and therefore the interaction between the organism
            and its environment.{" "}
          </p>

          <p className="text-normal">
            When METAL is applied at the community and ecosystem levels the
            local species’ individual response to climate alters the pelagic
            community in a predictive way, resulting from the propagation of the
            interaction between the species’ ecological niche and the ecological
            conditions at higher organizational levels. Here, the response
            includes seasonal succession, gradual and abrupt community shifts,
            aerography, biodiversity, ecosystems and biomes. Seasonal succession
            can sometimes trigger trophic mismatch, but provided no other
            important ecological dimensions or habitat requirements differ among
            species METAL predicts that community reassembly should be limited,
            however. Gradual community shifts have been frequently reported,
            sometimes associated with Abrupt Community Shifts (ACSs) and arise
            from the individual species’ response to climate that reconfigures
            communities in a given region. Biogeographical shifts of species’
            assemblages suggest that community reconfiguration is limited.
            Biodiversity is affected by the reconfiguration of communities
            triggered by phenological and biogeographical shifts of species.
            Ecosystem level changes affecting ecosystem functioning and
            associated goods and services are altered by biodiversity
            rearrangement. Finally, the spatial movements of provinces or biomes
            are the consequence of regional biodiversity rearrangements.
          </p>

          <p className="text-title bold">
            A. Responses to climate at a species level
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            Each species on Earth has its own unique ecological niche (sensu
            Hutchinson) (Hutchinson, 1957), which reflects the sum of all
            environmental factors that enable it to complete its life cycle and
            reproduce successfully and we propose that most responses of species
            to climate are linked together through the niche-environment
            interaction. We assume that the ecological niche is under the
            control of genes that determine a species’ biology and therefore its
            response to environmental change (
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>
            ). A species is capable of phenotypic plasticity (acclimation) (i)
            to extend its distributional range (i.e. their southern and northern
            boundaries) or (ii) to achieve seasonal acclimatization. Phenotypic
            plasticity can be accomplished in many ways for example by a change
            in enzyme concentration or activity, homeoviscous adaptation or the
            synthesis of new proteins or cryoprotective molecules. Phenotypic
            plasticity usually has limits however, which explains why there are
            only a few pandemic species, or why a subarctic species cannot
            become a temperate species on a short temporal scale. The species’
            niche integrates its norm of reaction (Shama et al., 2011) when its
            entire spatial distribution throughout the year is considered. The
            METAL theory shows that the ecological niche is a fundamental
            macroscopic property of a species, which elucidates the response of
            living systems to climatic variability and climate change from the
            species to the community and higher organizational levels
            (Beaugrand, 2015). Below, we review the different types of expected
            responses from local to global adjustments and integrate them into a
            unifying framework.
          </p>

          <p className="text-subtitle bold centered"> A.1. Local adjustments</p>
          <div className="top-span"></div>
          <p className="text-subtitle bold">A.1.1. Behavioral adjustments</p>
          <p className="text-normal">
            Behavioral adjustment is probably used by many organisms to
            attenuate the effects of environmental variability on physiological
            performance (Zmiri et al., 1974; Huey et al., 2003; Sunday et al.,
            2014). In particular, ectotherms exploit habitat heterogeneity by
            adjusting their time (e.g. day/night) or place of activity to
            maximize biological efficiency and save the cost of physiological
            adjustment, or to cope temporarily with extreme (damaging or lethal)
            hydro-meteorological events (Perry et al., 2005; Sims et al., 2006).
            For marine species, behavioural adjustment is particularly relevant
            in places where the water column is heterogeneous either
            horizontally or vertically. Living close to a thermocline or a
            horizontal frontal structure may enable a species to exploit the
            heterogeneity of their thermal environment, depending upon species
            life history traits that control dispersal, foraging behaviour (e.g.
            tuna, dogfish) or migration (e.g. herring, tuna). Behavioral
            adjustment to temperature must take place with respect to other
            environmental parameters, however, and it is likely that trade-offs
            exist. For example, vertical migration to escape temperatures that
            are too warm is possible providing that other environmental
            variables remain suitable.
          </p>
          <p className="text-normal">
            Distinct vertical distribution of two copepods Calanus finmarchicus
            (subarctic species) and C. helgolandicus (temperate, pseudo-oceanic
            species) have been reported in Dogger Bank (North Sea) (Jónasdóttir
            & Koski, 2011). The temperate species was observed at the surface
            whereas the cold-water species occurred at depths ranging from 30m
            to 50m (
            <FigureInnerLinkComponent
              text="Figure 2"
              anchor="Figure-2"
            ></FigureInnerLinkComponent>
            ). Behavioural adjustment therefore enables species to place
            themselves in a habitat with environmental conditions close to the
            optimal ecological niche. The phytoplankton food of both copepod
            species occurs near the surface however, which will ultimately
            constrain the vertical distribution of these two species and so
            their local response to changing temperature. Other examples include
            marine organisms that migrate offshore to escape cold inshore winter
            temperatures or over larger distances such as seabird migrations
            (McQuinn, 1997; Guilford et al., 2009).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-2">
            <ClickableImage
              className="theoryPage-IV-img2"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 2.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 2</span>: Vertical and
            diurnal changes in the abundance (numbers per m3) of female Calanus
            helgolandicus (dark grey bars) and C. finmarchicus (white bars) at
            day (white) and night (light grey) at two stations close to Dogger
            Bank in 2005. a. Station 5 (56°N, 4°E). b. Station 3 (56.3°N,
            3.7°E). Redrawn, from Jónasdóttir and Koski (Jónasdóttir & Koski,
            2011).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-subtitle bold">A.1.2. Physiological adjustments</p>
          <p className="text-normal">
            The normal activity of an individual species takes place within a
            range of thermal conditions, varying from one species to another (
            <FigureInnerLinkComponent
              text="Figure 3"
              anchor="Figure-3"
            ></FigureInnerLinkComponent>
            ).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-3">
            <ClickableImage
              className="theoryPage-IV-img2"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 3.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 3</span>: Physiological
            effects of temperature on an ectotherm. Tp: pejus thermal threshold
            from which aerobic metabolism reduces; Tc: critical thermal
            threshold from which activity ceases; Td: denaturation thermal
            threshold beyond which denaturation initiates.
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            When thermal stress moves a species too far away from its thermal
            optimum and behavioural plasticity cannot compensate, physiological
            adjustment occurs. At the individual level, thermal stress may be
            acute due to an extreme hydro-meteorological event e.g. El Niño
            event, or it may be chronic due to gradual persistent climate
            change. Using the terminology defined by Shelford (Shelford, 1931)
            to describe the Law of tolerance, Frederich and Pörtner (2000)
            described the changing performance of the spider crab Maja squinado
            by examining how haemolymph oxygen tensions, ventilation and heart
            rate varied from 0°C to 40°C (Frederich & Pörtner, 2000). They
            determined the range of optimum performance by identifying the pejus
            (meaning turning worse) temperature thresholds (Tp) of the animal.
            The pejus temperature Tp is the first threshold beyond which aerobic
            metabolism starts to diminish. Tp is currently thought to occur
            earlier at higher than lower temperatures because adaptation to a
            hyperthermic stress seems more difficult (Frederich & Pörtner,
            2000).
          </p>
          <p className="text-normal">
            Physiological stress leads to immuno-depression, making the species
            less resistant to parasites and pathogens. When the thermal stress
            reaches the critical temperature Tc, anaerobiosis starts and
            activity ceases. If the thermal stress continues, torpor takes over
            the organism, and survival is rapidly threatened. In the field,
            survival is further exacerbated by negative species interactions
            (e.g. parasitism, disease, predation). When temperature attains the
            denaturation temperature threshold Td, metabolic dysfunction has
            cascading effects at the cellular level. The thermal stress disrupts
            coordination between mutually dependent biochemical reactions,
            leading to a loss of physiological integration. Those physiological
            thresholds constrain species distribution and their response to
            climate change (Pörtner, 2001).{" "}
          </p>
          <p className="text-normal">
            In the case of climate change, the chronic effect of a thermal
            stress progressively perturbs an animal’s biology and affects its
            population dynamics. The physiological adjustment of an individual
            species to temperature has an energetic cost that subsequently
            affects other functions such as swimming, foraging activity, growth
            and reproduction. Individuals become less competitive or resistant
            to predation and mortality rapidly rises. When adverse environmental
            conditions become high and frequent, reproduction reduces and the
            growth of the population decreases, reducing local population
            density.{" "}
          </p>
          <p className="text-normal">
            A special case of physiological adjustment is dormancy, which is
            characterized by limited activity, growth and development
            (Baumgartner & Tarrant, 2017). A special type of dormancy in
            copepods is diapause, which enables them to endure temporary
            unfavourable environmental conditions. In pelagic ecosystems,
            diapause mainly takes place prior to, or when environmental
            conditions become unsuitable for a species (Baumgartner & Tarrant,
            2017) and in some copepods, such as Calanus finmarchicus, it is
            facultative and may be modulated by the environment (Aruda et al.,
            2011). Copepods may diapause in two different ways. Some species
            (i.e. Calanidae and Eucalanidae) accumulate large amount of lipids
            and diapause as juvenile or adult in deep waters while some others
            (i.e. Centropagoidea) produce resting eggs that sink on the
            sediments. These ways to diapause induce a dependence regarding the
            substrate-{""}
            <GlossaryDefinition
              word="biotope"
              definition="the term proposed by the British ecologist Arthur Tansley is used to describe the dominant physical and chemical characteristics that are experienced by a biocoenosis. The biocoenosis also influences the biotope. An ecosystem results from the interaction between the biocoenosis and the biotope."
            />{" "}
            component (van der Spoel, 1994); in the first case, the copepod
            needs deep water and in the second the copepod needs sediments in
            shallow (neritic) regions. For example, Calanus finmarchicus cannot
            diapause in the North Sea and their spring abundance results from
            individuals that overwinter in the Norwegian Sea deep Water (Heath
            et al., 1999). Although much remains to be studied, it is possible
            that climate change, by influencing the timing of diapause, may
            influence the number of generations that a species produces each
            year (the degree of voltinism), and thereby its phenology and annual
            abundance. Variations in the degree of voltinism resulting from the
            effect of climate on species physiology may either amplify or dampen
            the influence of climate change on seasonal species distribution and
            annual abundance (Beaugrand et al., 2007; Knell & Thackeray, 2016).{" "}
          </p>

          <p className="text-subtitle bold">A.1.3. Phenological shifts</p>
          <p className="text-normal">
            Phenology is measured here as the seasonal pattern of the abundance
            of a species. Altering the phenology enables a species to occur in
            an environment that has optimal ecological conditions. We therefore
            think that the timing is primarily driven by environmental
            conditions such as temperature, photoperiod and nutrient
            availability and structure of the water column (Visser & Both, 2005;
            Ji et al., 2010; Mackas et al., 2012; Thackeray et al., 2016), and
            only secondarily by prey availability (Visser & Both, 2005; Winder
            et al., 2009).
          </p>
          <p className="text-normal">
            Below, we show how changes in temperature can affect a species’
            phenology (Beaugrand et al., 2014). We first use a simple
            theoretical example using a niche based on a single environmental
            parameter, mean sea surface temperature (SST). The thermal niche is
            calculated as follows (Ter Braak, 1996):{" "}
          </p>
          <p className="text-subtitle centered">
            <Tex2SVG
              display="inline"
              latex="E_{i,j,s} = c_{s}e - \left(\frac{(x_{i,j}-u_s)^2}{2t^2_s}\right)"
            ></Tex2SVG>
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            With <Tex2SVG display="inline" latex="E_{i,j,s}"></Tex2SVG>
            the expected abundance of a pseudo-species{" "}
            <Tex2SVG display="inline" latex="{s}"></Tex2SVG> at location{" "}
            <Tex2SVG display="inline" latex="{i}"></Tex2SVG> and time{" "}
            <Tex2SVG display="inline" latex="{j}"></Tex2SVG>;{" "}
            <Tex2SVG display="inline" latex="{c_s}"></Tex2SVG> the maximum value
            of abundance for species{" "}
            <Tex2SVG display="inline" latex="{s}"></Tex2SVG> (here this
            parameter is fixed to one);{" "}
            <Tex2SVG display="inline" latex="{x_{i,j}}"></Tex2SVG> the value of
            SST at location <Tex2SVG display="inline" latex="{i}"></Tex2SVG> and
            time <Tex2SVG display="inline" latex="{j}"></Tex2SVG>;{" "}
            <Tex2SVG display="inline" latex="{u_s}"></Tex2SVG> the thermal
            optimum and <Tex2SVG display="inline" latex="{t_s}"></Tex2SVG> the
            thermal amplitude for species{" "}
            <Tex2SVG display="inline" latex="{s}"></Tex2SVG>. The thermal
            tolerance is an estimation of the breadth (or thermal amplitude) of
            the species thermal niche (or bioclimatic envelope) (Ter Braak,
            1996).
          </p>

          <p className="text-normal">
            From Equation (1), we created a hypothetical species characterized
            by a Gaussian thermal niche with us = 15°C and ts = 5°C (
            <FigureInnerLinkComponent
              text="Figure 4a"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). The corresponding spatial range of this pseudo-species was
            calculated by projecting its thermal niche onto the spatial domain (
            <FigureInnerLinkComponent
              text="Figure 4b"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ) and gave a maximal abundance in the North Atlantic Drift Province
            (Longhurst, 1998) between the northern part of the Subtropical Gyre
            and the Oceanic Polar Front (Dietrich, 1964).{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-4">
            <ClickableImage
              className="theoryPage-IV-img"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 4.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 4</span>: Relationships
            between species niche, spatial distribution, and regional seasonal
            phenology and long-term changes in abundance. a. Thermal niche of a
            hypothetical cold-water species. b. Spatial distribution of the
            cold-water pseudo-species. The circles on the niche (a) and on the
            species spatial distribution (b) denote the three parts of the niche
            or zones that are considered in other panels: warm side of the niche
            (panels d and e, zone 1), central part of the niche (panels f and g,
            zone 2) and cold side of the niche (panels i and h, zone 3). c.
            Theoretical changes in abundance as a function of latitudes and
            months (i.e. phenological shifts as a function of latitudes). Zone
            1: part of the distribution where the seasonal maximum occurs in
            spring/winter. Zone 2: part of the distribution where the seasonal
            extent is highest. Zone 3: part of the distribution where the
            seasonal maximum is located at the end of summer. Phenology of the
            pseudo-species in a region with a thermal regime corresponding to
            the warm (d), the central part (f) and the cold side (h) of the
            niche (see circles in panels a and b). Changes in abundance are for
            the coldest (blue) and the warmest year (red), based on the
            reference period 1854-2010. Relationships between year-to-year
            changes in abundance of the pseudo-species and annual SST in a
            region with a thermal regime corresponding to the warm (e), the
            central part (g) and the cold side (i) of the niche (see circles in
            panels a and b).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            The calculation of the expected species’ abundance as a function of
            latitude and month allows the distinction of three main phenological
            zones (
            <FigureInnerLinkComponent
              text="Figure 4c"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). First, in the southern part of its range, the pseudo-species has
            a seasonal maximum in winter or spring, the latter time period being
            more likely when parameters such as photosynthetically active
            radiation (PAR) affect the species either directly through its
            influence on photosynthesis (e.g. phytoplankton) or indirectly,
            through the food web (e.g. herbivorous zooplankton). The influence
            of PAR on primary production is prominent towards higher latitudes
            (Behrenfeld, 2010). At its extreme southern range, such a species is
            unlikely to adjust it phenology in response to an increase in sea
            temperature, resulting in a local reduction of its annual abundance
            (i.e. a=0.71 for the coldest year versus b=0.53 for the warmest
            year; period 1854-2010) and a northward biogeographical shift (
            <FigureInnerLinkComponent
              text="Figure 4d"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ) and conversely, if conditions get colder.
          </p>
          <p className="text-normal">
            Two situations may theoretically take place. If winter phenology is
            possible, the species experiences an earlier phenology (
            <FigureInnerLinkComponent
              text="Figure 4d"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). In contrast, species that cannot occur in winter may be unable to
            move towards an earlier phenology at the same rate that they
            disappear at the end of their phenological period; this may occur if
            the photoperiod in high latitudes limits the development of the
            species in winter. Note that in the two cases the species exhibits a
            phenological contraction (
            <FigureInnerLinkComponent
              text="Figure 4d"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). An earlier phenology associated with a phenological contraction
            have been shown for Neocalanus plumchrus in the northeast Pacific
            (Batten & Mackas, 2009). At the southern limit of its range (Zone 1,{" "}
            <FigureInnerLinkComponent
              text="Figure 4c"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ), both the resistance and the resilience of the pseudo-species to
            warming are expected to be small (
            <FigureInnerLinkComponent
              text="Figure 4e"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ), but the opposite is expected in the case of a cooling.{" "}
          </p>
          <p className="text-normal">
            At the centre of its range (Zone 2,{" "}
            <FigureInnerLinkComponent
              text="Figure 4a-c"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ), the pseudo-species exhibits its maximum seasonal extent, the
            duration being modulated by its thermal amplitude ts (Equation 1).
            Here, the species can be observed throughout the year so long as
            other niche dimensions such as PAR, Length Of Day (LOD) or nutrients
            do not exert a controlling influence. A small reduction in the
            abundance of the pseudo-species can be observed in summer if
            temperatures warm (
            <FigureInnerLinkComponent
              text="Figure 4f"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). At an annual scale, and with all else being equal or held
            constant, the erosion of the seasonal period of occurrence in late
            summer should be compensated by higher species’ abundance towards
            spring or early summer and consequently, no substantial alteration
            of the species annual mean should be expected (
            <FigureInnerLinkComponent
              text="Figure 4f-g"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ; average abundance a=0.88 for the coldest year and b=0.92 for the
            warmest year; period 1854-2010); species resistance and resilience
            to warming/cooling are greatest in Zone 2 and so no relationship
            between long-term changes in abundance and annual temperature should
            be seen (
            <FigureInnerLinkComponent
              text="Figure 4g"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). It should be noted that in the case of a phytoplankton species,
            because macronutrients are also important in shaping phenology, an
            annual maximum in summer might be divided into two maxima, one in
            spring and the second later in the year if low nitrate concentration
            (Law of Minimum) or grazing limits phytoplankton growth in summer.
            In the same way, winter phenology may be impossible because of the
            limitation induced by the photoperiod. The calculation of
            phenological index may reveal an earlier phenology but the annual
            abundance may not change substantially.
          </p>
          <p className="text-normal">
            Third, at the northern edge of its distributional range (Zone 3,{" "}
            <FigureInnerLinkComponent
              text="Figure 4a-c"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="Figure 4h-i"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ), the pseudo-species is likely to peak in summer. In this case the
            cold-water species can extend its occurrence in early summer and
            spring if temperatures warm and also in late summer beginning of
            autumn, resulting in an increase of its annual mean abundance
            (average abundance for the coldest year a=0.33 versus the warmest
            year b=0.46; period 1854-2010;{" "}
            <FigureInnerLinkComponent
              text="Figure 4h"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="Figure 4i"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). The pseudo-species experiences a phenological expansion with both
            earlier and later phenology . METAL therefore explains why
            researchers have sometimes reported a later phenology for
            late-summer species, although even for those species an earlier
            phenology is just as likely (Ji et al., 2010). It is possible that
            another environmental factor such as nutrients concentration or food
            availability limits the phenological adjustment towards summer.{" "}
          </p>
          <p className="text-normal">
            If SST warms north of the northern species boundary, a biogeographic
            shift will take place although the species’ presence will first be
            detected in late summer when sea temperatures are highest. This
            example shows that warm-water species arriving in a new area should
            be first detected in late summer or beginning of autumn when local
            temperatures warm. This prediction has been confirmed by the arrival
            of warm-water species such as Centropages typicus, C. violaceus and
            Temora stylifera along European coasts in late summer (Lindley &
            Daykin, 2005). Arrival time is expected to be earlier for eurytherms
            than stenotherms, both having the same degree of thermophily.
            According to METAL the most frequently documented phenological
            adjustment in the context of current climate change should be
            towards an earlier phenology (Menzel et al., 2006; Poloczanska et
            al., 2013; Thackeray et al., 2016). Although some studies have
            estimated an earlier phenology of ~4.4 days per decade for marine
            species (Poloczanska et al., 2013), METAL shows that this pattern
            only represents a part of a more complex temporal adjustment of a
            species to climate involving phenological contraction/expansion and
            increase/decrease in seasonal maximum of abundance.{" "}
          </p>
          <p className="text-normal">
            Sometimes a species does not exhibit a phenological shift or the
            magnitude and the timing of a phenological shift may vary between
            different taxonomic groups and trophic guilds (Visser & Both, 2005;
            Thackeray et al., 2016). The first case is expected under METAL when
            a species lives in zone 2 where changes in phenology are limited,
            the phenomenon being amplified when local variability and/or
            stochasticity related to sampling is/are high (Beaugrand & Kirby,
            2016). The second case is predicted by METAL when the degrees of
            eurythermy/thermophily among species or taxonomic groups differ.
            Spring/summer species are likely to exhibit an earlier phenology but
            also a phenological contraction, whereas late summer species may
            exhibit a phenological expansion associated with both earlier and
            later phenology (<span className="red-text-span">Figure 4</span>).{" "}
          </p>
          <p className="text-normal">
            Latitudinal shifts in phenology are expected from the poleward to
            the equatorward part of the spatial distribution of a species. This
            can be demonstrated when we model the latitudinal shift in the
            phenology of C. finmarchicus using a 4-dimensional niche, that
            includes Photosynthetically Active Radiation (PAR), bathymetry,
            chlorophyll-a concentration and mean SST (
            <span className="red-text-span">Figure 5</span>) (Beaugrand et al.,
            2014).{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-5">
            <ClickableImage
              className="theoryPage-IV-img"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 5.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 5</span>: Predicted and
            observed phenological shifts in a zonal band (10°W-30°W) from 48.5°N
            to 62.5°N. Predicted (a) and observed (b) phenological shifts for
            five latitudinal bands: black (≥60.5°N), blue (57.5-59.5°N), green
            (53.5-56.5°N), yellow (49.5-52.5°N), red (≤48.5°N). Predicted and
            observed center of gravity (c), maximum annual abundance (d),
            seasonal duration (e) and mean annual abundance (f) for every
            latitude between 48.5 and 62.5°N (15 pairs of observation). The
            center of gravity measures the timing of the peak of abundance (i.e.
            timing weighted by the monthly abundance). Seasonal duration was
            assessed by calculating the duration above half of the maximum
            annual abundance.{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            Although both duration and timing of predicted phenology are smaller
            than observed due to population dynamics (
            <FigureInnerLinkComponent
              text="Figure 5a-b"
              anchor="Figure-5"
            ></FigureInnerLinkComponent>
            ), the expected timing (revealed by the center of gravity index) and
            maximum and mean annual abundance and seasonal duration are
            significantly correlated positively and explain 67-96% of the
            variance of the observed parameters (
            <FigureInnerLinkComponent
              text="Figure 5"
              anchor="Figure-5"
            ></FigureInnerLinkComponent>
            ). Our example therefore shows that changes in the seasonal extent
            of a species from Zone 1 to Zone 2 are associated with a shift in
            annual abundance and that a shift towards an earlier phenology is
            limited by PAR and chlorophyll-a concentration (
            <FigureInnerLinkComponent
              text="Figure 5a-b"
              anchor="Figure-5"
            ></FigureInnerLinkComponent>
            ). The net effect is a phenological contraction towards Zone 1. The
            degree of voltinism diminishes when the seasonal extent shortens
            (Knell & Thackeray, 2016), which reduces the resilience of the
            species to warming. Duration is therefore a key phenological
            parameter to monitor, although rarely assessed (Beaugrand et al.,
            2007; Batten & Mackas, 2009).
          </p>
          <p className="text-normal">
            Interestingly, in studies of long-term phenological shifts of the
            North Sea plankton, Edwards & Richardson (Edwards & Richardson,
            2004) found no phenological shift for spring diatoms. They proposed
            that the phenology of these diatoms did not shift because the
            reproduction of the taxonomic group was mainly controlled by the
            photoperiod. According to METAL however, spring North Sea diatoms
            cannot shift their phenology in winter because they are at their
            southern boundary (Zone 1) and so they cannot compensate for an
            increase in sea temperature in winter because reduced winter PAR
            limits their growth. Spring North Sea diatoms should therefore
            decline in the future if the warming continues, exhibiting a pattern
            similar to the one observed for C. finmarchicus (
            <FigureInnerLinkComponent
              text="Figure 5a-b"
              anchor="Figure-5"
            ></FigureInnerLinkComponent>
            ). The METAL theory also predicts that summer diatoms and
            dinoflagellates will exhibit an earlier seasonal occurrence (their
            figure 1, (Edwards & Richardson, 2004)).
          </p>
          <p className="text-normal">
            It has been proposed that latitudinal compensation may take place
            for life history traits such as growth rate (Conover & Schultz,
            1995). This apparent evidence for a counter-gradient variation (i.e.
            an ecogeographical pattern where latitudinal change in a phenotypic
            trait is minimized by the genotype) is explained well by a
            phenological shift that places the individuals of a species in
            months that experience similar environmental conditions. Therefore,
            counter-gradient variation is also explained by METAL well when the
            species’ ecological niche (assumed to be constant at least at
            decadal scales) interacts with the environment to select the
            temporal window that corresponds to the optimum section of the
            ecological niche. As a result, only minor changes in some life
            history traits such as growth rate and body size should be expected,
            without the need to invoke an alteration of the genotype.
            Considering only the thermal niche, growth rate would not change and
            body size would not be affected because variations in body size
            result from the temperature-size rule (Atkinson, 1994). Our
            framework therefore suggests that phenological shifts are not
            exclusively the result of evolutionary adaptation or phenotypic
            plasticity (Przybylo et al., 2000). Rather, our results can simply
            reflect the adjustment of a species to the environmental regime that
            corresponds to their ecological niche (Beaugrand, 2009).{" "}
          </p>

          <p className="text-subtitle bold">
            A.1.4. Alteration in local annual abundance
          </p>
          <p className="text-normal">
            Annual abundance is the outcome of the environmental conditions
            experienced by a species and it represents the integration of
            behavioral, physiological and phenological adjustments (
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>
            ). While McGinty and colleagues (McGinty et al., 2011) noticed the
            long-term relationship between annual abundance and temperature may
            be inconsistent between different locations, it can be described by
            one of three scenarios (
            <FigureInnerLinkComponent
              text="Boxed Text 1"
              anchor="Boxed-Text-1"
            ></FigureInnerLinkComponent>
            ): 1) nil, 2) negative or 3) positive relationship (Beaugrand &
            Kirby, 2016). (Note that for some thermophile species (upper thermal
            limit higher than 30°C; some tropical species), the negative
            relationship may not be currently observable with the
            contemporaneous climate.)
          </p>
          <div className="top-span"></div>
          <div
            id="Boxed-Text-1"
            style={{
              border: "solid white 0.6vw",
              borderLeft: "solid white 0.8vw",
              borderRight: "solid white 0.8vw",
              background: "gray",
              "padding-left": "1vw",
              "padding-right": "1vw",
            }}
          >
            <p className="text-normal">
              <span className="red-text-span">Boxed Text 1</span> | Expected
              responses of species’ annual abundance to temperature changes.
            </p>
            <p className="text-small">
              Using a mixed deterministic/stochastic model, stochasticity
              representing 20% of the amplitude of the deterministic signal,
              Beaugrand &amp; Kirby (Beaugrand &amp; Kirby, 2016) explored the
              different types of relationships expected between the abundance of
              a cold-water species such as C. finmarchicus and temperature at
              four different thermal regimes corresponding to key points along
              the thermal niche (
              <FigureInnerLinkComponent
                text="Figure 6a"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>
              ): (1) The optimal zone (T opt ) between the points T s where both
              reproduction and growth are maximal and where the variability in
              species abundance as a function of temperature is low (
              <FigureInnerLinkComponent
                text="Figures 6"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>
              ); in this zone correlations are not expected with or without
              stochasticity because the thermal regime is unlikely to trigger
              large changes in abundance (Figure 6b). (2) T HV (cold and warm
              edges) the points around which the sensitivity to temperature is
              highest (Beaugrand &amp; Kirby, 2016) (
              <FigureInnerLinkComponent
                text="Figure 6a"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>
              ). In this zone, the deterministic signal related to the
              niche/temperature interaction is strong and only high
              stochasticity can affect the relationship between abundance and
              temperature (
              <FigureInnerLinkComponent
                text="Figure 6c"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>{" "}
              for the cold side and{" "}
              <FigureInnerLinkComponent
                text="Figure 6d"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>{" "}
              for the warm side). Although the effect of stochasticity is
              minimal in this region of the niche, it affects significantly the
              percentage of variance explained. (3) T D (warm edge) which is the
              threshold from where temperature effects are unlikely to be
              detected in the field because the thermal influence on the species
              becomes too small when it is compared to stochasticity (Beaugrand
              &amp; Kirby, 2016) (
              <FigureInnerLinkComponent
                text="Figure 6a"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>{" "}
              and{" "}
              <FigureInnerLinkComponent
                text="Figure 6e"
                anchor="Figure-6"
              ></FigureInnerLinkComponent>
              ).
            </p>
            <hr className="thick-hr"></hr>
            <div style={{ "text-align": "center" }} id="Figure-6">
              <ClickableImage
                className="theoryPage-IV-img"
                link={process.env.PUBLIC_URL + "/images/partIV/Figure 6.jpg"}
              ></ClickableImage>
            </div>
            <p className="text-small">
              <div className="top-span"></div>
              <span className="red-text-span">Figure 6</span>: a. Thermal niche
              (bottom) and the associated theoretical response (top) of a
              hypothetical species to climate-induced changes in temperature.
              The optimal part (T opt ) of the thermal niche corresponds to the
              centre of the species’ distributional range and is associated with
              low year-to-year variability. The bimodal distribution of the
              year-to-year variability exhibits a maximal variability V max
              corresponding to greatest slopes (T HV ; HV for High Variability)
              of the thermal niche. T D is the threshold from where temperature
              effects are unlikely to be detected because the species’ thermal
              sensitivity becomes too small. T L are the temperatures below
              (towards the northern part of the distributional range) and above
              (towards the southern part of the distributional range) which
              temperatures become lethal. The grey areas indicate the region
              where the response of the species to climate-induced temperature
              changes is expected to be strong. b-e. Theoretical long-term
              (1958-2009) changes in the standardised abundance of Calanus
              finmarchicus and relationships with annual SSTs in 4 areas of the
              North Atlantic using a deterministic model (red dot) and a mixed
              deterministic/stochastic model (blue dot) in the West Atlantic
              Basin (b), East (c) and West (d) Labrador Sea and (e) Reykjanes
              Ridge. Correlation coefficients based on a deterministic model
              only (red) and a mixed deterministic/stochastic model (blue). The
              deterministic model was based on 4 environmental parameters
              (temperature, bathymetry, Photosynthetically active radiation and
              chlorophyll-a concentration). See text. From Beaugrand (Beaugrand,
              2012) and Beaugrand and Kirby (Beaugrand &amp; Kirby, 2016).
            </p>
          </div>
          <div className="top-span"></div>
          <p className="text-normal">
            For a temperate boreal species, year-to-year changes in its
            abundance are negatively correlated to changes in temperature
            towards the warmer limit of its spatial distribution (
            <FigureInnerLinkComponent
              text="Figure 4e"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="Figure 6d"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ). Because seasonal extent of the species is restricted to a short
            time period (
            <FigureInnerLinkComponent
              text="Figure 6c"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ) it may have limited generations per year (Zone 1; some species may
            even be univoltine in this region) making it sensitive to
            exceptional meteorological events such as heat waves that may have
            persistent negative effect at the population level (Sunday et al.,
            2014); note that the opposite is true for a cold wave. (This region
            goes from THV to TL in the right side of the niche in{" "}
            <FigureInnerLinkComponent
              text="Figure 6a"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ).{""}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            A species is not influenced by temperature at the centre of its
            range (
            <FigureInnerLinkComponent
              text="Figure 4g"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="Figure 6d"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ) and its phenology is large so long as no other environmental
            factors have an effect (many species are likely to be multivoltine;
            Zone 2 in{" "}
            <FigureInnerLinkComponent
              text="Figure 4c"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>
            ). When the thermal regime is along the part of the niche located
            between the two Ts around Topt the sensitivity of the species to
            climate is lowest and the effects of exceptional meteorological
            events are likely to be insignificant.{" "}
          </p>
          <p className="text-normal">
            At the cold edge of a species range the relationship between
            year-to-year changes in abundance and fluctuations in temperature
            are positive (
            <FigureInnerLinkComponent
              text="Figure 4i"
              anchor="Figure-4"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="Figure 6c"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ). (This region goes from THV to TL in the left side of the niche in{" "}
            <FigureInnerLinkComponent
              text="Figure 6a"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ). Again, the degree of voltinism is lowest and exceptional
            meteorological events may have strong effects on the species’
            abundance. At the cold edge of the spatial distribution of a
            species, strong blooming or collapse can be expected to occur
            relatively rapidly in response to an exceptional meteorological
            event.
          </p>
          <p className="text-normal">
            Where the thermal regime corresponds to the side of the thermal
            niche ranging from TD to TL (
            <FigureInnerLinkComponent
              text="Figure 6a"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ) we can expect to see fluctuations in abundance that appear to be
            independent of temperature because annual fluctuations only have a
            small amplitude in comparison to local stochasticity (
            <FigureInnerLinkComponent
              text="Boxed Text 1"
              anchor="Boxed-Text-1"
            ></FigureInnerLinkComponent>
            ). It is not self-contradictory however, for a strong signal to be
            observed in case of an exceptional event since the intensity of an
            exceptional event may be such that it moves the thermal regime away
            from the insensitive region between TD and TL to THV-TD, which is a
            region very sensitive to change in temperature (
            <FigureInnerLinkComponent
              text="Figure 6a"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>
            ).{" "}
          </p>
          <p className="text-normal">
            Beaugrand & Kirby (Beaugrand & Kirby, 2016) used METAL to test
            whether a part of the long-term changes in the abundance of C.
            finmarchicus could be attributed to a multi-dimensional
            niche/environment interaction by modeling the niche of the calanoid
            with a mixed deterministic/stochastic model (
            <FigureInnerLinkComponent
              text="Figures 6"
              anchor="Figure-6"
            ></FigureInnerLinkComponent>{" "}
            and{" "}
            <FigureInnerLinkComponent
              text="7"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ). The deterministic component of the model was the result of the
            application of the NPPEN (Non-Parametric Probabilistic Ecological
            Niche) model based on 4 environmental parameters: monthly SSTs
            (1958-2009), bathymetry, and climatology of monthly chlorophyll-a
            concentration and PAR. The niche of C. finmarchicus was therefore
            four-dimensional but only annual SSTs varied on a year-to-year
            basis. The stochastic component of the model was represented by a
            random noise that was fixed to 20% of the total amplitude of species
            abundance. METAL predictions were subsequently tested against
            observed data of C. finmarchicus collected from the Continuous
            Plankton Recorder (CPR) survey in three regions of the North
            Atlantic corresponding to the warm edge of the niche since no data
            were available for the cold edge of the niche. Expected and observed
            long-term changes in the abundance of C. finmarchicus were
            significantly correlated (
            <FigureInnerLinkComponent
              text="Figure 7a-c"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ) and while the correlations may seem weak, they are in the range of
            values expected when stochasticity is moderate.{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-7">
            <ClickableImage
              className="theoryPage-IV-img"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 7.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 7</span>: Predicted and
            observed year-to-year changes in the abundance of C. finmarchicus in
            the North Atlantic Ocean using a model based on 4 ecological
            dimensions (temperature, bathymetry, photosynthetically active
            radiation and chlorophyll-a concentration). Observed data originate
            from the Continuous Plankton Recorder (CPR) survey. Long-term
            expected (black) and observed (red) changes in (a) North Sea, (b)
            North-east Atlantic and (c) Subarctic Gyre. d. Relationships between
            theoretical (blue dot) and observed (red dot) long-term (1958-2009)
            changes in the standardised abundance of Calanus finmarchicus as a
            function of annual SSTs. Correlation coefficients and their
            corresponding values of probability are indicated. From Beaugrand &
            Kirby (Beaugrand & Kirby, 2016).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            Stochasticity had a bigger effect at a local scale than at the
            macro-scale of the species spatial distribution. Although locally,
            the correlations were close to 0.5, when the niche was assessed from
            both expected and observed abundance of C. finmarchicus at the scale
            of the North Atlantic, the correlation increases substantially to
            0.7 (
            <FigureInnerLinkComponent
              text="Figure 7d"
              anchor="Figure-7"
            ></FigureInnerLinkComponent>
            ). Schwartzman and Lineweaver (Schwartzman & Lineweaver, 2005) also
            suggested that determinism is likely to diminish at smaller scales
            because the ratio of stochasticity/determinism increases when the
            spatial scale decreases. This may also explain the success of
            macroecology (Brown, 1995; Gaston & Blackburn, 2000).
          </p>

          <p className="text-subtitle bold centered">
            {" "}
            A.2. Global adjustments
          </p>
          <div className="top-span"></div>
          <p className="text-subtitle bold">A.2.1. Biogeographic shifts</p>
          <p className="text-normal">
            Changes in the local annual abundance of a species result from the
            sum of the negative and positive effects of climate on species
            abundance (
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>
            ). When the climatic forcing is too great however, a species may
            spend too much time outside its ecological niche and may diminish or
            even disappear from a region. Local extirpation or appearance is
            perceived at a macroecological scale as a biogeographic shift (
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>
            ). Many authors have suggested that the geographical range of a
            species reflects its ecological niche (Brown, 1984; Lomolino et al.,
            2006), which explains the relative success of ecological niche
            modeling as it allows past, current and future spatial distribution
            of a species to be predicted from environmental conditions (Lenoir
            et al., 2011; Raybaud et al., 2013; Fromentin et al., 2014).
            Biogeographic shifts have been documented in both the terrestrial
            and the marine realms (Thomas & Lennon, 1999; Beaugrand et al.,
            2002; Parmesan & Yohe, 2003; Perry et al., 2005). Hickling and
            colleagues investigated the response of freshwater and terrestrial
            species (e.g. millipedes, spiders, herpiles, birds, mammals) to
            climate change in Britain (Hickling et al., 2006). On the 329
            species they examined, 275 (84%) species exhibited a northward shift
            in agreement with global warming expectation. A meta-analysis
            performed on 129 marine species that exhibited a shift revealed that
            75% of them moved polewards, an observation consistent with climate
            change scenarios (Sorte et al., 2010). In the METAL theory
            (Beaugrand, 2015), the concept of the niche is also central to
            explain species biogeographic shifts.
          </p>
          <p className="text-normal">
            Contemporaneous biogeographical movements are generally regarded as
            latitudinal species shifts in response to warming (Thomas & Lennon,
            1999; Beaugrand et al., 2002; Parmesan & Yohe, 2003; Perry et al.,
            2005; Thomas, 2010). Using an average rate of latitudinal shift for
            a species is however misleading because those shifts should be
            envisioned in a two-dimensional (biogeographic) space and not only
            in a one-dimensional (latitudinal) space.{" "}
            <FigureInnerLinkComponent
              text="Figure 8"
              anchor="Figure-8"
            ></FigureInnerLinkComponent>{" "}
            shows the modeled past (Last Glacial Maximum; LGM), present
            (1960-1969) and future (2090-2099) spatial distributions of C.
            finmarchicus in the North Atlantic. This figure demonstrates the
            complexity of the biogeographical movements, showing that (i) the
            shifts have both meridional and zonal components, (ii) there are
            zones of nil or small shifts (e.g. the Oceanic Polar Front in the
            north-west Atlantic) and (iii) zones where substantial shifts take
            place (e.g. the northeast Atlantic). Perry and colleagues (Perry et
            al., 2005) showed a northward movement of 15 out of 36 species in
            the North Sea that paralleled an increase in sea temperature. They
            also provided evidence that some species that did not move
            latitudinally migrated vertically. For example, the plaice
            (Pleuronectes platessa) and the cuckoo ray (Leucoraja naevus) were
            two among six species that responded to sea warming by moving deeper
            into the water column.{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure 8">
            <ClickableImage
              className="theoryPage-IV-img"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 8.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 8</span>: Modelled
            probability of occurrence of Calanus finmarchicus for the LGM (A),
            the 1960s (B) and the end of the 2090s (C). The model NPPEN
            (Non-Parametric Probabilistic Ecological Niche), based on both SST
            and bathymetry, was applied. Future spatial distribution was
            modelled using a pessimistic scenario [pre-industrial CO2 x 4]. The
            white circle indicates an absence of northward movement between the
            LGM and the 1960s and white arrows indicate the magnitude of the
            northward movement. u and v is the zonal and meridional component,
            respectively. w is the vectorial sum of u and v. From Beaugrand
            (Beaugrand, 2015).{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            As a result of climate change, the spatial distribution of species
            may exhibit range contractions and expansions (Cheung et al., 2009;
            MacLeod, 2009), although it remains challenging to quantify this,
            however. It is particularly difficult to quantify for equatorial or
            tropical species for which the thermophilic section of the niche is
            often unknown and cannot be estimated reliably from our current
            knowledge of the warm edge of species’ spatial distribution, and as
            a result, we may underestimate their resistance and resilience to
            climate warming. Nevertheless, the ranges of tropical species are
            likely to expand while temperate and polar species are likely to
            contract.{" "}
          </p>

          <p className="text-subtitle bold">A.2.2. Global extinction</p>
          <p className="text-normal">
            For some species, range contraction may take place to such an extent
            that it results in extinction. Periods of global warming have been
            associated with major extinction events in the past (Jablonski,
            1991; Gaston & Spicer, 2004) and so anthropogenic climate change may
            cause species extinction in the future (Thomas et al., 2004). An
            estimate of the magnitude of such climate-induced extinctions is
            difficult, however. Based on the examination of 1103 species of
            animals and plants, Thomas and colleagues (Thomas et al., 2004)
            provided a rough assessment of the potential effects of
            anthropogenic climate change on terrestrial species extinction by
            2050. Estimates were based on bioclimatic models and species-area
            relationships for three levels of warming and for scenarios of nil
            or full (universal) dispersal. For a high level of warming, they
            found that climate-caused species extinction may range between 21%
            and 32% when there is no limitation in species dispersal and between
            38% and 52% when there is no possibility of dispersal. In case of a
            medium climate change, the percentage of species committed to
            extinction ranged between 15-20% (universal dispersal) and 26-37%
            (no dispersal). Using optimistic scenarios of climate change, the
            percentages were 9-13% (universal dispersal) and 22-31% (no
            dispersal). Rates of species committed to extinction varied among
            taxonomic groups and ecoregions.{" "}
          </p>
          <p className="text-normal">
            We do not know if there have been any climate-induced extinctions in
            the marine realm due to anthropogenic climate change and none have
            been documented as yet. Although some marine species such as the
            Mediterranean mysid Hemimysis speluncola (marine cave species) may
            become at risk (Chevaldonné & Lejeusne, 2003) it is unlikely at
            present for most pelagic species.{" "}
          </p>

          <p className="text-subtitle bold">A.2.3. Adaptation</p>
          <p className="text-normal">
            We consider the species’ phenotypes seen throughout its range as its
            norm of reaction and so the phenotypes that are associated to the
            full ecological conditions it experiences; therefore, the norm of
            reaction is fully included within the species’ ecological niche.
            Adaptation is viewed as the outcome of natural selection on the
            genotype that enables the species to alter its behavior and
            physiology so that it modifies the shape of its niche (
            <FigureInnerLinkComponent
              text="Figure 1"
              anchor="Figure-1"
            ></FigureInnerLinkComponent>
            ). Therefore, adaptation is not fully excluded from the present
            framework. It is unclear whether adaptation will enable ectotherms
            to overcome the environmental alterations induced by the current
            global climate change (Gundrson & Stillman, 2015).
          </p>
          <p className="text-normal">
            The tendency of a species to preserve ancestral ecological traits
            and environmental distribution is known as niche conservatism and it
            means that species rarely change biome (Crisp et al., 2009). In a
            study of 11,000 plant species at time scales of tens of millions
            years and at large spatial scales Crisp et al (2009) only found 396
            cases (3.6%) where a biome shift was associated with evolutionary
            divergence and interestingly, this only occurred between biomes with
            a high ecological similarity. Crisp and colleagues concluded by
            stating that species evolutionary success does not rely in adapting
            to a new biome but rather in tracking biomes with similar
            environmental conditions and they compared this to introduced
            species, which generally tend to invade regions with similar
            ecological characteristics to their native range (niches, sensu
            Hutchinson).{" "}
          </p>
          <p className="text-normal">
            Overpeck and colleagues (Overpeck et al., 2003) noted that only a
            small amount of macroevolution was apparent during the Quaternary,
            which was a Period of repeated climate change. Today, rates of
            climate change are probably much higher than past rates of climatic
            niche evolution, however (Quintero & Wiens, 2013). Quintero and
            Wiens (2013) estimated that the rate of niche evolution for
            terrestrial vertebrates should be 10,000 times faster than those
            normally observed to overcome the effects of anthropogenic climate
            change. For species with very short generation times evolution may
            be faster, however. Studies on the phytoplankton Chlorella vulgaris
            have shown that growth rates evolved rapidly after just 10
            generations when the temperature was raised from 20°C to 30°C
            (Padfield et al., 2016). However, if evolution can be so quick why
            have species in the past not adapted to changes in the thermal
            regime more readily, but instead retreated to refugia?(Dobrowski,
            2011).
          </p>

          <p className="text-title bold">
            B. Responses to climate at higher organizational levels
          </p>
          <p className="text-normal">
            Below, we illustrate briefly how the previous individualistic
            responses to climate change can propagate at the community level and
            we propose some predictions about how communities may reconfigure in
            response to climate change.
          </p>
          <p className="text-subtitle bold centered">
            {" "}
            B.1. Ecological succesion
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            Gleason and Ramensky observed that species respond
            individualistically to environmental changes and called it the
            “principle of species individuality” (Whittaker, 1975). As a
            consequence, it has been suggested that changes in seasonal
            succession may desynchronize species interaction and trigger trophic
            mismatch (Beaugrand et al., 2003; Edwards & Richardson, 2004;
            Parmesan, 2005). Although this remains to be investigated in depth,
            our framework suggests that reassembly should be limited because
            interacting species have at least a part of their ecological niche
            in common, providing that no other important ecological dimensions
            or habitat requirements differ among them. Since a{" "}
            <GlossaryDefinition
              word="synusia"
              definition="a structural unit of a biocoenosis characterized by relative life homogeneity."
            ></GlossaryDefinition>{" "}
            should remain similar for a given thermal regime (
            <FigureInnerLinkComponent
              text="Figure 9"
              anchor="Figure-9"
            ></FigureInnerLinkComponent>
            ) a certain degree of reconfiguration is only expected when (i) the
            degree of eurythermy among species in interaction differs
            substantially, (ii) species strongly depend on each other (e.g.
            specialized predator) and (iii) the habitat is strongly influenced
            by engineer species, which is not generally the case in the pelagic
            environment.
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-9">
            <ClickableImage
              className="theoryPage-IV-img2"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 9.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 9</span>: A pseudo-community
            composed of pseudo-species being each characterized by a thermal
            niche. For a given temperature (e.g. the vertical arrow), community
            structure is invariant so long as no other ecological factor affects
            a pseudo-species. It follows that community reconfiguration is
            limited by the ecological niche of each species.
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>

          <p className="text-subtitle bold centered">
            {" "}
            B.2. Long-term and abrupt community shifts
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            Many reports have shown that temperature is a key driver of changes
            in communities at different spatial scales (Richardson & Schoeman,
            2004; Beaugrand et al., 2008; Kirby et al., 2008; Kirby & Beaugrand,
            2009; Burrows et al., 2011; Luczak et al., 2011; Beaugrand et al.,
            2013b; Burrows et al., 2014). Because the responses of species to
            climate change are determined in large part by the interaction
            between the thermal niche and temperature, we can create
            pseudo-species, each having a different thermal niche. Those species
            are subsequently allowed to colonize a given oceanic region so long
            as they can survive changes in the environmental regime at different
            temporal scales. Niche overlapping is allowed but no species can
            have the same ecological niche in a given region, after the
            principle of competitive exclusion (Gause, 1934). By constructing
            pseudo-communities, we can then investigate how climate may
            influence pelagic communities. Because pseudo-communities have in
            general more species than actual ones, we can only select a number
            of pseudo-species that correspond to the community’s species
            richness. By repeating the procedure 10,000 times we can examine the
            long-term changes in these pseudo-communities and investigate how
            they are correlated with actual communities. This work was carried
            out in the North Sea using data from the Continuous Plankton
            Recorder (CPR) survey (Beaugrand, 2014; Beaugrand et al., 2014).
            Modeled changes in pseudo-communities are highly correlated to
            observed community shifts in the North Sea (
            <FigureInnerLinkComponent
              text="Figure 10"
              anchor="Figure-10"
            ></FigureInnerLinkComponent>
            ). In addition, the abrupt shift observed in CPR data is also
            predicted from the METAL model.
          </p>

          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-10">
            <ClickableImage
              className="theoryPage-IV-img2"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 10.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 10</span>: Test of the
            hypothesis that climate-caused abrupt community shift may be
            triggered by the interaction between changes in temperature and the
            thermal niche of each species composing a community. A total of
            10,000 long-term expected changes was simulated (curves in black)
            and compared to the first component of long-term copepod abundance
            changes (1958-2009; curve in red) and to annual sea surface
            temperature (1958-1999; curve in green). From Beaugrand and
            colleagues (Beaugrand et al., 2014).
          </p>

          <p className="text-subtitle bold centered">
            {" "}
            B.3. Changes in the location of species assemblages
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            As we said earlier, community reconfiguration is likely to be
            limited because species occurring in the same place and at the same
            time have overlapping niches and therefore, we should not expect a
            substantial alteration of pelagic sinusiae (
            <FigureInnerLinkComponent
              text="Figure 11"
              anchor="Figure-11"
            ></FigureInnerLinkComponent>
            ). In the northeastern region of the North Atlantic, major
            biogeographical shifts have taken place from 1960 to 2005 (Beaugrand
            et al., 2002; Beaugrand, 2009). For example, a northward movement of
            warm-water calanoid copepod species have been observed, associated
            with a reduction in the number of colder-water species. Those
            biogeographical movements have deeply altered pelagic biodiversity
            (Beaugrand et al., 2009).{" "}
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-11">
            <ClickableImage
              className="theoryPage-IV-img"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 11.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 11</span>: Long-term
            biogeographical shifts in calanoid copepods in the northeastern part
            of the North Atlantic. Mean number of species per sample is small
            because sampling is based on about 3 m3 of seawater filtered and
            includes day/night surface sampling. From Beaugrand and colleagues
            (Beaugrand et al., 2009).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-subtitle bold centered">
            {" "}
            B.4. Changes in biodiversity
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            Biodiversity shifts have already been observed or predicted to occur
            as climate warms at a regional and a global scale and examples exist
            from plankton to commercially exploited fish (Brander et al., 2003;
            Perry et al., 2005; Hiddink & Hofstede ter, 2008; Cheung et al.,
            2009; Beaugrand et al., 2010; Wernberg et al., 2011; Burrows et al.,
            2014; Beaugrand et al., 2015; Jones & Cheung, 2015). Our framework
            suggests that shifts in biodiversity are likely to result from the
            individual rearrangement of species as climate changes, which takes
            place through the interaction between the ecological niche of each
            species and changes in the environmental regime (Beaugrand et al.,
            2013a; Beaugrand et al., 2015).
          </p>
          <p className="text-normal">
            As temperature is a top parameter from the molecular level to the
            biosphere (Brown et al., 2004; Lineweaver & Schwartzman, 2004) we
            consider that using a thermal niche can significantly help to
            understand and anticipate climate-induced shifts in biodiversity. To
            reconstruct biodiversity we used the same type of model we used to
            investigate long-term community shifts. Each pseudo-community
            results from the aggregation of pseudo-species, each being
            characterized by a unique niche. A total of 39,218 thermal
            (Gaussian) niche were generated at a year-to-year scale, but only
            half (19,609) were chosen randomly to consider niche vacancy (Rohde,
            2005). All species were able to colonize a given region of the
            global ocean providing that they could withstand the local
            temperature (annual SST) regime (Beaugrand et al., 2015).
            Reconstructed biodiversity patterns from the past (Last Glacial
            Maximum and mid-Pliocene) and the contemporary period were highly
            correlated with observed patterns of biodiversity for a variety of
            taxonomic groups. Predictions of biodiversity shifts were carried
            out for the North Sea and the METAL model explained well long-term
            biodiversity shifts observed from 1960 to 2009 (Beaugrand et al.,
            2015), demonstrating that the currently observed increase in
            biodiversity results from a climatic effect, i.e. the increase in
            thermal regime of extratropical oceans and seas (Hiddink & Hofstede
            ter, 2008; Beaugrand et al., 2010).
          </p>
          <p className="text-normal">
            This model revealed that changes in marine biodiversity around the
            world will be nonuniform; biodiversity will decrease in warm-water
            regions between 40°S and 40°N, which is from the equator to the sub
            tropics, and will increase by as much as 300% from temperate to
            Polar regions. The increase in biodiversity projected by this model,
            and also expected in other studies (Jones & Cheung, 2015), has
            already been observed among copepods and fish in the extratropical
            regions of the Atlantic Ocean (i.e. the North Sea) (Hiddink &
            Hofstede ter, 2008; Beaugrand et al., 2010). Our model also suggests
            a reduction in biodiversity in permanently stratified regions, an
            observation that is also projected by the multi-ensemble model based
            on niche modeling (Jones & Cheung, 2015). Using 194 strains
            belonging to more than 130 species from major phytoplankton groups,
            Thomas and colleagues (Thomas et al., 2012) showed that global
            climate change may reduce phytoplankton biodiversity in tropical
            regions considerably, by the end of this century.
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div style={{ "text-align": "center" }} id="Figure-12">
            <ClickableImage
              className="theoryPage-IV-img"
              link={process.env.PUBLIC_URL + "/images/partIV/Figure 12.jpg"}
            ></ClickableImage>
          </div>
          <p className="text-normal">
            <div className="top-span"></div>
            <span className="red-text-span">Figure 12</span>: Expected future
            mean vulnerability of biodiversity in relation to past and
            contemporary changes. a-d. Net biodiversity changes between
            2081–2100 and 2006–2013 for scenarios RCP 2.6 (a), RCP 4.5 (b), RCP
            6.0 (c) and RCP 8.5 (d). e. Vulnerability of biodiversity related to
            natural (year-to-year) fluctuations in mean SST. f. Vulnerability of
            biodiversity related to changes in mean SST between the 2000s and
            the 1960s. g. Vulnerability of biodiversity related to changes in
            mean SST between the Last Glacial Maximum (LGM) and the 1960s. h.
            Vulnerability of biodiversity related to changes in mean SST between
            the mid-Pliocene and the 1960s. All biological changes were based on
            quantitative measure of biodiversity and were subsequently expressed
            as percentage. Modified, from Beaugrand and colleagues (Beaugrand et
            al., 2015).
          </p>
          <div className="top-span"></div>
          <hr className="thick-hr"></hr>
          <div className="top-span"></div>
          <p className="text-normal">
            We next investigated the potential consequences of global warming
            for the pelagic biodiversity at the end of the century, comparing
            those changes with those that occurred between the mid-Pliocene and
            Last Glacial Maximun and today, and between the 1960s and the 2000s.
            If future global warming remains below 2°C (Representation
            Concentration Pathway, RCP 2.6), which is the amount of warming the
            international community considers to be below the threshold that
            would place natural systems at risk of grave damage, only 42±26% of
            the global ocean would experience a substantial shift in
            biodiversity (
            <FigureInnerLinkComponent
              text="Figure 12a"
              anchor="Figure-12"
            ></FigureInnerLinkComponent>
            ). However, more alarming, the other three levels of global warming
            that were examined each indicated that they would place the marine
            biosphere at risk of increasingly, significant changes (
            <FigureInnerLinkComponent
              text="Figure 12b-d"
              anchor="Figure-12"
            ></FigureInnerLinkComponent>
            ). When warming reaches the dangerous threshold of 2°C our model
            predicts that between 78% and 94% of the surface ocean would
            experience a change in marine biodiversity. Such percentages should
            be compared to biodiversity shifts experienced as part of natural
            year-to-year variability during the last 5 decades or changes that
            already took place between the 2000s and the 1960s (
            <FigureInnerLinkComponent
              text="Figure 12e-f"
              anchor="Figure-12"
            ></FigureInnerLinkComponent>
            ). These levels of biodiversity shifts would be higher than those
            experienced between the Last Glacial Maximum (or even the warmer
            period of the mid-Pliocene) and today (
            <FigureInnerLinkComponent
              text="Figure 12g-h"
              anchor="Figure-12"
            ></FigureInnerLinkComponent>
            ). Biodiversity shifts between the Last Glacial Maximum and today
            took place during a period much bigger than those expected for the
            end of the century (~5000 versus 100 years). When global warming
            rises above the dangerous threshold of 2°C, between 50 and 70% of
            the global ocean may experience a change in marine biodiversity
            equivalent to, or higher than, that experienced between the Last
            Glacial Maximum and the mid-Pliocene, and today, indicating a major
            effect of climate warming on marine biodiversity.
          </p>

          <p className="text-subtitle bold centered">
            {" "}
            B.5. Consequences at the ecosystem level
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            Changes in biodiversity will inevitably affect ecosystem functioning
            (e.g. trophodynamics and species interaction, benthic-pelagic
            coupling, sea-land interaction), provisioning (e.g. fisheries and
            aquaculture) and regulating services (e.g. biological carbon
            pump)(Kirby et al., 2007; Cheung et al., 2009; Kirby & Beaugrand,
            2009; Beaugrand et al., 2010; Luczak et al., 2011; Doney et al.,
            2012; Luczak et al., 2012; Jones & Cheung, 2015). While some of the
            consequences may be predicted from the METAL theory the
            relationships between the characteristics of the species’ ecological
            niche and their life history traits remains to be understood and
            parameterized. For example, the increase in biodiversity of calanoid
            copepods observed in areas that experienced an increase in
            temperature in the northeastern part of the North Atlantic and its
            adjacent seas has been accompanied by a phenomenon of dwarfism. The
            increase northwards of warm-water species have reconfigured the
            synusia in such a way that it is now composed of smaller species,
            which may significantly alter biological carbon exportation on those
            areas (Beaugrand et al., 2010). Understanding the relationships
            between the thermal amplitude and optima of species and their size
            could therefore help understand the effect of community
            reconfiguration on ecosystem-level processes such as biological
            carbon exportation.
          </p>
          <p className="text-normal">
            Benthic-pelagic coupling is another ecosystem-level response that
            may be strongly affected by changes in temperature induced by
            anthropogenic climate change (Doney et al., 2012). In shallow seas
            when stratification breaks down the sea bed and sea surface
            temperatures may be similar thermally. In the North Sea this appears
            to have influenced the reproduction of benthic species and with
            meroplanktonic larvae this may also change interactions between
            species in the plankton (Kirby et al., 2007; Kirby et al., 2008).
            Kirby and colleagues (2008, 2007) suggested that climate-induced
            changes in the North Sea merozooplankton (increase) and
            holozooplankton (decrease) reflected a shift in energy partitioning
            between the benthos and the pelagos that involved many species from
            decapods to cod and several direct and indirect trophic
            interactions.{" "}
          </p>
          <p className="text-normal">
            Sea-land interactions may also be altered as a result of climate
            change. The changes in North Sea decapods described above were also
            associated with an outburst in swimming crabs (Necora puber,
            Liocarcinus depurator and Polybius henslowi) (Luczak et al., 2012).
            As swimming crabs are a significant food source for lesser
            black-backed gulls during the breeding season, it was suggested that
            this increase may be at the origin of an increase in some North Sea
            colonies (Luczak et al., 2012). Inhabiting the land, but feeding
            mainly at sea, these gulls provide a link between marine and
            terrestrial ecosystems, since the bottom-up influence of
            allochthonous nutrient input from seabirds to coastal soils can
            structure terrestrial trophodynamics. In this way, climate-caused
            marine ecosystem changes may have some consequences for coastal
            terrestrial ecosystems.
          </p>

          <p className="text-subtitle bold centered">
            {" "}
            B.6. Shifts in biomes and provinces
          </p>
          <div className="top-span"></div>
          <p className="text-normal">
            A major ecological compartment (a biome) is by definition in
            equilibrium with a climatic regime. Therefore climate change is
            expected to unbalance these large ecosystems (Lomolino et al.,
            2006). Climate-induced shifts in the location of biomes and
            provinces are predicted by the METAL theory. Beaugrand and
            co-workers (Beaugrand et al., 2008) showed that a northward movement
            of North Sea isotherms triggered a poleward shift of the boundary
            between temperate and Arctic biomes sensu Longurst (Longhurst,
            1998). They first showed that the average spatial distribution of
            upper-ocean chlorophyll concentration (as measured by SeaWIFS), the
            diversity and mean size of calanoid copepods (as measured by the
            Continuous Plankton Recorder survey), and the modelled occurrence of
            Atlantic cod, exhibited a pronounced non-linearity in their
            individual responses to temperature change between 9 and 10°C. All
            these key functional attributes showed a maximum in local biological
            variance at temperatures (i.e. annual mean of Sea Surface
            Temperature) between 9° and 10°C, which suggests that i) a similar
            ecosystem will experience pronounced ecosystem effects but ii) an
            identical increase in an ecosystem with a different temperature
            regime (e.g. 12°-13°C) will have a more moderate impact. Therefore,
            all systems are not equally sensitive to temperature rise and so
            ‘variance hot spots’ exits i.e. regional discontinuities
            characterized by high biological variance. These results also
            indicate that abrupt community shifts are likely to be located
            around critical thermal boundaries. The critical thermal boundary of
            9-10°C coincided with the transitional region between the Atlantic
            Polar and the Atlantic Westerly Winds biome. Our analysis revealed
            that biome boundaries are highly sensitive to climate change and
            that climate-induced modifications in their geographical locations
            may be at the origin of pronounced community/ecosystem shifts. Other
            regions spatially embedded deeply within a major biome can remain
            relatively ecologically stable over long periods.
          </p>
          <p className="text-normal">
            Pelagic biomes are primary biological units that can be fairly
            separated from each other (Longhurst, 1998), although their
            geographical limits are dynamic at multiple temporal scales
            (Reygondeau et al., 2013). However, provinces are imbricated and
            they are constituted by a mosaic of coenoclines and therefore
            ecoclines (Whittaker, 1975). At a large scale, many studies suggest
            that provinces may be reconfigured as a consequence of global
            climate change. METAL suggests that there are strong constraints
            that will limit the rearrangement of provinces, especially in the
            pelagic domain (
            <FigureInnerLinkComponent
              text="Figure 9"
              anchor="Figure-9"
            ></FigureInnerLinkComponent>
            ). Some studies have proposed that new provinces may emerge from
            climate change in permanently stratified regions. This is indeed a
            possibility but caution is needed as the upper thermal limits of the
            species inferred from their current distribution may be
            underestimated. In the past, higher temperatures may have occurred
            in those regions and many species may have the possibility to occur
            in warmer thermal regimes; here, this is the norm of reaction of
            species that may be underestimated. The determination of the upper
            thermal limit of pseudo-species in METAL has strong consequences on
            the modelled patterns of biodiversity, showing that the estimation
            of the upper thermal limit of species is fundamental. The only way
            to understand this is to re-examine warmer periods or to estimate
            this parameter by individual physiological experiments.
          </p>

          <p className="text-title bold">Conclusion</p>
          <p className="text-normal">
            A unifying ecological theory to understand and predict how
            biodiversity is organised and how it responds to climate change is
            important. Our METAL framework is based on the concept of the
            ecological niche of Hutchinson (Hutchinson, 1957), which integrates
            genetic, molecular and other biological processes that are difficult
            to parametrise in classical approaches because of the many variables
            that need to be estimated. Our theory shows that most responses of
            species documented so far can be explained by the niche-environment
            interaction, which subsequently propagates at higher organizational
            levels. Our study shows that the responses of species to climate
            change are therefore intelligible, i.e. they have a strong
            deterministic component and can be predicted. A unifying ecological
            theory should also be able to guide future research by defining
            research priorities and identify both weaknesses and strengths in
            our current scientific knowledge, which we hope is a role METAL will
            fulfil.{" "}
          </p>

          <p className="text-title bold">References</p>
          <PartFourReferences></PartFourReferences>
        </Col>
        <Col>
        <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/rightTop.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
            <ClickableCopyrightImage
              link="/images/theoryRessources/gallery/rightBottom.jpg"
              className="homePage-vertical-streamer-img"
              author="Dr Gregory Beaugrand"
            ></ClickableCopyrightImage>
        </Col>
      </Row>
    </div>
  );
};

export default PartFourPage;
