
function ContactComponent() {
  return (
    <div>
      <p className="text-normal"><span className="bold">Domain and Webmaster</span>: Dr Gregory Beaugrand</p>
      <p className="text-normal"><span className="bold">Domain owner and research organization</span>: Centre National de Recherche Scientifique (CNRS)</p>
      <p className="text-normal"><span className="bold">Web Developer</span>: Jeremy Delobel</p>
  
    </div>
  );
}

export default ContactComponent;
