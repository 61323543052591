import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
const ClickableImage = (props) => {
  return (
    //<a href={props.link} target="_blank" rel="noreferrer"/>}
    <Link
      to={{
        pathname: "/ressource",
        search: `?author=${
          props.author ? props.author : "Dr Gregory Beaugrand"
        }&link=${props.link}`,
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image
        alt={props.alt}
        src={props.link}
        className={props.className}
        fluid
      />
    </Link>
  );
};

export default ClickableImage;
