import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "../../css/homePage.css";
const HomeCarouselComponent = (element) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const captions = [
    "Modelled biodiversity (terrestrial)",
    "Modelled biodiversity (ocean, surface)",
    "Modelled biodiversity (ocean, bottom)",
  ];

  const pictures = [
    "/images/homeRessources/homeCarousel.jpg",
    "/images/homeRessources/homeCarousel2.jpg",
    "./images/homeRessources/homeCarousel3.jpg",
  ];

  return (
    <article className="homeCarousel">
      <p className="text-subtitle">
        <b>{captions[index]}</b>
      </p>
      <Carousel variant="dark" activeIndex={index} onSelect={handleSelect} fade>
        <Carousel.Item>
          <img
            src={pictures[0]}
            alt="Impossible d'afficher la ressource demandée"
            style={{ height: "100%", width: "100%" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={pictures[1]}
            alt="Impossible d'afficher la ressource demandée"
            style={{ height: "100%", width: "100%" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={pictures[2]}
            alt="Impossible d'afficher la ressource demandée"
            style={{ height: "100%", width: "100%" }}
          />
        </Carousel.Item>
      </Carousel>
    </article>
  );
};

export default HomeCarouselComponent;
