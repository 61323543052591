import "../../css/theoryPage.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ChapterColumnComponent from "./ChapterColumnComponent";

function TheoryPageComponent() {
  return (
    <Container className="theoryPage">
      <div className="theoryPage-summary">
        <p className="text-normal">
          In <span className="red-text-span">part I</span> (see below), we
          explain the rationale and the main assumptions and biological laws
          used as part of the{" "}
          <b>MacroEcological Theory on the Arrangement of Life (METAL).</b> We
          focus mainly on the concept of the niche and why it is the central
          tenet of the theory. We also mention some other ecological principles
          such as niche conservatism and Buffon’s Law.
        </p>
        <p className="text-normal">
          In <span className="red-text-span">part II</span>, we show patterns
          that the METAL theory can explain at three main organizational levels
          (individual, species and community). For example, at the individual
          level, we explain the phenomenon of thermotaxis. At the species level,
          the theory can explain why a species occurs in some regions, their
          spatial range and phenology. We connect spatial range and phenology.
          At the community level, we can explain community arrangement and
          organization, spatial changes in communities and how the environment
          modulates species interaction. We propose an explanation of the
          well-known patter of annual plankton succession.
        </p>
        <p className="text-normal">
          In <span className="red-text-span">part III</span>, we propose a
          global theory of biodiversity in the terrestrial and marine (from the
          surface to the bottom) realms. We explain this great chessboard of
          life for the contemporary period. We also propose an explanation why
          there are more terrestrial than marine species. We apply the theory in
          the past at different time periods (Cambrian, Ordovician,
          mid-Pliocene, and Last Glacial Maximum). Finally, we apply the METAL
          theory to revisite our knowledge of island biogeography.
        </p>
        <p className="text-normal">
          In <span className="red-text-span">part IV</span>, we explain why and
          how the METAL theory helps to understand and anticipate biological
          changes observed in the context of natural and anthropogenic climate
          change. We propose to connect through the niche concept phenological
          and biogeographical shifts, long-term changes in abundance and
          community rearrangement in space and time.
        </p>
      </div>

      <Row
        xxl={2}
        xl={2}
        lg={2}
        md={2}
        sm={1}
        xs={1}
        className="theoryPage-row"
      >
        <ChapterColumnComponent
          link="/images/theoryRessources/Figure_Part_I.png"
          alt="Can't display Part I ressource"
          partID="Part I"
          title="Fundamentals of the theory"
          introduction="Brief text to describe Part I content"
          redirect="/theory/partI"
        ></ChapterColumnComponent>
        <ChapterColumnComponent
          link="/images/theoryRessources/Figure_Part_II.png"
          alt="Can't display Part II ressource"
          partID="Part II"
          title="Patterns and biological events"
          introduction="Brief text to describe Part II content"
          redirect="/theory/partII"
        ></ChapterColumnComponent>
        <ChapterColumnComponent
          link="/images/theoryRessources/Figure_Part_III.png"
          alt="Can't display Part III ressource"
          partID="Part III"
          title="A global theory of biodiversity"
          introduction="Brief text to describe Part III content"
          redirect="/theory/partIII"
        ></ChapterColumnComponent>
        <ChapterColumnComponent
          link="/images/theoryRessources/Figure_Part_IV.png"
          alt="Can't display Part IV ressource"
          partID="Part IV"
          title="Climate change biology"
          introduction="Brief text to describe Part IV content"
          redirect="/theory/partIV"
        ></ChapterColumnComponent>
      </Row>
    </Container>
  );
}

export default TheoryPageComponent;
