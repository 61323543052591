import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";

const GlossaryDefinition = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span
        style={{
          "text-decoration": "underline  #b6e4ff",
          cursor: "pointer",
          color: "#b6e4ff",
        }}
        onClick={handleShow}
      >
        {props.word}
      </span>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{props.word}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{props.definition}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default GlossaryDefinition;
