import ClickableImage from "./ClickableImage";
/**
 *
 * @param {*} props
 * anchor => #anchorName
 * link => path of the figure to show
 * text => figure number
 * definition => definition of the figure
 * @returns
 */
const TheoryFigure = (props) => {
  return (
    <>
      <div className="top-span"></div>
      <hr className="thick-hr"></hr>
      <div style={{ "textAlign": "center" }} id={props.anchor}>
        <ClickableImage
          className={props.className}
          link={props.link}
        ></ClickableImage>
      </div>
        <div className="top-span"></div>
      <p className="text-normal">
        <span className="red-text-span bold">{props.text}</span>
        {props.definition}
      </p>
      <div className="top-span"></div>
      <hr className="thick-hr"></hr>
    </>
  );
};

export default TheoryFigure;
