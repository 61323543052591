import { Container } from "react-bootstrap";
import data from "../../data/Beaugrand-Publication.json"; 


function PPBeaugrand() {


    function checkType(type) {
        switch (type) {
            case "publications":
                return "P";
            case "book-chapters":
                return "BC"
            case "other-publications" || "other-papers":
                return "OP"
            case "sr-is":
            return "SR-IS";
            case "book":
                return "B";
            case "sr":
                return "SR";
            default:
                return "P";
        }
    }
  return (
    <Container className="left">
        <div className="top-span"></div>
        {data.writing.map( (categories) => {
            return (
                <>
                <h1 className="text-title red bold centered">{categories.category}</h1>
                <hr className="thick-hr"></hr>
                { categories.papers.map( (paper) => {
                    
                    return (
                        <>
                        <p>
                            <span className="text-normal bold">[{checkType(categories.src)}{paper.id}]{""}
                            </span>
                            {paper.authors.map( (author) => ` ${author}`)} 
                            ({paper.date})
                            {", "}
                            {paper.work}
                            {" "}
                            <span className="bold">{paper.review}</span>
                            {", "}
                            {paper.meta}
                        </p> 
                        </>
                    )
                })}
                    <hr className="thick-hr"></hr>
                </>
            );
        })}
        
    </Container>
  );
}

export default PPBeaugrand;
