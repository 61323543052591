import { Accordion, Container } from "react-bootstrap";

const NewBubbleComponent = (newsData) => {

  return (
    <Container className="left">
      <Accordion className="news-accordion-body">
          <Accordion.Header className="news-accordion-header">
          <h1 className="text-title bold">{newsData.title}</h1>
          </Accordion.Header>
          <Accordion.Body>
    
            <p className="text-subtitle bold">{newsData.preview}</p> 
                
            <p className="text-normal">{newsData.abstract}</p>
            {newsData.references.map( (ref) => <p className="text-small">{ref}</p>)}
          </Accordion.Body>
          </Accordion>
    </Container>
  );
};

export default NewBubbleComponent;
